import { Period } from 'Constants/Interface/Period';
import { atom } from 'recoil';

export const isPeriodApiLoadingState = atom<boolean>({
  key: 'isPeriodApiLoadingState',
  default: false,
});

export const periodState = atom<Period>({
  key: 'periodState',
  default: {
    english: '',
    native: '',
    period: '',
  },
});
