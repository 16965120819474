import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { countryState } from 'Stores/Countries/CountriesStore';
import { orderDetailFormState, setZipCodeTempState, zipCodeState } from 'Stores/OrderDetail/OrderDetailStore';
import { Box, TextField, IconButton, ThemeProvider, Stack, Typography, Dialog } from '@mui/material';
import styled from '@emotion/styled';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { MainTheme } from 'Cores/Layouts/MainTheme';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import CustomButton from 'Components/Elements/CustomButton/CustomButton';
import { ZipCodeInterface } from 'Constants/Interface/OrderDetail';
import { ReactComponent as SearchIcon } from '../../../../Assets/icons/search-icon.svg'
import { ReactComponent as WarningIcon } from '../../../../Assets/icons/warning-icon.svg'

type Props = {
  name?: string;
  label?: string | string[];
  type?: string;
  variant?: any;
  className?: string;
  inputProps?: any;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ZipSearch = ({ label, type, variant, className, inputProps, value, name, onChange }: Props) => {
  const [openInput, setOpenInput] = useState<boolean>(false);
  const [openResult, setOpenResult] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [translate] = useTranslate()
  const country = useRecoilValue(countryState);
  const [zipCodeData, setZipCodeData] = useRecoilState(zipCodeState);
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);
  const zipCodeTemp = useRecoilValue(setZipCodeTempState);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onCloseInputSearch = () => {
    setOpenInput(false)
    setShowError(false)
  }

  const onCloseSearchResult = () => {
    setOpenResult(false)
  }

  const onOpenInputSearch = () => {
    setOpenInput(true)
  }

  const fetchZipCode = async () => {
    setLoading(true)
    try {
      const response = await RenewalAPI.getZipcode(country.short, value)
      setZipCodeData(response.data.data)
      setLoading(false)

      if (response.data.data.length !== 0) {
        setOpenInput(false)
        setOpenResult(true)
      } else {
        setShowError(true)
      }

    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchZipCode()
    }
  };

  const onSubmit = () => {
    setAddressForm({
      ...addressForm,
      'prefecture': zipCodeTemp.prefecture,
      'district': zipCodeTemp.district,
      'town': zipCodeTemp.town,
      'zip': zipCodeTemp.zip,
    });

    setOpenResult(false)
  }

  const handleShowError = () => {
    setShowError(false)
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <UInputStyled className={className || ''}>
        <TextField
          label={label}
          variant={variant}
          value={value}
          type={type}
          name={name}
          InputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'false',
            endAdornment: (
              <IconButton onClick={onOpenInputSearch} edge="end">
                <SearchIcon />
              </IconButton>
            ),
            ...inputProps,
          }}
          onChange={onChange}
        />
      </UInputStyled>

      {/* dialog search input */}
      <Dialog
        open={openInput}
        onClose={onCloseInputSearch}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              borderRadius: '8px',
              width: '345px',
            },

            '.MuiDialog-paper': {
              margin: '15px'
            },
          }
        }}
      >
        <Stack sx={{
          padding: '15px',
        }}>
          <Box sx={{ marginBottom: '10px' }}>
            <Typography variant="title2" fontWeight={300} >
              {translate('urenew_search_address')}
            </Typography>
          </Box>

          <UInputSearchStyled>
            <TextField
              variant={variant}
              value={value}
              type={type}
              name={name}
              InputProps={{
                autoComplete: 'off',
                autoCorrect: 'off',
                spellCheck: 'false',
                ...inputProps,
              }}
              onChange={onChange}
              onKeyDown={onKeyDown}
              autoFocus={true}
              onFocus={handleShowError}
            />
          </UInputSearchStyled>

          {showError && <Box sx={{
            marginTop: '15px',
            display: 'flex',
            gap: '10px'
          }}>
            <Box width={28}>
              <WarningIcon />
            </Box>

            <Typography
              variant="caption"
              sx={{
                fontWeight: 300,
                color: '#FF6187'
              }}
            >
              {`${translate('urenew_text_error')} "${value}"`}
            </Typography>
          </Box>}

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
            alignItems: 'center'
          }}>
            <Box
              onClick={onCloseInputSearch}
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant="title2" fontWeight={300} >
                {translate('urenew_close')}
              </Typography>
            </Box>

            <Box width="146px">
              <CustomButton
                title={translate('ok')}
                bgColor='#079FF4'
                loading={loading}
                isCenterLoading={true}
                disabled={value === ''}
                onClick={() => fetchZipCode()}
              />
            </Box>
          </Box>
        </Stack>
      </Dialog>

      {/* dialog zip code data */}
      <Dialog
        open={openResult}
        onClose={onCloseSearchResult}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              borderRadius: '8px',
              width: '345px',
            },

            '.MuiDialog-paper': {
              margin: '15px'
            }
          }
        }}
      >
        <Stack sx={{
          padding: '15px',
        }}>
          <Box sx={{ marginBottom: '10px' }}>
            <Typography variant="title2" fontWeight={300} >
              {translate('urenew_search_address')}
            </Typography>
          </Box>
          <Stack sx={{
            overflowY: 'auto',
            maxHeight: '450px',
            scrollbarWidth: 'auto', /* Reset scrollbar width for desktop */
            scrollbarColor: 'red',
            gap: '15px',
          }}>

            {zipCodeData.map((item, index) => (
              <CardItems key={`zipcode+${index}`} items={item} />
            ))}
          </Stack>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
            alignItems: 'center'
          }}>
            <Box
              onClick={onCloseSearchResult}
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant="title2" fontWeight={300} >
                {translate('urenew_close')}
              </Typography>
            </Box>

            <Box width="146px">
              <CustomButton
                title={translate('ok')}
                bgColor='#079FF4'
                loading={loading}
                isCenterLoading={true}
                disabled={value === ''}
                onClick={onSubmit}
              />
            </Box>
          </Box>
        </Stack>
      </Dialog>

    </ThemeProvider>
  )
}

interface CardItemsProps {
  items: ZipCodeInterface
}

export function CardItems({ items }: CardItemsProps) {
  const [zipCodeTemp, setZipCodeTemp] = useRecoilState(setZipCodeTempState);
  const currentLanguage = useRecoilValue(languageState);

  const zipCode = items.search_address_roman.split('　')[0]

  const handleOnSet = () => {
    const newItem = {
      id: items.id,
      town: currentLanguage === 'en' ? items.sub2_roman : items.sub2_native,
      district: currentLanguage === 'en' ? items.sub1_roman : items.sub1_native,
      prefecture: currentLanguage === 'en' ? items.city_roman : items.city_native,
      zip: items.zip
    }
    setZipCodeTemp(newItem)
  }

  const isSelect = items.id === zipCodeTemp.id

  const changeLanguage = () => {
    if (currentLanguage === 'en') {
      return items.return_address_roman
    }
    return items.return_address_native
  }

  return (
    <Stack
      sx={{
        border: isSelect ? '2px solid #99BCDF' : '1px solid #E5EEF7',
        borderRadius: '8px',
        padding: '15px',
        cursor: 'pointer'
      }}
      onClick={handleOnSet}
    >
      <Typography
        variant="label5"
        sx={{
          fontWeight: isSelect ? 600 : 400,
          color: isSelect ? '#003B6F' : '#33628C'
        }}
      >
        {zipCode}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          fontWeight: 300,
          color: isSelect ? '#003B6F' : '#93AABF'
        }}
      >
        {changeLanguage()}
      </Typography>
    </Stack>
  )
}

const UInputStyled = styled.div`
  width: 100%;
  .MuiFormControl-root {
    &.MuiTextField-root {
      font-family: 'Prompt, sans-serif';
      width: 100%;
      height: 55px;
      
      .MuiInputBase-root {
        background-color: #ffffff;
        border: 1px solid #A0BBDC;
        border-radius: 8px;
        height: 55px;
        display: flex;
        align-items: center;
      }

      .MuiInputLabel-root {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 29px;
        color: #8A9BB0;
        margin-bottom: 0px;
        top: -2px;
        transform: translate(19px, 16px) scale(1);
        
        &.MuiInputLabel-shrink {
          transform: translate(19px, 4px) scale(0.8);
          top: 1px;
        }
      }

      .MuiOutlinedInput-input {
        color: #000000;
        right: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        padding: 0;
        padding: 25px 12px 5px 18px ;
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiIconButton-root {
        margin-right: -8px;
      }
    }
  }
`;

const UInputSearchStyled = styled.div`
  width: 100%;
  .MuiFormControl-root {
    &.MuiTextField-root {
      font-family: 'Prompt, sans-serif';
      width: 100%;
      height: 55px;
      
      .MuiInputBase-root {
        background-color: #ffffff;
        border: 2px solid #99BCDF;
        border-radius: 8px;
        height: 55px;
        display: flex;
        align-items: center;
        &::after,
        &::before {
          border: 0;
        }
      }

      .MuiOutlinedInput-input {
        color: #003B6F;
        right: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 29px;
        text-align: center;
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }
  }
`;

ZipSearch.defaultProps = {
  name: '',
  label: '',
  type: '',
  variant: undefined,
  className: '',
  inputProps: undefined,
  value: '',
};

export default ZipSearch