import { atom } from 'recoil';
import { FooterInterface } from './FooterInterface';

export const footerState = atom<FooterInterface>({
  key: 'footerState',
  default: {
    app: {
      ios: {
        native: "",
        english: ""
      },
      android: {
        native: "",
        english: ""
      },
    },
    help: [{
      link: {
        native: "",
        english: ""
      },
      name: {
        native: "",
        english: ""
      },
      links: {
        native: [""],
        english: [""],
      },
    }],
    misc: {
      display_email_subscription: false,
    },
    hours: [{
      day: {
        native: "",
        english: "",
      },
      time: {
        native: "",
        english: "",
      },
    }],
    login: {
      imageUrls: {
        mobile: {
          native: "",
          english: ""
        },
        tablet: {
          native: "",
          english: ""
        },
        desktop: {
          native: "",
          english: ""
        },
      },
      backgroundUrl: {
        mobile: {
          native: "",
          english: ""
        },
        tablet: {
          native: "",
          english: ""
        },
        desktop: {
          native: "",
          english: ""
        },
      },
      resetPasswordUrl: {
        native: "",
        english: ""
      },
    },
    order: {
      cart_message: {
        native: "",
        english: ""
      },
      cart_limit_qty: {
        native: "",
        english: ""
      },
      cart_message_2: {
        native: "",
        english: ""
      },
      cart_message_3: {
        native: "",
        english: ""
      },
      minimum_set_qty: {
        native: "",
        english: ""
      },
      cart_limit_price: {
        native: "",
        english: ""
      },
      minimum_item_qty: {
        native: "",
        english: ""
      },
      cart_limit_qty_total: {
        native: "",
        english: ""
      },
      cart_warning_qty_total: {
        native: "",
        english: ""
      },
      cart_warning_price_total: {
        native: "",
        english: ""
      },
      cart_warning_qty_per_item: {
        native: "",
        english: ""
      },
    },
    video: {
      body: {
        native: "",
        english: ""
      },
      link: {
        native: "",
        english: ""
      },
      button: {
        native: "",
        english: ""
      },
      send_to: {
        native: "",
        english: ""
      },
      header_1: {
        native: "",
        english: ""
      },
      header_2: {
        native: "",
        english: ""
      },
    },
    social: {
      line: {
        native: "",
        english: ""
      },
      twiter: {
        native: "",
        english: ""
      },
      youtube: {
        native: "",
        english: ""
      },
      facebook: {
        native: "",
        english: ""
      },
      instagram: {
        native: "",
        english: ""
      },
    },
    contact: {
      email: {
        native: "",
        english: ""
      },
      phone: {
        native: "",
        english: ""
      },
      address: {
        native: "",
        english: ""
      },
      address2: {
        native: "",
        english: ""
      },
    },
    country: {
      name: {
        native: "",
        english: ""
      },
      code_short: {
        native: "",
        english: ""
      },
      code_shorter: {
        native: "",
        english: ""
      },
    },
    general: {
      map_link: {
        native: "",
        english: ""
      },
      warehouse: {
        native: "",
        english: ""
      },
      maintenance: {
        native: "",
        english: ""
      },
      country_name: {
        native: "",
        english: ""
      },
      payment_logo: {
        native: "",
        english: ""
      },
      currency_code: {
        native: "",
        english: ""
      },
      currency_symbol: {
        native: "",
        english: ""
      },
      decimal_controller: {
        native: "",
        english: ""
      },
      maintenance_display: {
        native: "",
        english: ""
      },
      partners_logo_mobile: {
        native: "",
        english: ""
      },
      partners_logo_desktop: {
        native: "",
        english: ""
      },
    },
    feedback: {
      body: {
        native: "",
        english: ""
      },
      email: [""]
    },
    main_pic: {
      link: {
        native: "",
        english: ""
      },
    },
    messenger: {
      fri_end: {
        native: "",
        english: ""
      },
      mon_end: {
        native: "",
        english: ""
      },
      sat_end: {
        native: "",
        english: ""
      },
      sun_end: {
        native: "",
        english: ""
      },
      thu_end: {
        native: "",
        english: ""
      },
      tue_end: {
        native: "",
        english: ""
      },
      wed_end: {
        native: "",
        english: ""
      },
      fri_start: {
        native: "",
        english: ""
      },
      mon_start: {
        native: "",
        english: ""
      },
      sat_start: {
        native: "",
        english: ""
      },
      sun_start: {
        native: "",
        english: ""
      },
      thu_start: {
        native: "",
        english: ""
      },
      tue_start: {
        native: "",
        english: ""
      },
      wed_start: {
        native: "",
        english: ""
      },
    },
    promotion: {
      top: {
        native: "",
        english: ""
      },
      link: {
        native: "",
        english: ""
      },
      image: {
        native: "",
        english: ""
      },
      button: {
        native: "",
        english: ""
      },
      header: {
        native: "",
        english: ""
      },
      caption_1: {
        native: "",
        english: ""
      },
      caption_2: {
        native: "",
        english: ""
      },
      countdown: {
        native: "",
        english: ""
      },
    },
    disclaimer: {
      image1: {
        native: "",
        english: ""
      },
      image2: {
        native: "",
        english: ""
      },
      image3: {
        native: "",
        english: ""
      },
      image4: {
        native: "",
        english: ""
      },
      disclaimer1_body: {
        native: "",
        english: ""
      },
      disclaimer2_body: {
        native: "",
        english: ""
      },
      disclaimer3_body: {
        native: "",
        english: ""
      },
      disclaimer4_body: {
        native: "",
        english: ""
      },
      disclaimer1_header: {
        native: "",
        english: ""
      },
      disclaimer2_header: {
        native: "",
        english: ""
      },
      disclaimer3_header: {
        native: "",
        english: ""
      },
      disclaimer4_header: {
        native: "",
        english: ""
      },
    },
    quick_link: [{
      link: {
        native: "",
        english: ""
      },
      name: {
        native: "",
        english: ""
      },
      links: {
        native: [""],
        english: [""],
      },
    }],
    header_menu: [{
      path: {
        native: "",
        english: ""
      },
      title: {
        native: "",
        english: ""
      },
      isEnable: false,
      usageType: "",
      externalLink: {
        native: "",
        english: ""
      },
      handleFunction: {
        native: "",
        english: ""
      },
      externalLinkTarget: "",
    }],
    retry_login: "",
    auto_address: false,
    qr_code_urls: {
      line: {
        native: "",
        english: ""
      },
    },
    sidebar_menu: {
      product_catalogue: {
        native: "",
        english: ""
      },
    },
    topup_message: {
      message: {
        native: "",
        english: ""
      },
    },
    ushop_timeout: "",
    privacy_policy: {
      path: {
        native: "",
        english: ""
      },
      title: {
        native: "",
        english: ""
      },
      imageUrls: {
        native: [""],
        english: [""],
      },
      usageType: "",
      externalLink: {
        native: "",
        english: ""
      },
      cookiesMessage: {
        native: "",
        english: ""
      },
      handleFunction: {
        native: "",
        english: ""
      },
      externalLinkTarget: "",
      displayCookiesMessage: false,
    },
    report_setting: {
      is_enable_kpi: false,
      is_enable_top_pv: false,
      is_enable_top_tv: false,
      is_enable_new_enrollment: false,
    },
    avatar_approval: false,
    login_banner_v2: {
      mobile: {
        native: "",
        english: ""
      },
      desktop: {
        native: "",
        english: ""
      },
    },
    menu_cover_area: {
      type: "",
      background_color: "",
      background_image_url: {
        native: "",
        english: ""
      },
    },
    display_sections: {
      home: {
        promotion: false,
        contact_us: false,
        disclaimer: false,
        opportunity: false,
        best_selling: false,
        top_categories: false,
      },
    },
    payment_processor: "",
    is_enable_cart_qty: false,
    enroll_with_autoship: false,
    terms_and_conditions: {
      status: {
        native: "",
        english: ""
      },
      image_urls: {
        native: [""],
        english: [""],
      },
      html_content: {
        native: "",
        english: ""
      },
    },
    title_product_section: {
      subscribe: {
        native: "",
        english: ""
      },
      bestseller: {
        native: "",
        english: ""
      },
      topcategories: {
        native: "",
        english: ""
      },
    },
    ushop_enroll_birthday: "",
    is_enable_add_autoship: false,
    payment_success_images: {
      shop: [""],
      enroll: [""],
    },
    term_and_condition_order: [{
      status: {
        native: "",
        english: ""
      },
      image_urls: {
        native: [""],
        english: [""],
      },
      html_content: {
        native: "",
        english: ""
      },
    }],
    term_and_condition_enroll: [{
      status: {
        native: "",
        english: ""
      },
      image_urls: {
        native: [""],
        english: [""],
      },
      html_content: {
        native: "",
        english: ""
      },
    }],
    save_credit_card_for_future_use: false,
    is_show_same_day_delivery: false,
  },
})