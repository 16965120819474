import httpHelper from 'Services/HttpHelper'

export const toCurl = (method: string , url: string, headers: any , dataRaw: any) => {
  const curlLines = [
    `curl --location --request ${method} '${httpHelper.Proxy({ originalUrl: url, queryString: '' })}'`,
    ...Object.keys(headers).map(key => `--header '${key}: ${headers[key]}'`),
    `--data-raw '${dataRaw}'`
  ]
  return curlLines.join(` `)
}
