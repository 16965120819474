import React from 'react';
import { Container, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useUser } from 'Stores/User/useUser';
import useProducts from 'Stores/Products/useProducts';

function UserTestPage() {
  useProducts();
  const param = useParams();

  return (
    <Stack>
      <Container>
        <h1>User test page</h1>
      </Container>
    </Stack>
  );
}

export default UserTestPage;
