import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  wrapperHeader: {
    background: '#003B6F',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperContent: {
    background: '#fff',
    height: 'auto',
    padding: '15px',

    '@media (min-width: 820px)': {
      minHeight: 'calc(100vh - 246px)',
    },

    '@media (min-width: 1366px)': {
      padding: '40px',
      minHeight: 'calc(100vh - 296px)',
    },
  },
  ribbon: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    padding: '2px 0px',
    minWidth: '220px',
    transform: 'rotate(36deg) translate(28%, -32%)',
    textAlign: 'center',
    right: 0,
    boxSizing: 'border-box',
    background: '#FAA977',

    '@media (min-width: 600px)': {
      transform: 'rotate(36deg) translate(27%, 4%)',
    }
  },
  renewalPackImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '50px 15px 25px 15px',

    img: {
      width: '300px',
      borderRadius: '8px'
    },

    '@media (min-width: 820px)': {
      padding: 0
    },
  },
  renewalPackWrapper: {
    height: 'auto',
    background: '#FDF4E5',
    position: 'relative',
    borderRadius: '12px',
    width: '100%',
    overflow: 'hidden',

    '@media (min-width: 820px)': {
      flexDirection: 'row'
    }
  },
  renewalPackDetailWrapper: {
    padding: '15px 15px 25px 15px',
    '@media (min-width: 820px)': {
      width: '55%',
      padding: '20px 20px 20px 0px',
    },

    '@media (min-width: 1366px)': {
      width: '63%',
      padding: '30px 40px 30px 30px',
    }
  },
  renewalBasicWrapper: {
    height: 'auto',
    background: '#F0F2F5',
    position: 'relative',
    borderRadius: '12px',
    padding: '25px 15px',

    '@media (min-width: 820px)': {
      flexDirection: 'row',
      padding: 0,
    }
  },
  renewalBasicNameBox: {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',

    '@media (min-width: 820px)': {
      width: '45%',
      marginBottom: 0,
      justifyContent: 'center',
    },

    '@media (min-width: 1366px)': {
      width: '40%',
    }
  },
  renewalBasicDetailWrapper: {
    padding: 0,
    '@media (min-width: 820px)': {
      width: '55%',
      padding: '20px 20px 20px 0px',
    },

    '@media (min-width: 1366px)': {
      width: '63%',
      padding: '30px 40px 30px 30px',
    }
  },
  backDrop: {
    position: 'absolute',
    bottom: -80,
    top: -71,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  }
};
