import { atom } from 'recoil';
import { AddressHistory } from '../../Constants/Interface/AddressHistory/AddressHistory';

export const addressHistoryState = atom<AddressHistory>({
  key: 'addressHistoryState',
  default: {
    items: [],
    mainAddress: {
      displayAddress: '',
      shipToAddress: {
        displayAddress: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      shipToName: {
        firstName: '',
        lastName: '',
        fullName: '',
      },
      ushopShipToName: '',
    },
    loadingApi: false,
    login_id: '',
    totalItems: 0,
    ushopCountryCode: '',
  },
});

export const isAddressHistoryLoadingState = atom<boolean>({
  key: 'isAddressHistoryLoadingState',
  default: false,
});
