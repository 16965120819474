import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Box, Divider, Stack } from '@mui/material'
import styled from '@emotion/styled'
import { useTranslate } from 'Stores/Dictionaries/useDictionaries'
import { guideState } from 'Stores/Guide/GuideStore'
import { RenewalAPI } from 'Services/RenewalAPIs'
import './style.css'

type Props = {
  step: number;
  setStep: (step: number) => void;
};

const PopupOrderSummaryGuide = ({ step, setStep }: Props) => {
  const [translate] = useTranslate()
  const [guide, setGuide] = useRecoilState(guideState)

  const handleClickSetting = async () => {
    try {
      if (step < 1) {
        setStep(step + 1)
      } else if (step === 1) {
        const baId = sessionStorage.getItem('baId')
        const newGuide = {
          ...guide,
          isOrderSummaryGuide: false
        }
        const res = await RenewalAPI.postIsShowGuide(baId as string, newGuide)
        if (res) {
          sessionStorage.setItem('isGuide', JSON.stringify(newGuide))
          setGuide(newGuide);
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const positionPopupDesktop = [
    { left: 200, top: 230 },
    { left: 200, top: 74 },
  ]

  const positionPopupMobile = [
    { right: 64, top: -132 },
    { right: 64, top: 72 },
  ]

  return (
    <Box
      sx={{
        position: 'absolute',
        right: positionPopupMobile[step].right ? positionPopupMobile[step].right : 88,
        top: positionPopupMobile[step].top ? positionPopupMobile[step].top : 18,
        zIndex: 9999,
        '@media (min-width: 768px)': {
          left: positionPopupDesktop[step].left ? positionPopupDesktop[step].left : -300,
          top: positionPopupDesktop[step].top ? positionPopupDesktop[step].top : 20
        }
      }}>
      <Box className={`popup-centurion ${step >= 0 ? `arrow-order-summary-step-${step}` : 'arrow'}`}>
        <Box
          sx={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#0057AC',
            position: 'relative',
            zIndex: 998,
            textAlign: 'left',
            color: '#EAEBEB'
          }}>
          {step === 0 && (
            <Stack sx={{ fontSize: '12px', fontWeight: 300, gap: '3px' }}>
              <Box sx={{ fontSize: '14px', fontWeight: 500 }}>{translate('urenew_guide_order_summary_title_1')}</Box>
              <Box>{translate('urenew_guide_order_summary_content_1')}</Box>
            </Stack>
          )}

          {step === 1 && (
            <Stack sx={{ fontSize: '12px', fontWeight: 300, gap: '3px' }}>
              <Box sx={{ fontSize: '14px', fontWeight: 500 }}>{translate('urenew_guide_order_summary_title_2')}</Box>
              <Box>{translate('urenew_guide_order_summary_content_2')}</Box>
            </Stack>
          )}

          <Divider sx={{ background: '#CCC', marginTop: '16px', marginBottom: '8px' }} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box sx={{ fontSize: '14px', fontWeight: 500 }}>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <CustomButton onClick={() => handleClickSetting()}>{step === 1 ? translate('urenew_guide_done') : translate('urenew_guide_next')}</CustomButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const CustomButton = styled.button<any>`
  padding: 6px 16px;
  color: #fff;
  background: #009ecf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`

export default PopupOrderSummaryGuide
