import { createTheme } from '@mui/material/styles';

const headerVariant = {
  fontFamily: 'Prompt',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  '@media (min-width: 820px)': {
    fontSize: '24px',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    menu: React.CSSProperties
    menuFooter: React.CSSProperties
    title: React.CSSProperties;
    title2: React.CSSProperties;
    label: React.CSSProperties;
    label2: React.CSSProperties;
    label3: React.CSSProperties;
    label4: React.CSSProperties;
    label5: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
    caption5: React.CSSProperties;
    caption6: React.CSSProperties;
    textSelectRenewal: React.CSSProperties
    navbarCountry: React.CSSProperties
    footerCopyright: React.CSSProperties
    textHeaderCard: React.CSSProperties
    textDeleteCardProduct: React.CSSProperties
    textCodeCard: React.CSSProperties
    textPriceCard: React.CSSProperties
    footerError: React.CSSProperties
    header: typeof headerVariant;
    addMoreProduct: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    menu?: React.CSSProperties
    menuFooter?: React.CSSProperties
    title?: React.CSSProperties;
    title2?: React.CSSProperties;
    label?: React.CSSProperties;
    label2?: React.CSSProperties;
    label3?: React.CSSProperties;
    label4?: React.CSSProperties;
    label5?: React.CSSProperties;
    caption2?: React.CSSProperties;
    caption3?: React.CSSProperties;
    caption4?: React.CSSProperties;
    caption5?: React.CSSProperties;
    caption6?: React.CSSProperties;
    textSelectRenewal?: React.CSSProperties
    navbarCountry?: React.CSSProperties
    footerCopyright?: React.CSSProperties
    textHeaderCard?: React.CSSProperties
    textDeleteCardProduct?: React.CSSProperties
    textCodeCard?: React.CSSProperties
    textPriceCard?: React.CSSProperties
    footerError?: React.CSSProperties
    header?: typeof headerVariant;
    addMoreProduct?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menu: true
    menuFooter: true
    title: true;
    title2: true;
    label: true;
    label2: true;
    label3: true;
    label4: true;
    label5: true;
    caption2: true;
    caption3: true;
    caption4: true;
    caption5: true;
    caption6: true;
    textSelectRenewal: true
    navbarCountry: true
    footerCopyright: true
    textHeaderCard: true
    textDeleteCardProduct: true
    textCodeCard: true
    textPriceCard: true
    footerError: true
    header: true,
    addMoreProduct: true
  }
}

export const MainTheme = createTheme({
  palette: {
    primary: {
      main: '#153862',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#D1D9E2',
    },
  },
  typography: {
    textSelectRenewal: {
      fontSize: '24px',
      color: '#003B6F',
      fontWeight: 400,
    },
    navbarCountry: {
      color: '#003B6F',
      fontWeight: 300,
      fontSize: '14px',
    },
    footerCopyright: {
      color: '#ffffff',
      fontWeight: 300,
      fontSize: '14px',
    },
    textHeaderCard: {
      fontSize: '20px',
      color: '#003B6F',
      fontWeight: 500,
    },
    textCodeCard: {
      fontSize: '14px',
      color: '#9EA9B9',
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    textPriceCard: {
      fontSize: '16px',
      color: '#003B6F',
      fontWeight: 300,
      textTransform: 'uppercase',

    },
    textDeleteCardProduct: {
      fontFamily: 'Prompt',
      fontSize: '24px',
      color: '#FF6187',
      fontWeight: 300,
      lineHeight: '36.29px',
      textAlign: 'center',
    },
    footerError: {
      fontSize: '20px',
      color: '#FF6187',
      fontWeight: 300,
    },
    addMoreProduct: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      color: '#003B6F'
    },
    h2: {
      fontSize: 60,
      fontFamily: 'Prompt',
      fontWeight: 600,
      color: '#0B3B61',
      fontStyle: 'normal',
      '@media (max-width: 430px)': {
        fontSize: '33px',
      },
    },
    h3: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      color: '#0B3B61',
    },
    h4: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#0B3B61',
      '@media (max-width: 430px)': {
        fontSize: '16px',
      },
    },
    h5: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      color: '#0B3B61',
    },
    h6: {
      fontSize: 40,
      fontFamily: 'Prompt',
      fontWeight: 600,
      color: '#0B3B61',
      fontStyle: 'normal',
      '@media (max-width: 430px)': {
        fontSize: '28px',
      },
    },
    body1: {
      fontFamily: 'Prompt, sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '36px',
      color: '#0B3B61',
      '@media (max-width: 430px)': {
        fontSize: '24px',
      },
    },
    body2: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      color: '#0B3B61',
      '@media (max-width: 430px)': {
        fontSize: '16px',
      },
    },
    header: headerVariant,
    title: {
      fontFamily: 'Prompt',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '30px',
      color: '#FAA977',
    },
    title2: {
      fontFamily: 'Prompt',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: '#33628C',
    },
    label: {
      fontFamily: 'Prompt',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#003B6F',
    },
    label2: {
      fontFamily: 'Prompt',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#003B6F',
    },
    label3: {
      fontFamily: 'Prompt',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#fff',
      letterSpacing: '0.2px'
    },
    label4: {
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: '#33628C',
    },
    label5: {
      fontFamily: 'Prompt',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: '#33628C',
    },
    caption: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 'normal',
      color: '#003B6F',
    },
    caption2: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: 'normal',
      color: '#003B6F',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    caption3: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 'normal',
      color: '#5A8FC3',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    caption4: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#003B6F',
    },
    caption5: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: 'normal',
      color: '#003B6F',
    },
    caption6: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: 'normal',
      color: '#707783',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '65px',
          boxShadow: 'none',
          '&.has-shadow': {
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)!important',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: 'none',
          '&.card--with-picture': {
            border: 'none',
            boxShadow: 'none',
            borderRadius: 0,
            '@media (max-width: 768px)': {
              paddingLeft: 15,
              paddingRight: 15,
            },
          },
          '&.card--form': {
            border: 'none',
            boxShadow: '0px 1px 2px rgba(14, 35, 56, 0.2)',
            borderRadius: 12,
          },
          '&.card--products': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '&.card--payment': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '&.card--menu': {
            backgroundColor: '#003b6f',
            borderRadius: 12,
            minHeight: 200,
            height: 'fit-content',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '&.logo': {
            // paddingTop: "25px",
            // paddingBottom: "25px",
            // minHeight: "0",
            height: '100%',
          },
          '&.footer': {
            background: '#03274C',
            height: '64px',
          },
          '&.error': {
            background: '#FFE7EB',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '&.menu-login': {
            '.MuiMenu-paper': {
              border: '1px solid #F1F5F8',
              top: '60px!important',
              boxShadow: '0px 4px 18px rgba(0,0,0,.05)',
              width: '360px',
            },
            '.MuiList-root': {
              padding: '30px',
            },
            '.MuiButtonBase-root': {
              '&.MuiMenuItem-root': {
                padding: '0px',
                '&:hover': {
                  backgroundColor: 'transparent!important',
                },
              },
            },
          },
          '&.menu-select-country': {
            '.MuiMenu-paper': {
              border: '1px solid #F1F5F8',
              top: '60px!important',
              boxShadow: '0px 4px 18px rgba(0,0,0,.05)',
              width: '320px',
            },
            '.MuiList-root': {
              padding: '30px 30px 20px 30px',
            },
            '.MuiButtonBase-root': {
              '&.MuiMenuItem-root': {
                padding: '0px',
                '&:hover': {
                  backgroundColor: 'transparent!important',
                },
              },
            }
          },
        },
      },
    },
  },
})
