import { useState } from 'react';
import { Stack, Box, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close-icon.svg'
import Ushop from './Ushop';
import Express from './Express';
import FeelGreat from './FeelGreat';
import { styles } from './CountryModal.style'

type Props = { open: boolean, setOpen: (open: boolean) => void };

const CountryModal = ({ open, setOpen }: Props) => {

  const [value, setValue] = useState('1');

  const [translate] = useTranslate()

  const handleClose = () => {
    setOpen(!open)
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={styles.modalWrapper}
    >
      <Stack sx={styles.contentStack}>
        <Box
          sx={{
            marginBottom: '20px',
            padding: '0px 30px',

            '@media (min-width: 1366px)': {
              padding: '0px 40px',
            }
          }}>
          <Typography
            variant="label2"
            sx={{
              '@media (max-width: 375px)': {
                fontSize: '22px',
              },

              '@media (min-width: 1366px)': {
                fontSize: '30px',
              }
            }}
          >
            {translate('urenew_select_country_language')}
          </Typography>

          <Box
            onClick={handleClose}
            sx={styles.closeIconStyle}>
            <CloseIcon width={10} />
          </Box>
        </Box>
        <TabContext value={value}>
          <Box sx={{
            padding: '0 20px',

            '@media (min-width: 1366px)': {
              padding: '0px 40px',
            }
          }}>
            <TabList
              onChange={handleChange}
              sx={styles.tabListBox}>
              <Tab label="Ushop" value="1" />
              <Tab label="Express" value="2" />
              <Tab label="Feelgreat" value="3" />
            </TabList>
          </Box>

          <Box sx={{
            background: '#daf0f0',
            overflow: 'hidden',
            height: '100%'
          }}>
            <Box sx={styles.tabPanelWrapper}>
              <TabPanel value="1">
                <Ushop />
              </TabPanel>
              <TabPanel value="2">
                <Express />
              </TabPanel>
              <TabPanel value="3">
                <FeelGreat />
              </TabPanel>
            </Box>
          </Box>
        </TabContext>
      </Stack>
    </Dialog>
  )
}

export default CountryModal