import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  footerWrapper: {
    background: '#03274C',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
  },
  footerBoxWidth: {
    maxWidth: '1366px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (min-width: 1366px)': {
      justifyContent: 'space-between',
    }
  },
  footerBoxText: {
    paddingLeft: '20px',
    display: 'none',
    width: '100%',
    '@media (min-width: 1366px)': {
      display: 'block',
      paddingLeft: '40px',
    }
  },
  footerLanguageWrapper: {
    paddingRight: '20px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (min-width: 1366px)': {
      paddingLeft: '0px',
      paddingRight: '40px',
      gap: '20px',
      justifyContent: 'end',
    }
  },
  footerImageFlag: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#021F36',
    width: '133px',
    borderRadius: '7px',
    cursor: 'pointer',
    gap: '10px',
  },
  footerLanguageBox: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#021F36',
    width: '187px',
    borderRadius: '7px',
    gap: '15px',
    '@media (min-width: 1366px)': {
      width: '197px',
    }
  },
  headerWrapper: {
    position: 'fixed',
    background: '#fff',
    height: '55px',
    zIndex: 1,
    '@media (min-width: 768px)': {
      height: '71px',
    }
  },
  headerBoxWidth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  headerLogoMenu: {
    padding: '0 21px',
    borderRight: '1px solid #E3E8ED',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '@media (min-width: 768px)': {
      padding: '0 30px',
    },
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '30px',
  },
  mainWrapper: {
    minHeight: 'calc(100vh - 151px)',
    marginTop: '55px',
    '@media (min-width: 768px)': {
      marginTop: '71px',
    }
  }
};
