/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback, useEffect, useState, ReactEventHandler, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import mobiscroll from '@mobiscroll/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LoadingSubmitFormState, detailOrderSummaryState, errorPaymentFormState, isLoadingFormatApiState, paymentChannelState, paymentFormState } from 'Stores/OrderSummary/OrderSummaryStore';
import { countryState } from 'Stores/Countries/CountriesStore';
import { Box, Card, CardContent, Modal, Stack } from '@mui/material';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { delay } from 'Utils/functions';
import { toCurl } from 'Utils/ToCurl';
import { ServerEnv } from 'Cores/Countries/Environment/ServerEnv';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { guideState } from 'Stores/Guide/GuideStore';
import { maskedCreditCard } from 'Utils/CheckCardType';
import { getBaStatus, userState } from 'Stores/User/UserStore';
import { footerState } from 'Stores/Footer/FooterStore';
import { isBasicItemCode } from 'Stores/Products/ProductStore';
import ProductsCartPayment from './ProductsCartPayment';
import { styles } from './OrderSummary.style'
import ShipToBox from './ShipToBox';
import TotalPriceBox from './TotalPriceBox';
import PaymentBox from './PaymentBox';
import OrderSummarySkeleton from './OrderSummarySkeleton';
import PopupOrderSummaryGuide from '../UrenewGuide/PopupOrderSummaryGuide';

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light',
};


const OrderSummary = () => {
  const [translate] = useTranslate()
  const isLoading = useRecoilValue(isLoadingFormatApiState)
  const detail = useRecoilValue(detailOrderSummaryState)
  const paymentType = useRecoilValue(paymentChannelState)
  const paymentForm = useRecoilValue(paymentFormState)
  const footerData = useRecoilValue(footerState)
  const basicItemCode = useRecoilValue(isBasicItemCode)
  const country = useRecoilValue(countryState);
  // const user = useRecoilValue(userState)
  const baStatus = getBaStatus()
  const [, setLoadingSubmitForm] = useRecoilState(LoadingSubmitFormState)
  const [, setErrorMessages] = useRecoilState(errorPaymentFormState)
  const [guide, setGuide] = useRecoilState(guideState)
  const [searchParams,] = useSearchParams();
  const token = sessionStorage.getItem('tokenHydra')!

  const uuid = searchParams.get('uuid')
  const isBasicPack = detail.productsDisplay[0]?.item_code === basicItemCode
  const [processingPaymentSuccess, setProcessingPaymentSuccess] = useState(false)
  const [processingPaymentFail, setProcessingPaymentFail] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [ddcHTML, setDdcHTML] = useState(null)
  const [challengeHTML, setChallengeHTML] = useState('')
  const [countPathChange, setCountPathChange] = useState(0)
  // const [frameElement, setFrameElement] = useState<any>(null)
  const [createOrderResponse, setCreateOrderResponse] = useState<any>({})
  const [createPaymentResponse, setCreatePaymentResponse] = useState<any>({})
  const [isStep, setIsStep] = useState<number>(0)

  const paymentChannel = paymentType.channel
  const navigate = useNavigate();

  const paymentRef = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   console.log('HOOK ', frameElement?.contentDocument?.body?.innerHTML?.length)
  // }, [frameElement])

  useEffect(() => {
    if (countPathChange > 2) {
      continuePayment()
    }
  }, [countPathChange])

  useEffect(() => {
    if (processingPaymentSuccess) {
      continuePayment()
    }
  }, [processingPaymentSuccess])

  useEffect(() => {
    if (processingPaymentFail) {
      updateLogDataPaymentFail()
    }
  }, [processingPaymentFail])

  const continuePayment = async () => {
    setOpenModal(false)
    const orderId = createOrderResponse.data.id.unicity
    // check logic payment success or fail
    // check order status
    const orderStatus = await RenewalAPI.HydraOrderStatus(orderId, token!, country.shorter.toLowerCase())
    if (paymentChannel === 'creditcard') {
      const orderStatusData = orderStatus.data[0].transactions.items[0].methodDetails.bankName
      // logic check payment success https://app.clickup.com/t/86dqm6c6d?comment=90170003119980&threadedComment=90170003171516
      if (orderStatusData === 'WP-CC') {
        setProcessingPaymentFail(true)
        return
      }
    }
    await updateLogDataAndSendEmail()
    // go success page
    navigate(`/payment-success/${orderId}`)
    setLoadingSubmitForm(false)
  }

  const updateLogDataAndSendEmail = async () => {
    const bodyMasking = maskedCreditCard(createOrderResponse.config.data)
    const baId = createOrderResponse.data.customer.id.unicity
    const updateLogData = {
      payments_id: createPaymentResponse.data.payment_id,
      dist_id: baId,
      dist_status: baStatus,
      payment_status: "success",
      order_number: createOrderResponse.data.id.unicity,
      approval_code: createOrderResponse.data.transactions?.items[0]?.authorization,
      hydra_bearer: token!,
      hydra_curl: toCurl('POST', `${ServerEnv.Hydra()}/customers/me/orders`, { 'Content-Type': `application/json` }, bodyMasking),
      hydra_response_header: JSON.stringify(createOrderResponse.headers),
      hydra_response_body: JSON.stringify(createOrderResponse.data)
    }

    const sendEmailData = {
      to_email: createOrderResponse.data.shipToEmail,
      user_id: baId,
      order_id: createOrderResponse.data.id.unicity,
      ship_to_name: createPaymentResponse.data.ushop.fullname,
      ship_to_address: createPaymentResponse.data.ushop.display_address,
      shipping_type: "delivery",
      product_items: createPaymentResponse.data.email.products_email
    }

    await Promise.allSettled([
      RenewalAPI.sendEmail(sendEmailData),
      RenewalAPI.updateLog(updateLogData)
    ])
  }

  const updateLogDataPaymentFail = async () => {
    const bodyMasking = maskedCreditCard(createOrderResponse.config.data)
    const baId = createOrderResponse.data?.customer.id.unicity || ''
    const hydraResponseBody = JSON.stringify(createOrderResponse.data) || JSON.stringify(createOrderResponse.response.data)
    const updateLogData = {
      payments_id: createPaymentResponse.data.payment_id,
      payment_status: "fail",
      dist_id: baId,
      dist_status: baStatus,
      approval_code: '',
      order_number: '',
      hydra_bearer: token!,
      hydra_curl: toCurl('POST', `${ServerEnv.Hydra()}/customers/me/orders`, { 'Content-Type': `application/json` }, bodyMasking),
      hydra_response_header: JSON.stringify(createOrderResponse.headers),
      hydra_response_body: hydraResponseBody
    }
    const hydraErrorMessageJson = JSON.parse(hydraResponseBody)
    const message = hydraErrorMessageJson?.error?.error_message
      || hydraErrorMessageJson?.error?.message || hydraErrorMessageJson?.message || hydraErrorMessageJson?.error

    const suffixError = `${translate('urenew_payment_fail')}. Ref #:${createPaymentResponse.data.payment_id}`
    const messageError = []
    messageError.push(message)
    messageError.push(suffixError)
    // setErrorMessages(`${translate('urenew_payment_fail')}. Ref #:${createPaymentResponse.data.payment_id}`)
    setErrorMessages(messageError)
    await RenewalAPI.updateLog(updateLogData)
    setLoadingSubmitForm(false)
  }

  const submitForm = async () => {
    setLoadingSubmitForm(true)
    setProcessingPaymentSuccess(false)
    setProcessingPaymentFail(false)

    if (paymentChannel === 'creditcard') {
      const { data, status } = await RenewalAPI.HydraWorldPayReqForm(paymentForm.card_number, country.shorter.toLowerCase())
      if (status === 200) {
        const cleanedHtml = data.ddcHTML.replace(/\r\n/g, '')
        setDdcHTML(cleanedHtml)
        await delay(4250)
        const bodyHydra = await generateBody(data.sessionId, paymentChannel)
        try {
          const paymentHydra = await RenewalAPI.paymentHydra(token!, bodyHydra, country.shorter.toLowerCase())
          setCreateOrderResponse(paymentHydra)
          if (paymentHydra.data.transactions.items.length) {
            const { methodDetails } = paymentHydra.data.transactions.items[0]
            if (methodDetails.challengeHTML) {
              setChallengeHTML(methodDetails.challengeHTML.replace(/\r\n/g, ''))
              setOpenModal(true)
            } else {
              setProcessingPaymentSuccess(true)
            }
          }
        } catch (error) {
          setCreateOrderResponse(error)
          setProcessingPaymentFail(true)
        }
      }
    } else if (paymentChannel === 'bank_wire') {
      try {
        const bodyHydra = await generateBody('', paymentChannel)
        const paymentHydra = await RenewalAPI.paymentHydra(token!, bodyHydra, country.shorter.toLowerCase())
        setCreateOrderResponse(paymentHydra)
        setProcessingPaymentSuccess(true)
      } catch (error) {
        setCreateOrderResponse(error)
        setProcessingPaymentFail(true)
      }
    }
  }

  const generateBody = async (id: string, paymentTypeChannel: string) => {
    let tempExpDate = paymentForm.exp_date.toString().split('/')
    tempExpDate = `${tempExpDate[1]}-${tempExpDate[0]}`
    const createPayment = await RenewalAPI.createPayment(uuid!)
    setCreatePaymentResponse(createPayment)

    const method = 'CreditCard'
    let typePayments = 'AuthorizeAndCapture'
    const paymentProcessor = footerData.payment_processor
    if (paymentProcessor === '3DSv2') {
      typePayments = 'IOU'
    } else if (paymentProcessor === 'MOTO') {
      typePayments = 'AuthorizeAndCapture'
    }
    const transactions = paymentTypeChannel === 'creditcard' ? {
      "items": [
        {
          // type: 'IOU',
          type: typePayments,
          method,
          amount: 'this.terms.total',
          methodDetails: {
            "payer": paymentForm.name,
            "creditCardNumber": paymentForm.card_number,
            "creditCardExpires": tempExpDate,
            "creditCardSecurityCode": paymentForm.cvv,
            "languageDialect": "en-US",
            "challengeWindowSize": "fullPage",
            "sessionId": id,
            "bankName": "WorldPay"
          },
          "billToAddress": {
            "country": "JP"
          },
          "billToEmail": "it-exp@unicity.com"
        }
      ]
    } : {
      items: [
        {
          amount: "this.terms.total",
          type: "IOU",
          method: "BankWire",
          methodDetails: []
        }
      ]
    }

    const body = {
      ...createPayment.data.hydra,
      transactions
    }
    return body
  }

  // const handleFrameElement: ReactEventHandler = (e) => {
  //   setFrameElement(e.currentTarget);
  // }

  const scrollToCenturion = () => {
    if (paymentRef.current && guide.isOrderSummaryGuide && !isLoading) {
      paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    // document.body.classList.add('backdrop-active');
  }

  useEffect(() => {
    scrollToCenturion()

    const sessionGuide: any = sessionStorage.getItem('isGuide')
    setGuide(JSON.parse(sessionGuide))
  }, [isStep])

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <Stack>
      {!isLoading && guide.isOrderSummaryGuide && <Box sx={styles.backDrop} />}

      {!isLoading && <Stack sx={styles.container}>
        <Stack gap='15px'>
          <ProductsCartPayment />
          {!isBasicPack && <ShipToBox />}
        </Stack>

        <Stack ref={paymentRef} gap='15px' style={{ position: 'relative' }}>
          <Box sx={isStep === 0 && guide.isOrderSummaryGuide ? { zIndex: 14, pointerEvents: 'none' } : {}}>
            <TotalPriceBox />
          </Box>
          <PaymentBox
            isBackDropBtn={isStep === 1 && guide.isOrderSummaryGuide}
            submitForm={submitForm}
          />

          {!isLoading && guide.isOrderSummaryGuide && <PopupOrderSummaryGuide step={isStep} setStep={setIsStep} />}
        </Stack>
      </Stack>}

      {isLoading && <OrderSummarySkeleton />}

      {
        ddcHTML && (
          <iframe
            srcDoc={ddcHTML}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            // onLoad={handleFrameElement}
            style={{ display: 'none' }}
          />
        )
      }

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="worldpay-modal-modal-title"
        aria-describedby="worldpay-modal-modal-description"
      >
        <Box
          sx={{
            margin: '0px 0px 20px 0px',
            backgroundColor: '#fff',
            display: !challengeHTML ? 'none' : countPathChange < 2 ? 'block' : 'none'
          }}>

          <iframe
            srcDoc={challengeHTML}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            onLoad={(e) => {
              // handleFrameElement(e);
              setCountPathChange((cpc) => cpc + 1);
            }}
            width="100%"
            height="800"
          />
        </Box>
      </Modal >
    </Stack >
  )
}
export default OrderSummary