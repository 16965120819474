import { SelectAddressType, SelectTempAddressType, ZipCodeListType, SelectTempZipState, ResponseShippingType, OrderDetailFormType, SelectPrefecture, ZipCodeInterface, ZipCodeTemp } from 'Constants/Interface/OrderDetail';
import { atom } from 'recoil';

export const isAddressHistoryApiLoadingState = atom<boolean>({
  key: 'isAddressHistoryApiLoading',
  default: false,
});

export const orderDetailFormState = atom<OrderDetailFormType>({
  key: 'newAddressForm',
  default: {
    fullname: '',
    email: '',
    phone: '',
    shipment_time: 'none',
    shipment_time_label: 'none',
    send_confirmation: false,
    send_tax_invoice: false,
    shipment_options: 'delivery',
    selectAddressHistory: "addressHistory",
    shipToAddress: {},
    house_number: '',
    town: '',
    district: '',
    prefecture: '',
    zip: ''
  },
});

export const selectAddressState = atom<SelectAddressType>({
  key: 'selectAddress',
  default: {
    addressHref: '',
    shipToAddress: { displayAddress: '', address1: '', address2: '', city: '', country: '', state: '', zip: '' },
    shipToName: { fullName: '', lastName: '' },
    shipToOptionId: { unicity: '' },
    displayAddress: '',
    ushopShipToName: ''
  }
});

export const selectTempAddressState = atom<SelectAddressType>({
  key: 'selectTempAddress',
  default: {
    addressHref: '',
    shipToAddress: { displayAddress: '', address1: '', address2: '', city: '', country: '', state: '', zip: '' },
    shipToName: { fullName: '', lastName: '' },
    shipToOptionId: { unicity: '' },
    displayAddress: '',
    ushopShipToName: ''
  }
});

export const searchZipState = atom<string>({
  key: 'searchZipState',
  default: '',
});

export const zipCodeListState = atom<ZipCodeListType>({
  key: 'zipCodeList',
  default: {
    success: false,
    data: [{ city_native: '', city_roman: '', country_code: '', id: 0, priority: '', return_address_native: '', return_address_roman: '', search_address_native: '', search_address_roman: '', sub1_native: '', sub1_roman: '', sub2_native: '', sub2_roman: '', sub3_native: '', sub3_roman: '', zip: '' }]
  },
});

export const selectTempZipState = atom<SelectTempZipState>({
  key: 'selectTempZipState',
  default: {
    index: -1,
    zipCode: { city_native: '', city_roman: '', country_code: '', id: 0, priority: '', return_address_native: '', return_address_roman: '', search_address_native: '', search_address_roman: '', sub1_native: '', sub1_roman: '', sub2_native: '', sub2_roman: '', sub3_native: '', sub3_roman: '', zip: '' },
  },
});

/* export const selectZipState = atom<any>({
  key: 'selectZipState',
  default: {},
}); */

export const responseShippingState = atom<ResponseShippingType>({
  key: 'responseShippingState',
  default: {
    log_id: 0,
    success: null,
    uuid: '',
    Message_v2: []
  },
});

export const isLoadingSubmitFormOrderDetailState = atom<boolean>({
  key: 'isLoadingSubmitFormOrderDetail',
  default: false,
});

export const selectPrefectureState = atom<SelectPrefecture[]>({
  key: 'selectPrefectureState',
  default: [],
});

export const zipCodeState = atom<ZipCodeInterface[]>({
  key: 'zipCodeState',
  default: [],
});

export const setZipCodeTempState = atom<ZipCodeTemp>({
  key: 'setZipCodeTempState',
  default: {
    id: 0,
    town: '',
    district: '',
    prefecture: '',
    zip: ''
  },
});

export const isLoadingOrderDetailState = atom<boolean>({
  key: 'isLoadingOrderDetail',
  default: false,
})

export const isLoadingValidateOrderDetailState = atom<boolean>({
  key: 'isLoadingValidateOrderDetail',
  default: false,
})
