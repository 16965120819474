import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { checkCardType } from 'Utils/CheckCardType';
import { orderDetailFormState, selectAddressState } from 'Stores/OrderDetail/OrderDetailStore';
import { isBasicItemCode, productsState } from 'Stores/Products/ProductStore';
import { cartState } from 'Stores/Cart/CartInterface';
import { detailOrderSummaryState, uuidOrderSummaryState, isLoadingFormatApiState, cardTypeState, paymentFormState, isLoadingState } from './OrderSummaryStore';

const useOrderSummary = () => {
  const [detail, setDetail] = useRecoilState(detailOrderSummaryState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingFormatApiState)
  const [cardType, setCardType] = useRecoilState(cardTypeState)
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentForm = useRecoilValue(paymentFormState);
  const uuid = searchParams.get("uuid")
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);
  const [selectAddress, setSelectAddress] = useRecoilState(selectAddressState);

  const products = useRecoilValue(productsState);
  const [cartItems, setCartItems] = useRecoilState(cartState)
  const basicItemCode = useRecoilValue(isBasicItemCode)

  useEffect(() => {
    getDetail()
  }, [products]);

  useEffect(() => {
    const card = checkCardType(paymentForm.card_number)
    setCardType(card)
  }, [paymentForm.card_number])

  const getDetail = async () => {
    setIsLoading(true)
    try {
      if (products.items.length > 0) {
        const isCalOrder = /orderSummary/.test(window.location.pathname)
        const result = await RenewalAPI.getOrderSummaryFormat(uuid!, isCalOrder)
        const itemsJson = JSON.parse(result.data.ushop.detail_page.items)
        const cart = itemsJson.map((item: any) => {
          if (item.item_code === '32120' || item.item_code === '35413' || item.item_code === basicItemCode) {
            const findItems = products.renewal.find((product) => product.item_code === item.item_code)
            return {
              product: findItems,
              quantity: item.qty
            }
          }
          const findItems = products.items.find((product) => product.item_code === item.item_code)
          return {
            product: findItems,
            quantity: item.qty
          }
        })
        // const { phone } = result.data.ushop.detail_page

        // let formattedPhone = ''
        // if (phone) {
        //   if (phone.length === 10) {
        //     formattedPhone = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
        //   } else if (phone.length === 11) {
        //     formattedPhone = phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
        //   } else {
        //     formattedPhone = phone;
        //   }
        // }

        setCartItems(cart)
        setDetail(result.data.ushop)
        
        // setSelectAddress({
        //   ...selectAddress,
        //   displayAddress: result.data.ushop.urenew.formatContact.display_address,
        //   ushopShipToName: result.data.ushop.urenew.formatContact.name
        // })
        // setAddressForm({
        //   ...result.data.ushop.detail_page,
        //   phone: formattedPhone,
        //   shipment_time_label: result.data.ushop.detail_page.shipment_time

        // })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.warn(error)
    }
  }
};

export default useOrderSummary;
