import { atom } from 'recoil';

interface DictionaryInterface {
  [language: string]: {
    [key: string]: string[]
  }
}

export const dictionariesState = atom<DictionaryInterface>({
  key: 'dictionariesState',
  default: {
    en: {},
  },
});

export const isDictionariesApiLoadingState = atom<boolean>({
  key: 'isDictionariesApiLoadingState',
  default: false,
});

export const languageState = atom<string>({
  key: 'languageState',
  default: 'en',
});
