import { atom } from 'recoil';
import { Product } from '../../Constants/Interface/Product';

export interface CartInterface {
  product: Product
  quantity: number
}

export const cartState = atom<CartInterface[]>({
  key: 'cartState',
  default: [],
});
