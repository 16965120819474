/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Product } from 'Constants/Interface/Product';
import { getProductNameOnConfig } from 'Utils/functions';
import { isProductApiLoadingState, productsState } from './ProductStore';
import { Products } from '../../Constants/Interface/Products';

export default function useProducts() {
  const [products, setProducts] = useRecoilState<Products>(productsState);
  const [isLoading, setIsLoading] = useRecoilState(isProductApiLoadingState);

  useEffect(() => {
    if (isLoading === false && products.items.length === 0) {
      setIsLoading(true);
    }
  }, [products.items.length, isLoading, setIsLoading]);

  useEffect(() => {
    if (isLoading) {
      const GetProducts = async () => {
        try {
          const response = await axios.get('https://member-calls2.unicity.com/products-v2/publish/jpn?allow=enroll&status=A');
          const newProductsRenewal = response.data.renewal.map((element: Product) => {
            // renewal product need to change name but don't need change name on live 
            //  **** https://app.clickup.com/t/865d5nf0z?comment=90080058953228

            const productName = getProductNameOnConfig(element)
            return {
              ...element,
              item_name: productName
            }
          })

          const newProducts = {
            ...response.data,
            renewal: newProductsRenewal
          }
          setProducts(newProducts);
          
          setIsLoading(false);
        } catch (error) {
          /** TODO: catch */
          // eslint-disable-next-line no-console
          console.warn({ error });
        }
      };

      GetProducts();
    }
  }, [isLoading, setProducts, setIsLoading]);
}
