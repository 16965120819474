import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  container: {
    padding: '15px 15px',
    gap: '15px',
    display: 'flex',
    minHeight: 'inherit',
    '@media (min-width: 768px)': {
      padding: '30px 0',
      margin: '0 auto'
    },
    '@media (min-width: 1366px)': {
      flexDirection: 'row',
      gap: '30px',
    }
  },
  productCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '306px',
    }
  },
  shipToCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '306px',
    }
  },
  totalCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#003B6F',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '596px',
    }
  },
  paymentCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '596px',
    }
  },
  dividerStyle: {
    margin: '15px 0px',
    background: '#E9F0F7'
  },
  creditCardBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '.credit-card-icon': {
      color: '#99BCDF',
    },
    '.credit-card-text': {
      fontWeight: 400,
      color: '#6687A2'
    },
    '.credit-card-arrow-icon': {
      color: '#99BCDF'
    },

    '&:hover': {
      '.credit-card-icon': {
        color: '#003B6F',
      },
      '.credit-card-text': {
        fontWeight: 600,
        color: '#003B6F'
      },
      '.credit-card-arrow-icon': {
        color: '#003B6F',
      },
    }
  },
  bankWireBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '.bank-wire-icon': {
      color: '#99BCDF',
    },
    '.bank-wire-text': {
      fontWeight: 400,
      color: '#6687A2'
    },
    '.bank-wire-arrow-icon': {
      color: '#99BCDF'
    },

    '&:hover': {
      '.bank-wire-icon': {
        color: '#003B6F',
      },
      '.bank-wire-text': {
        fontWeight: 600,
        color: '#003B6F'
      },
      '.bank-wire-arrow-icon': {
        color: '#003B6F',
      },
    }
  },
  optionIcon: {
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paymentButtonWrapper: {
    display: 'flex',
    marginTop: '30px',
    width: '100%',
    '@media (min-width: 768px)': {
      justifyContent: 'center',
    },
    '@media (min-width: 1366px)': {
      justifyContent: 'end',
    }
  },
  paymentButtonBox: {
    width: '100%',
    '@media (min-width: 768px)': {
      width: '485px',
    },
    '@media (min-width: 1366px)': {
      width: '271px',
    }
  },
  bankWireIcon: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '25px',
    height: '25px',
    cursor: 'pointer'
  },
  bankWireTitle: {
    padding: '15px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      padding: '15px 20px',
    }
  },
  bankWireCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '2px solid #99BCDF'
  },
  creditCardWrapper: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '2px solid #99BCDF',
    padding: '15px',
    '@media (min-width: 768px)': {
      padding: '20px',
    }
  },
  creditCardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px'
  },
  customCardMedia: {
    justifyContent: 'center',
    display: 'flex',
    height: '60px',
    width: '95px',
    objectFit: 'contain',
    '@media (min-width: 1366px)': {
      width: '85px',
    },
  },
  boxTextQuantityDisbled: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
    width: '55px',
    background: '#F0F2F5',
    borderRadius: '20px'
  },
  backDrop: {
    position: 'fixed',
    bottom: 0,
    top: -71,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  }
};
