export const rmSpace = (value: string): string | number => value.replace(/\s/g, '')

export const isContainChar = (value: string) => {
  if (!value) return false
  const valueTrimed: number = rmSpace(value) as number
  return Number.isNaN(valueTrimed)
}

export const isAmex = (ccNumber: string) => /^37|^34/.test(ccNumber)

export const formatCreditCardNumber = (value: string) => {
  let ccNumber = ''
  let ccNumberFormat = ''
  if (!value) return { ccNumber, ccNumberFormat }

  const rmSpaceAfterSlice = rmSpace(value) as string

  ccNumber = rmSpaceAfterSlice.slice(0, 16)
  ccNumberFormat = ccNumber.match(/[0-9]{1,4}/g)?.join(' ') as string

  const isAmex = /^37|^34/.test(ccNumber)
  if (isAmex) {
    ccNumber = ccNumber.slice(0, 15)
    ccNumberFormat = [ccNumber.substring(0, 4), ccNumber.substring(4, 10), ccNumber.substring(10, 15)].join(' ').trim()
  }
  return { ccNumber, ccNumberFormat }
}

export const validateCreditNumber = (cardNumber: string) => {
  const regexCardNumber = /^[0-9]+$/
  return regexCardNumber.test(rmSpace(cardNumber) as string)
}

export const validateCVVFormat = (cardNumber: string, cvv: string) => !cvv || cvv.length <= 3 || (cvv.length <= 4 && isAmex(cardNumber))
