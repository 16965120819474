import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  container: {
    padding: '15px',
    gap: '15px',
    display: 'flex',
    position: 'relative',
    '@media (min-width: 768px)': {
      padding: '30px',
      margin: '0 auto'
    },
    '@media (min-width: 1366px)': {
      flexDirection: 'row',
      gap: '30px',
    }
  },
  orderDetailCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '306px',
    }
  },
  informationCard: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',
    position: 'relative',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '720px',
    }
  },
  informationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  },
  overlayDisable: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#E1E1E1',
    zIndex: 10,
    pointerEvents: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAddressHistoryTitle: {
    fontWeight: 600,
    '@media (max-width: 428px)': {
      width: '220px'
    }
  },
  backDrop: {
    position: 'absolute',
    bottom: -80,
    top: -71,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  },
  backDropShowNewAddress: {
    borderRadius: '0px 0px 12px 12px',
    position: 'absolute',
    bottom: 0,
    top: 70,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  },
  backDropShipToNameTop: {
    borderRadius: '12px 12px 0px 0px',
    position: 'absolute',
    bottom: 400,
    top: 0,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12,

    '@media (min-width: 431px)': {
      bottom: 310,
    },
  },
  backDropShipToNameBottom: {
    borderRadius: '0px 0px 12px 12px',
    position: 'absolute',
    bottom: 0,
    top: 120,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  }
};
