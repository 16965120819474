/* eslint-disable filenames/match-exported */
import { Box, Typography, Button, CardMedia, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { isBasicItemCode } from 'Stores/Products/ProductStore';
import { useRemoveFromCart } from 'Stores/Cart/CartStore';
import { guideProductCartState, guideState } from 'Stores/Guide/GuideStore';
import { CartInterface, cartState } from 'Stores/Cart/CartInterface';
import { ReactComponent as Plus } from '../../../../Assets/icons/plus.svg'
import { ReactComponent as Minus } from '../../../../Assets/icons/minus.svg'
import { ReactComponent as Close } from '../../../../Assets/icons/close.svg'
import './ProductsCart.scss';
import { styles } from './ProductsCart.style'


const ProductsCartDisplay = (props: any) => {
  const {
    index,
    imageUrl,
    itemName,
    itemPv,
    itemPrice,
    itemCode,
    itemQuantity,
    showBackDropDisplay,
  } = props

  const [cartItems, setCartItems] = useRecoilState(cartState)
  const [translate] = useTranslate();
  const currentLanguage = useRecoilValue(languageState);
  const guide = useRecoilValue(guideState);
  const guideProductCart = useRecoilValue(guideProductCartState);
  const basicItemCode = useRecoilValue(isBasicItemCode)

  const removeFromCart = useRemoveFromCart();

  const formatNumberWithCommas = (value: number) => {
    const data = new Intl.NumberFormat().format(value)
    return data
  };

  const isCanEditQty = () => (
    itemCode !== basicItemCode && itemCode !== '32120' && itemCode !== '35413'
  )

  const onClickAddToCart = (itemCode: string) => {
    const newData = cartItems.map((element: CartInterface) => {
      if (element.product.item_code === itemCode) {
        return {
          ...element,
          quantity: element.quantity + 1
        }
      }
      return element
    })
    setCartItems(newData)
  }

  const onClickRemoveToCart = (itemCode: string) => {
    const newData = cartItems.map((element: CartInterface) => {
      if (element.product.item_code === itemCode && element.quantity > 1) {
        return {
          ...element,
          quantity: element.quantity - 1
        }
      }
      return element
    })
    setCartItems(newData)
  }

  const onClickDeleteItem = (itemCode: string) => {
    removeFromCart(itemCode)
  }

  return (
    <Box
      sx={styles.boxDisplayProduct}
      style={!guide.isOrderDetailGuide ? { zIndex: 14, background: 'white' } : {}}
    >
      {showBackDropDisplay && !isCanEditQty() && (
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
          background: '#000000',
          opacity: '80%',
          zIndex: 12
        }} />
      )}
      {showBackDropDisplay && (isCanEditQty() && index === 0) && (
        <Box sx={{
          position: 'absolute',
          bottom: guideProductCart.stepPopup === 1 ? '60%' : 0,
          top: guideProductCart.stepPopup === 0 ? '30%' : 0,
          right: 0,
          left: 0,
          background: '#000000',
          opacity: '80%',
          zIndex: 12
        }} />
      )}
      {showBackDropDisplay && (isCanEditQty() && index === 0) && guideProductCart.stepPopup === 1 && (
        <Box sx={{
          position: 'absolute',
          bottom: 0,
          top: '90%',
          right: 0,
          left: 0,
          background: '#000000',
          opacity: '80%',
          zIndex: 12
        }} />
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }} className={isCanEditQty() ? 'borderHover' : ''}>
        <Box>
          <CardMedia component='img' image={imageUrl} sx={styles.customCardMedia} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{
            width: !isCanEditQty() ? '100%' : '80%',
            marginBottom: '5px'
          }}>
            <Typography variant='label5' color={'#003764'}>{currentLanguage === 'en' ? itemName.english : itemName.native}</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Stack>
              <Box>
                <Typography variant='label4' color={'#003B6F'}>
                  {itemPv === 0 ? '' : `${itemPv} ${translate('cart_pv')} ${itemPv === 0 ? '' : ' | '}`}
                </Typography>

                <Typography variant='label4' color={'#003B6F'}>
                  {formatNumberWithCommas(itemPrice)} {translate('currency_JPY')}
                </Typography>
              </Box>

              <Typography
                variant='label4'
                sx={{ color: '#33628C', fontWeight: 300 }}
              >
                {translate('urenew_code')}:  {itemCode}
              </Typography>
            </Stack>
            <Box>
              <ProductsCartQTY
                itemCode={itemCode}
                onClickAddToCart={onClickAddToCart}
                onClickRemoveToCart={onClickRemoveToCart}
                quantity={itemQuantity}
                isCanEditQty={isCanEditQty()}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {isCanEditQty() && <Box
        className={`boxRemoveItems ${showBackDropDisplay && guideProductCart.stepPopup === 0 ? 'active' : ''}`}
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          cursor: 'pointer'
        }}
        onClick={() => onClickDeleteItem(itemCode)}
      >
        <Close />
      </Box>}
    </Box>
  );
};

const ProductsCartQTY = (props: any) => {
  const {
    itemCode,
    quantity,
    isCanEditQty,
    onClickAddToCart,
    onClickRemoveToCart
  } = props

  return (
    <Box>
      {isCanEditQty && <Box sx={{ display: 'flex', background: '#E9F1FA', borderRadius: '20px' }}>
        <Button disabled={quantity === 1} onClick={() => onClickRemoveToCart(itemCode)} sx={styles.buttonMinusIcon}>
          {quantity > 1 && <Minus />}
        </Button>
        <Box sx={styles.boxTextQuantity}>
          <Typography variant='caption5' color='#153862' fontWeight={600}>
            {quantity}
          </Typography>
        </Box>
        <Button onClick={() => onClickAddToCart(itemCode)} sx={styles.buttonPlusIcon}>
          <Plus />
        </Button>
      </Box>}

      {!isCanEditQty && <Box sx={styles.boxTextQuantityDisbled}>
        <Typography variant='caption5' color='#8797AD' fontWeight={600}>
          {quantity}
        </Typography>
      </Box>}

    </Box >
  )
}

export default ProductsCartDisplay;
