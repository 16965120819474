/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react';
import { Box, Typography, ThemeProvider, Divider, Card } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isBasicItemCode } from 'Stores/Products/ProductStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import ProductsDisplayPayment from './Components/ProductsDisplayPayment';
import { detailOrderSummaryState } from '../../../Stores/OrderSummary/OrderSummaryStore';
import { styles } from './OrderSummary.style'


const ProductsCartPayment = (props: any) => {
  const [translate] = useTranslate();
  const [detail, setDetail] = useRecoilState(detailOrderSummaryState);
  const basicItemCode = useRecoilValue(isBasicItemCode)

  const additionalProducts = detail?.productsDisplay.filter((item: any) => item.item_code !== '32120' && item.item_code !== '35413' && item.item_code !== basicItemCode)

  return (
    <Box sx={styles.productCard}>
      <Typography variant='label2' fontWeight={700}>
        {translate('urenew_your_cart')}
      </Typography>

      <Divider sx={styles.dividerStyle} />

      <ProductsDisplayPayment
        product={detail?.productsDisplay}
        imageUrl={detail?.productsDisplay[0].image_url}
        itemName={detail?.productsDisplay[0].item_name}
        itemPv={detail?.productsDisplay[0].pv}
        itemPrice={detail?.productsDisplay[0].price}
        itemCode={detail?.productsDisplay[0].item_code}
        itemQuantity={detail?.productsDisplay[0].qty}
      />

      {additionalProducts.length !== 0 && <Box>
        <Divider sx={styles.dividerStyle} />

        <Box marginBottom='15px'>
          <Typography variant='caption4' fontWeight={600}>
            {translate('urenew_title_renew_set_additional')}
          </Typography>
        </Box>

        <Box>
          {additionalProducts.map((items: any, index: number) => (
            <Fragment key={`${index}-${items.item_code}`}>
              <ProductsDisplayPayment
                product={items}
                imageUrl={items.image_url}
                itemName={items.item_name}
                itemPv={items.pv}
                itemPrice={items.price}
                itemCode={items.item_code}
                itemQuantity={items.qty}
              />
              {additionalProducts.length - 1 > index && <Divider sx={styles.dividerStyle} />}
            </Fragment>
          ))}
        </Box>
      </Box>}
    </Box>
  );
};

export default ProductsCartPayment;
