export function checkCardType(cardNumber:string, full = true) {
  let card = ''
  if (full) {
    // Visa
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/
    if (visaRegex.test(cardNumber)) {
      card = 'visa'
    }
    // Mastercard
    const mastercardRegex = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
    if (mastercardRegex.test(cardNumber)) {
      card = 'mastercard'
    }
    return card
  } 
  // Visa
  const visaRegex = /^4/
  if (visaRegex.test(cardNumber)) {
    card = 'visa'
  }

  // Mastercard
  const mastercardRegex = /^5[1-5]/
  if (mastercardRegex.test(cardNumber)) {
    card = 'mastercard'
  }

  return card
    
}

export const maskedCreditCard = (jsonString: string) => {
  // Define a regular expression to match credit card numbers
  const creditCardRegex = /"creditCardNumber":\s*"(.*?)"(\s*[,}])/;

  // Replace the matched credit card number with the masked version
  const maskedJsonString = jsonString.replace(creditCardRegex, (match, cardNumber, afterNumber) => {
    const maskedNumber = '***'
    return `"creditCardNumber": "${maskedNumber}"${afterNumber}`;
  });

  return maskedJsonString;
}
