interface HumanName {
    firstName : string
    lastName : string
    fullName: string
    [key:string]:string
}

interface GetNativeNameProp {
    humanName:HumanName
    nameType:string
}


export function GetNativeName({ humanName, nameType }:GetNativeNameProp) {
  let fullName = '';
  let nativeName = '';

  const checkNative = /@/
  Object.keys(humanName).forEach((key) => {
    const value = humanName[key];

    if (checkNative.test(key)) {
      nativeName = value
    } else {

      nativeName = ''
      if (key === 'firstName' || key === 'lastName') {
        fullName += value
      } else if (key === 'fullName') {
        fullName = value
      }
    }

  });

  // if (nameType === 'fullName') {
  //     return fullName;
  // } else if (nameType === 'nativeName'){
  //     if (nativeName === '') {
  //         return fullName;
  //     } else {
  //         return nativeName;
  //     }
  // } else {
  //     // Since the previous conditions have covered 'fullName' and 'nativeName',
  //     // this block will be executed only if nameType is neither of them.
  //     return nativeName === '' ? fullName : nativeName;
  // }

  if (nameType === 'fullName') {
    return fullName;
  }
    
  if (nameType === 'nativeName') {
    if (nativeName === '') {
      return fullName;
    }
    return nativeName;
  }
     
  return nativeName === '' ? fullName : nativeName;

}