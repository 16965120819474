import { Stack } from '@mui/material'
import { Product } from 'Constants/Interface/Product';
import { cartState } from 'Stores/Cart/CartInterface';
import { productsState } from 'Stores/Products/ProductStore';
import { useRecoilValue } from 'recoil'
import ProductView from './Product';

function ProductListLayout() {

  const products = useRecoilValue(productsState);
  const cartItems = useRecoilValue(cartState);

  return <Stack width="100%">
    <Stack display="flex" flexDirection="row" justifyContent="center" gap="40px">{cartItems.length > 0 ? cartItems.map((item) => <ProductView item={item.product} qty={item.quantity} key={item.product.item_code} />) : products.renewal.map((item: Product) => <ProductView item={item} qty={0} key={item.item_code} />)}</Stack>
  </Stack>
}

export default ProductListLayout