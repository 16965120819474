import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#fe5d7c',
    backgroundColor: ' #ffe7eb',
    padding: '15px 20px',
    borderRadius: '10px',
    gap: '15px',
    
    '.hr-alert-error': {
      width: '100%',
      border: '1px dashed #FF6187',
      margin: '0 20px'
    },
    '.hr-alert-error:first-of-type': {
      display: 'none'
    }
  },

};
