import { atom } from 'recoil';
import { DetailOrderSummaryType, ErrorPaymentForm, PaymentChannel, PaymentFormType } from 'Constants/Interface/OrderSummary';

export const detailOrderSummaryState = atom<DetailOrderSummaryType>({
  key: 'detailOrderSummary',
  default: {
    detail_page: {
      fullname: '',
      house_number: '',
      street: '',
      city: '',
      prefecture: '',
      zip: '',
      phone: '',
      email: '',
      shipment_options: '',
      shipment_time: '',
      login_id: '',
      login_name: '',
      login_name_native: '',
      selectAddressHistory: '',
      shipToAddress: {},
      period: '',
      send_confirmation: false,
      items: ''
    },
    productsDisplay: [{ id: 0, isShowPv: null, pvOrigin: 0, pv_x_qty: { english: '', native: '', suffix: '', value: '' }, price_x_qty: { english: '', native: '', suffix: '', value: '' }, image_url: '', name: '', qty: 0, price: 0, pv: '', item_code: '', item_name: { english: '', native: '' } }],
    urenew: {
      formatContact: { display_address: '', email: '', name: '', phone: '' },
      formatSummary: {
        total_price: { suffix: '', value: '' },
        total_pv: { suffix: '', value: '' },
        delivery_fee: { suffix: '', value: '' },
        products_price: { suffix: '', value: '' }
      }
    }
  }
})

export const uuidOrderSummaryState = atom<string>({
  key: 'uuidOrderSummary',
  default: ''
})

export const paymentFormState = atom<PaymentFormType>({
  key: 'paymentForm',
  default: {
    name: '',
    card_number: '',
    exp_date: '',
    cvv: '',
  },
});

export const errorPaymentFormState = atom<ErrorPaymentForm>({
  key: 'errorPaymentForm',
  default: [],
});

export const paymentChannelState = atom<PaymentChannel>({
  key: 'paymentChannel',
  default: {
    channel: '',
  },
});

export const agreeTermConditionState = atom<boolean>({
  key: 'agreeTermCondition',
  default: false,
});

export const isLoadingFormatApiState = atom<boolean>({
  key: 'isLoadingFormatApi',
  default: false,
})

export const isLoadingState = atom<boolean>({
  key: 'isLoadingApi',
  default: false,
})

export const LoadingSubmitFormState = atom<boolean>({
  key: 'loadingSubmitForm',
  default: false,
});

export const cardTypeState = atom<string>({
  key: 'cardType',
  default: ''
})
