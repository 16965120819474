import { countriesState } from 'Stores/Countries/CountriesStore';
import { useRecoilValue } from 'recoil';
import { Box, Grid, Stack, Typography, Link } from '@mui/material';
interface LanguageItem {
  name: string;
  code: string;
  live: string;
}

function Ushop() {
  const countries = useRecoilValue(countriesState);

  const countryData = countries.data.ushop

  const domain = 'https://ushop.unicity.com'

  const generateLinkUrl = (url: string, language: string) => {
    if (url.includes('{{{language}}}')) {
      const langParam = language ? `?lang=${language}` : '';
      const replacedString = url
        .replace('{{{domain}}}', domain)
        .replace('{{{language}}}', langParam);

      return replacedString
    }

    const langParam = language ? `?lang=${language}` : '';
    const replacedString = url
      .replace('{{{domain}}}', domain)

    const result = `${replacedString}${langParam}`

    return result
  }

  return (
    <Grid container spacing={{ xs: 2, md: 4, lg: 6 }}>
      {countryData.map((item, index) => (
        <Grid key={`ushop${index}`} item xs={12} sm={6} lg={4}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px'
          }}>
            <Box sx={{
              img: {
                width: '25px',
                borderRadius: '3px'
              }
            }}>
              <img src={item.flagUrl} alt={`${item.short}${index}`} />
            </Box>

            <Stack>
              <Typography variant='caption4' fontWeight={400}>
                {item.roman_name}
              </Typography>

              <Box sx={{
                display: 'flex'
              }}>
                {item.config.language.items.map((lang: LanguageItem, index: number) => {
                  const linkUrl = generateLinkUrl(item.live, lang.code.toLowerCase());
                  return (
                    <Box
                      key={`lang${index}`}
                      sx={{
                        display: 'flex'
                      }}>
                      <Typography variant='caption5' fontWeight={400}>
                        <Link
                          href={linkUrl}
                          target='_blank'
                          rel="noopener"
                          sx={{
                            textDecoration: 'none',
                            color: 'rgb(112, 119, 131)',
                            cursor: 'pointer'
                          }}>
                          {lang.name}
                        </Link>

                      </Typography>
                      {item.config.language.items.length - 1 === index ? '' : <Typography
                        variant='caption5'
                        sx={{
                          color: 'rgb(112, 119, 131)',
                          padding: '0px 10px',
                          fontWeight: 400
                        }}>
                        |
                      </Typography>}
                    </Box>
                  )
                })}
              </Box>
            </Stack>

          </Box>

        </Grid>
      ))}
    </Grid>
  )
}

export default Ushop
