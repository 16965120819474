import { atom } from 'recoil';
import { Product } from '../../Constants/Interface/Product';
import { Products } from '../../Constants/Interface/Products';

// Create an atom to hold your products
export const productsState = atom<Products>({
  key: 'productsState',
  default: {
    easyshipSet: [],
    isTagNative: '',
    items: [],
    marketingItems: [],
    renewal: [],
    starter_kit: [],
    totalItems: 0,
    upgradeKit: [],
    warehouseSelected: '',
    warehouses: [],
  },
});

const defaultProductValue = {
  id: 0,
  warehouse: '',
  is_enable: false,
  is_liquefy: false,
  is_service_item: false,
  enable_allowbackorder: false,
  country_code: '',
  item_code: '',
  product_sorting: 0,
  item_name: {
    english: '',
    native: '',
  },
  more_info_usage: '',
  item_info_list: {
    english: [],
    native: [],
  },
  item_info_link_target: '',
  item_info_link_url: {
    english: '',
    native: '',
  },
  item_info_path: {
    english: '',
    native: '',
  },
  price: 0,
  price_original: null,
  discount_amount: null,
  pv: 0,
  qty_sellable: 0,
  qty: 0,
  image_url: '',
  remarks: '',
  categories: [],
  tags: [],
  status: '',
  system_tags: [],
  tooltip: {
    enable: false,
    content: {
      english: '',
      native: '',
    },
    dialog: {
      style: {
        backgroundColor: '',
      },
    },
  },
  partsCount: {
    unit: {
      english: '',
      native: '',
    },
    value: 0,
  },
  vat: {
    rate: 0,
  },
};

export const productState = atom<Product>({
  key: 'productState',
  default: defaultProductValue,
});

export const isProductApiLoadingState = atom<boolean>({
  key: 'isProductApiLoadingState',
  default: false,
});

export const isBasicItemCode = atom<String>({
  key: 'isBasicItemCode',
  default: '36131',
});
