import { useRecoilValue } from 'recoil';
import { detailOrderSummaryState } from 'Stores/OrderSummary/OrderSummaryStore'
import { isBasicItemCode } from 'Stores/Products/ProductStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { styles } from './OrderSummary.style'

const TotalPriceBox = () => {
  const detail = useRecoilValue(detailOrderSummaryState);
  const basicItemCode = useRecoilValue(isBasicItemCode)
  const isBasicPack = detail.productsDisplay[0]?.item_code === basicItemCode

  const [translate] = useTranslate()

  return (
    <Box sx={styles.totalCard}>
      {
        !isBasicPack && <Stack gap='15px'>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant='caption5' color='#fff'>
              {translate('total_pv')}
            </Typography>
            <Typography variant='caption4' color={'#fff'}>
              {detail?.urenew?.formatSummary?.total_pv?.value}
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant='caption5' color='#fff'>
              {translate('urenew_items_subtotal')} {`(${translate('currency_JPY')})`}
            </Typography>
            <Typography variant='caption4' color={'#fff'}>
              {detail?.urenew?.formatSummary?.products_price?.value}
            </Typography>
          </Box>

          <Divider sx={{ background: '#9EA9B9' }} />

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant='caption5' color='#fff'>
              {translate('urenew_shipping_subtotal')} {`(${translate('currency_JPY')})`}
            </Typography>

            <Typography variant='caption4' color={'#fff'}>
              {detail?.urenew?.formatSummary?.delivery_fee?.value}
            </Typography>

          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant='label2' fontWeight={600} color='#fff'>
              {translate('urenew_total_price')} {`(${translate('currency_JPY')})`}
            </Typography>
            <Typography variant='label2' fontWeight={600} color={'#fff'}>
              {detail?.urenew?.formatSummary?.total_price?.value}
            </Typography>
          </Box>
        </Stack>
      }

      {isBasicPack && <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant='caption5' color='#fff'>
          {translate('urenew_total_price')} {`(${translate('currency_JPY')})`}
        </Typography>
        <Typography variant='label2' fontWeight={600} color={'#fff'}>
          {detail?.urenew?.formatSummary?.total_price?.value}
        </Typography>
      </Box>}
    </Box>
  )
}
export default TotalPriceBox