import { useEffect, useState, ReactNode } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDictionaries, useTranslate } from 'Stores/Dictionaries/useDictionaries';
import SkeletonPaymentSuccess from './SkeletonPaymentSuccess';
import { ReactComponent as Banner1 } from '../../../Assets/banners/banner-success1.svg';
import { ReactComponent as Banner2 } from '../../../Assets/banners/banner-success2.svg';
import { ReactComponent as Banner3 } from '../../../Assets/banners/banner-success3.svg';
import { ReactComponent as Banner4 } from '../../../Assets/banners/banner-success4.svg';
import { ReactComponent as FacebookIcon } from '../../../Assets/icons/fb-icon.svg';
import { ReactComponent as MailIcon } from '../../../Assets/icons/mail-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../Assets/icons/phone-icon.svg';

const PaymentSuccess: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [banner, setBanner] = useState<ReactNode | null>(null);
  const [translate] = useTranslate();
  const { id } = useParams();
  const paymentNumber = id?.split('-')[1]

  useEffect(() => {
    randomImg();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const listBanner = [
    {
      icon: <Banner1 width='100%' height='100%' />,
    },
    {
      icon: <Banner2 width='100%' height='100%' />,
    },
    {
      icon: <Banner3 width='100%' height='100%' />,
    },
    {
      icon: <Banner4 width='100%' height='100%' />,
    },
  ];

  const randomImg = (): void => {
    const num: number = Math.floor(Math.random() * listBanner.length);
    setBanner(listBanner[num].icon);
  };

  return (
    <Box>
      {loading && <SkeletonPaymentSuccess />}

      {!loading && (
        <Box sx={Wrapper}>
          <Box sx={WrapperContent}>
            <Box sx={Card1}>
              <Stack className='card1-padding'>
                <Box sx={{ width: '100%', maxWidth: '344px' }}>
                  {banner}
                </Box>

                <Stack
                  sx={{
                    gap: '15px',
                    alignItems: 'center',
                    '@media (min-width: 600px)': {
                      gap: '10px',
                    },
                  }}
                >
                  <Typography variant='label2'>
                    {translate('urenew_payment_success')}
                  </Typography>
                </Stack>

                <Box
                  sx={{
                    borderRadius: '8px',
                    background: '#F0F2F5',
                    width: '100%',
                    textAlign: 'center',
                    padding: '15px 0',
                  }}
                >
                  <Typography
                    variant='caption4'
                  >
                    {translate('order_number')}: {paymentNumber}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box sx={Card2}>
              <Stack className='card2-padding'>
                <Typography variant='label5' color={'#003B6F'}>
                  {translate('payment_response_description')}
                </Typography>

                <Box sx={IconLink}>
                  <PhoneIcon width='25px' height='25px' />

                  <Typography variant='caption'>
                    {translate('payment_phone_contact_jp')}
                  </Typography>
                </Box>

                <Box sx={IconLink}>
                  <MailIcon width='25px' height='25px' />

                  <Typography variant='caption'>
                    <Link
                      href={`mailto:${translate(
                        'payment_mail_contact_jp'
                      )}`}
                      underline='none'
                      sx={{ textDecoration: 'none' }}
                    >
                      {translate(
                        'payment_mail_contact_jp'
                      )}
                    </Link>
                  </Typography>
                </Box>

                <Box sx={IconLink}>
                  <FacebookIcon width='25px' height='25px' />

                  <Typography variant='caption'>
                    <Link
                      href={translate(
                        'link_fb_contact_jp'
                      ).toString()}
                      target='_blank'
                      underline='none'
                      sx={{ textDecoration: 'none' }}
                    >
                      {translate('payment_fb_contact_jp')}
                    </Link>
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const Wrapper = {
  width: '100%',
};

const WrapperContent = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 15px',
  gap: '15px',

  '@media (min-width: 600px) and (max-width: 1024px)': {
    gap: '20px',
    alignItems: 'center',
    padding: '30px 15px',
  },

  '@media (min-width: 1025px)': {
    gap: '30px',
    padding: '30px 15px',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

const Card1 = {
  width: '100%',
  maxWidth: '595px',
  borderRadius: '12px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 5px 0px rgba(14, 35, 56, 0.20)',
  height: '100%',

  '.card1-padding': {
    padding: '20px',
    gap: '10px',
    alignItems: 'center',
  },

  '@media (min-width: 600px) and (max-width: 1024px)': {
    '.card1-padding': {
      padding: '40px 47px',
    },
  },

  '@media (min-width: 1025px)': {
    maxWidth: '646px',

    '.card1-padding': {
      gap: '20px',
      padding: '40px 73px',
    },
  },
};

const Card2 = {
  width: '100%',
  maxWidth: '595px',
  borderRadius: '12px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 5px 0px rgba(14, 35, 56, 0.20)',
  height: '100%',

  '.card2-padding': {
    padding: '20px',
    gap: '20px',
  },

  '@media (min-width: 600px) and (max-width: 1024px)': {
    '.card2-padding': {
      padding: '40px 30px',
      gap: '30px',
    },
  },

  '@media (min-width: 1025px)': {
    maxWidth: '440px',

    '.card2-padding': {
      padding: '40px 30px',
      gap: '30px',
    },
  },
};

const IconLink = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  '@media (min-width: 600px)': {
    gap: '20px',
  },
};

export default PaymentSuccess;
