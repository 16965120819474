import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { userState } from 'Stores/User/UserStore';
import {
  isLoadingOrderDetailState, orderDetailFormState, selectAddressState
} from './OrderDetailStore';
import { addressHistoryState } from '../AddressHistory/AddressHistoryStore';
import { countryState } from '../Countries/CountriesStore';

function useOrderDetail() {
  const [addressHistory, setAddressHistory] = useRecoilState(addressHistoryState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingOrderDetailState)
  const [selectAddress, setSelectAddress] = useRecoilState(selectAddressState);
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState)

  const country = useRecoilValue(countryState);
  const token = sessionStorage.getItem('tokenHydra')!;
  const baId = sessionStorage.getItem('baId')!;
  const user = useRecoilValue(userState);

  useEffect(() => {
    getAddressHistory();
  }, []);

  const getAddressHistory = async () => {
    setIsLoading(true)
    try {
      const response = await RenewalAPI.getAddressHistory(baId, token, country.short);
      if (response.data) {
        setAddressHistory({
          ...response.data,
        });

        let isMainAddress = response.data.mainAddress
        if (response.data.mainAddress === null) {
          isMainAddress = response.data.items[0] || {}
        }

        setSelectAddress(isMainAddress);
        setAddressForm({
          ...addressForm,
          selectAddressHistory: 'addressHistory',
          fullname: isMainAddress.ushopShipToName,
          shipToAddress: isMainAddress.shipToAddress,
        })

        if (addressForm.email === '' || addressForm.phone === '') {
          const phone = user?.mobilePhone || user?.homePhone
          let formattedPhone = ''
          if (phone) {
            if (phone.length === 10) {
              formattedPhone = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
            } else if (phone.length === 11) {
              formattedPhone = phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
            } else {
              formattedPhone = phone;
            }
          }

          setAddressForm({
            ...addressForm,
            selectAddressHistory: 'addressHistory',
            email: user?.email,
            phone: formattedPhone,
          })
        }

        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    } catch (error) {
      setIsLoading(false)
      setAddressHistory({
        ...addressHistory,
      });
      console.warn({ error });
    }
  };
}
export default useOrderDetail;
