import { Stack, ThemeProvider } from '@mui/material';
import { MainTheme } from 'Cores/Layouts/MainTheme';
import AppRouters from 'Routers/AppRouters';
import { countryState } from 'Stores/Countries/CountriesStore';
import useCountries from 'Stores/Countries/useCountries';
import useProducts from 'Stores/Products/useProducts';
import { useRecoilValue } from 'recoil';

function Init() {
  useCountries();
  useProducts()
  const country = useRecoilValue(countryState);

  return (
    <ThemeProvider theme={MainTheme}>
      <Stack className='custom-body'>
        {country.country ? <AppRouters /> : ''}
      </Stack>
    </ThemeProvider>
  )
}

export default Init;
