import {
  Box, Divider, Stack, Typography,
} from '@mui/material';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { paymentChannelState } from 'Stores/OrderSummary/OrderSummaryStore';
import { useRecoilState } from 'recoil';
import { styles } from '../OrderSummary.style'
import { ReactComponent as SVGArrow } from '../../../../Assets/icons/arrow-left.svg'

const BankWirePayment = () => {
  const [translate] = useTranslate()
  const [, setPaymentType] = useRecoilState(paymentChannelState)

  const bankList = [
    { name: `${translate('mizuho_bank_aoyama_branch')}:`, value: '2583367' },
    { name: `${translate('sumitomo_mitsui_banking_corporation_marunouchi_branch')}:`, value: '1166789' },
    { name: `${translate('bank_of_tokyo_mitsubishi_ufj_aoyama_branch')}:`, value: '4592289' },
    { name: `${translate('yucho_bank_transfer_gaienmae_post_office')}:`, value: '00100 9 126835' },
  ];

  const handlePaymentSelected = (value: string) => {
    setPaymentType({ channel: value })
  }
  return (
    <Box sx={styles.bankWireCard}>
      <Box sx={styles.bankWireTitle}>
        <Typography variant='caption4' fontWeight={600} color='#153862'>
          {translate('payment_options')}:
        </Typography>
        <Box
          onClick={() => handlePaymentSelected('')}
          sx={styles.bankWireIcon}>
          <SVGArrow />
        </Box>
      </Box>
      <Divider sx={{ borderStyle: 'dashed', borderColor: '#99BCDF' }} />
      <Stack sx={{ padding: '15px', textAlign: 'center', gap: '15px' }}>
        {bankList.map((item, index) => (
          <Stack key={index} gap='5px'>
            <Typography variant='caption5' color={'#707783'}>
              {item.name}
            </Typography>
            <Typography variant='caption5' fontWeight={600}>
              {item.value}
            </Typography>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed', borderColor: '#99BCDF' }} />

      <Box sx={{ padding: '15px', textAlign: 'center' }}>
        <Typography variant='caption6'>
          {translate('bank_remark')}
        </Typography>
      </Box>
    </Box>
  );
}

export default BankWirePayment;
