import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  card: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '20px',
    background: '#fff',
    position: 'relative',

    '@media (min-width: 768px)': {
      width: '500px',
    },
    '@media (min-width: 1366px)': {
      width: '306px',
    }
  },
  buttonMinusIcon: {
    minWidth: '25px',
    width: '25px',
    padding: '6px 6px 6px 11px',
    borderRadius: '20px 0px 0px 20px',
    color: '#99BCDF'
  },
  buttonPlusIcon: {
    minWidth: '25px',
    width: '25px',
    padding: '6px 11px 6px 6px',
    borderRadius: '0px 20px 20px 0px',
    color: '#99BCDF'
  },
  boxTextQuantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
    width: '18px'
  },
  boxTextQuantityDisbled: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
    width: '55px',
    background: '#F0F2F5',
    borderRadius: '20px'
  },
  productAddMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productAddMoreButton: {
    '&:hover': {
      background: '#5A8FC3',
    },
    background: '#5A8FC3',
    width: '100%',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '5px',
    borderRadius: '7px',
    '&.MuiButtonBase-root:disabled': {
      background: '#CCDEEF',
    }
  },
  dividerStyle: {
    marginTop: '20px',
    marginBottom: '20px',
    background: '#E9F0F7'
  },
  boxDisplayProduct: {
    position: 'relative',
    '.boxRemoveItems': {
      display: 'block',
    },
    '.borderHover': {
      padding: '4px 2px',
    },

    '@media (min-width: 1200px)': {
      '.boxRemoveItems': {
        display: 'none',

        '&.active': {
          display: 'block !important',
        }
      },
      '&:hover': {
        '.borderHover': {
          border: '1px solid #99BCDF',
          borderRadius: '8px',
        },
        '.boxRemoveItems': {
          display: 'block !important',
        }
      },
    },
  },
  customCardMedia: {
    justifyContent: 'center',
    display: 'flex',
    height: '60px',
    width: '95px',
    objectFit: 'contain',
    '@media (min-width: 1366px)': {
      width: '85px',
    },
  },
  backDrop: {
    borderRadius: '12px',
    position: 'absolute',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    background: '#000000',
    opacity: '80%',
    zIndex: 12
  }
};
