import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  modalWrapper: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        borderRadius: '0px',
        width: '100%',

        '@media (min-width: 601px)': {
          borderRadius: '8px',
          height: '650px',
          maxWidth: '700px',
        },

        '@media (min-width: 1200px)': {
          maxWidth: '944px',
        },
      },

      '.MuiDialog-paper': {
        '@media (max-width: 600px)': {
          margin: '0px',
          maxHeight: '100%',
          height: '100%',
        },
      }
    }
  },
  tabListBox: {
    '.MuiTab-root': {
      fontFamily: 'Prompt',
      textTransform: 'none',
      fontWeight: 300,
      color: 'rgb(51, 98, 140) !important',
      fontSize: '14px',

      '@media (min-width: 768px)': {
        fontSize: '18px',
      }
    },
    '.MuiTabs-flexContainer': {
      justifyContent: 'space-between',

      '@media (min-width: 600px)': {
        gap: '60px',
        justifyContent: 'start',
      }

    },
    '.MuiTabs-indicator': {
      backgroundColor: 'rgb(255, 191, 58)',
      height: '3px',
      borderRadius: '10px'
    },
    '.Mui-selected': {
      color: 'rgb(7, 159, 244) !important',
      fontWeight: 700,
      fontFamily: 'Prompt',
    },
  },
  tabPanelWrapper: {
    overflow: 'auto',
    height: 'calc(100vh - 160px)',

    '@media (min-width: 600px)': {
      height: '480px',
    },

    '.MuiTabPanel-root': {
      padding: '20px 30px',

      '@media (min-width: 1366px)': {
        padding: '40px 40px',
      }
    }
  },
  contentStack: {
    paddingTop: '30px',
    position: 'relative',
    height: '100%',

    '@media (min-width: 768px)': {
      paddingTop: '30px',
    }
  },
  closeIconStyle: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

};
