import { get } from 'lodash'

export function CountryFromPathname() {
  const pathnames = window.location.pathname.split('/')
  let country = get(pathnames, 1, '')
  if (/^\/feature/.test(window.location.pathname)) {
    country = get(pathnames, 4, '')
  } else if (/^\/epic/.test(window.location.pathname)) {
    country = get(pathnames, 6, '')
  }

  return country
}

export function BuildBasename(country: string) {
  const pathnames = window.location.pathname.split('/')

  if (/^\/feature/.test(window.location.pathname)) {
    pathnames[4] = country
  } else if (/^\/epic/.test(window.location.pathname)) {
    pathnames[6] = country
  } else {
    pathnames[1] = country
  }

  const basename = pathnames.join('/')
  let arrayBasename = basename.split('/')
  if (/^\/feature/.test(window.location.pathname)) {
    arrayBasename = pathnames.slice(0, 5)
  } else if (/^\/epic/.test(window.location.pathname)) {
    arrayBasename = pathnames.slice(0, 7)
  } else {
    arrayBasename = pathnames.slice(0, 2)
  }
    
  const newPathName = arrayBasename.join('/')
  // newPathName = `${newPathName}${window.location.search}`
  return newPathName
}
