/* eslint-disable filenames/match-exported */
import { Box, Typography, CardMedia } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { isBasicItemCode } from 'Stores/Products/ProductStore';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { Stack } from '@mui/system';
import { styles } from '../OrderSummary.style';

const ProductsCartDisplay = (props: any) => {
  const {
    imageUrl,
    itemName,
    itemPv,
    itemPrice,
    itemCode,
    itemQuantity,
  } = props

  const [translate] = useTranslate();
  const currentLanguage = useRecoilValue(languageState);
  const basicItemCode = useRecoilValue(isBasicItemCode)

  const formatNumberWithCommas = (value: any) => {
    const data = new Intl.NumberFormat().format(value)
    return data
  };

  const isCanEditQty = () => (
    itemCode !== basicItemCode && itemCode !== '32120' && itemCode !== '35413'
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      <Box>
        <CardMedia component='img' image={imageUrl} sx={styles.customCardMedia} />
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', marginBottom: '5px' }}>
          <Typography variant='label5' color={'#003764'}>{currentLanguage === 'en' ? itemName.english : itemName.native}</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Stack>
            <Box>
              <Typography variant='label4' color={'#003B6F'}>
                {itemPv === 0 ? '' : `${itemPv} ${translate('cart_pv')} ${itemPv === 0 ? '' : ' | '}`}
              </Typography>

              <Typography variant='label4' color={'#003B6F'}>
                {formatNumberWithCommas(itemPrice)} {translate('currency_JPY')}
              </Typography>
            </Box>

            <Typography
              variant='label4'
              sx={{ color: '#33628C', fontWeight: 300 }}
            >
              {translate('urenew_code')}:  {itemCode}
            </Typography>
          </Stack>
          <Box>
            <ProductsCartQTY
              itemCode={itemCode}
              quantity={itemQuantity}
              isCanEditQty={isCanEditQty()}
            />
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

const ProductsCartQTY = (props: any) => {
  const {
    quantity,
  } = props

  return (
    <Box sx={styles.boxTextQuantityDisbled}>
      <Typography variant='caption5' color='#8797AD' fontWeight={600}>
        {quantity}
      </Typography>
    </Box>
  )
}

export default ProductsCartDisplay;
