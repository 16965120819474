import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CheckBoxIcon from '../../../Assets/checkBox.svg';
import CheckedCheckBoxIcon from '../../../Assets/CheckedCheckBox.svg';

type Props = { 
  label?: string; 
  checked?: boolean; 
  onClick?: () => void;
};

function CustomCheckBox({ label, checked, onClick }: Props) {
  return (
    <FormControlLabel
      sx={{ color: '#003B6F' }}
      control={(
        <Checkbox
          icon={<img src={CheckBoxIcon} />}
          checkedIcon={<img src={CheckedCheckBoxIcon} />}
          checked={checked}
          onClick={onClick}
          sx={{
            color: '#003B6F',
            '&.Mui-checked': {
              color: '#6FCF97',
            },
            '& .MuiSvgIcon-root': { fontSize: 25, borderRadius: '50%' },
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
      )}
      label={(
        <Typography fontSize={{ xs: '14px', md: '14px' }} fontWeight={300}>
          {label}
        </Typography>
      )}
    />
  );
}

CustomCheckBox.defaultProps = {
  label: '', 
  checked: false,
  onClick: () => {},
};

export default CustomCheckBox;