/* eslint-disable react/button-has-type */
import React, { Fragment, useRef, useState } from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { isLoadingValidateOrderDetailState } from 'Stores/OrderDetail/OrderDetailStore';
import { cartState } from 'Stores/Cart/CartInterface';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { useAddToCart } from 'Stores/Cart/CartStore';
import PopupProductCartGuide from 'Components/Elements/UrenewGuide/PopupProductCartGuide';
import mobiscroll from '@mobiscroll/react';
import { Product, ProductListSelectInterface, ProductOnCart } from 'Constants/Interface/Product';
import { isBasicItemCode, productsState } from '../../../../Stores/Products/ProductStore';
import './ProductsCart.scss';
import ProductsCartDisplay from './ProductsDisplay';
import { ReactComponent as Plus } from '../../../../Assets/icons/plus.svg'
import { styles } from './ProductsCart.style'

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light'
}

const defaultEmpty = {
  product: {
    image_url: '',
    item_name: {
      english: '',
      native: ''
    },
    pv: 0,
    price: 0,
    item_code: ''
  },
  quantity: 0
}

const ProductsCart = (props: any) => {
  const {
    showBackDrop,
    isStep,
  } = props
  const myRef = useRef<any>(null)
  const [translate] = useTranslate();
  const currentLanguage = useRecoilValue(languageState);
  const products = useRecoilValue(productsState);
  const cartItems = useRecoilValue(cartState)
  const addToCart = useAddToCart();
  const loadingValidate = useRecoilValue(isLoadingValidateOrderDetailState)
  const basicItemCode = useRecoilValue(isBasicItemCode)

  const productListSelect = products.items.map((item: Product) => {
    // if have item in cart, not show in select
    const idArray = cartItems.map((element: ProductOnCart) => element.product.item_code);

    if (idArray.includes(item.item_code)) {
      return {}
    }

    const productTextDisplay = `${item.item_code} ${item.item_name[currentLanguage === 'en' ? 'english' : 'native']}`
    return {
      value: `${item.item_code}`,
      text: `${item.item_code}${item.item_name.english}${item.item_name.native}`,
      html: productTextDisplay
    }
  }).filter((x) => x.value !== undefined) as ProductListSelectInterface[];

  const renewalItem = cartItems.length > 0 ? cartItems[0] : defaultEmpty;

  const isCanAddNewProducts = () => (
    renewalItem.product.item_code === '32120' || renewalItem.product.item_code === '35413'
  )

  // additional products is not include 32120 and 0100
  const additionalProducts = cartItems.filter((item: ProductOnCart) => item.product.item_code !== '32120' && item.product.item_code !== '35413' && item.product.item_code !== basicItemCode)

  const showSelect = () => {
    if (myRef.current) {
      myRef.current.instance.show();
    }
  }

  const onSet = (_: any, inst: any) => {
    const selected = inst.getVal()
    const filterData = products.items.find((item: Product) => item.item_code === selected)

    if (filterData) {
      addToCart(filterData, 1)
    }

    if (myRef.current) {
      myRef.current.instance.clear();
    }
  }

  return (
    <Box sx={styles.card}>
      {showBackDrop && <Box sx={styles.backDrop} />}

      <Typography variant='label2' fontWeight={700}>
        {translate('urenew_your_cart')}
      </Typography>

      <Divider sx={styles.dividerStyle} />

      <ProductsCartDisplay
        product={renewalItem.product}
        imageUrl={renewalItem.product.image_url}
        itemName={renewalItem.product.item_name}
        itemPv={renewalItem.product.pv}
        itemPrice={renewalItem.product.price}
        itemCode={renewalItem.product.item_code}
        itemQuantity={renewalItem.quantity}
        showBackDropDisplay={showBackDrop}
      />

      {isCanAddNewProducts() && <Box>

        <Divider sx={styles.dividerStyle} />

        <Box marginBottom='15px'>
          <Typography variant='caption4' fontWeight={600}>
            {translate('urenew_title_renew_set_additional')}
          </Typography>
        </Box>
        <Box>
          {additionalProducts.map((items: ProductOnCart, index: number) => (
            <Fragment key={`${index}-${items.product.item_code}`}>
              <ProductsCartDisplay
                index={index}
                product={items.product}
                imageUrl={items.product.image_url}
                itemName={items.product.item_name}
                itemPv={items.product.pv}
                itemPrice={items.product.price}
                itemCode={items.product.item_code}
                itemQuantity={items.quantity}
                showBackDropDisplay={showBackDrop}
              />

              <Divider sx={styles.dividerStyle} />

            </Fragment>
          ))}
          {showBackDrop && <PopupProductCartGuide />}
        </Box>

        <mobiscroll.Select
          ref={myRef}
          filter
          showInput={false}
          data={productListSelect}
          display={'center'}
          touchUi={false}
          buttons={[]}
          rows={7}
          height={35}
          maxWidth={450}
          focusOnClose={false}
          onSet={onSet}
          showOverlay={true}
        />

        <Box sx={styles.productAddMore}>
          <Button
            disabled={loadingValidate}
            sx={styles.productAddMoreButton}
            onClick={showSelect}
            style={{ zIndex: (isStep === 2 ? 14 : 10) }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
              <Plus />
            </Box>
            <Typography variant='caption5' color='#fff'>
              {translate('urenew_add_more_product')}
            </Typography>
          </Button>
        </Box>
      </Box>}
    </Box>
  );
};

export default ProductsCart;
