import styled from '@emotion/styled';
import React from 'react';
import { ReactComponent as LoaderIcon } from '../../../Assets/LoaderV2.svg';

interface SpinnerProps {
    // eslint-disable-next-line react/require-default-props
    color?: string; // Make 'color' prop optional
}

export const SpinnerV2: React.FC<SpinnerProps> = ({ color }) => (
  <SpinnerIcon color={color} />
);

const SpinnerIcon = styled(LoaderIcon)<{ color?: string }>((props) => ({
  animation: 'rotation 1s infinite linear',
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(359deg)'
    }
  },
  stroke: props.color || 'black',
}));