import { Stack, ThemeProvider } from '@mui/material';
import { useDictionaries } from 'Stores/Dictionaries/useDictionaries';
import { MainTheme } from './MainTheme';
import { styles } from './Layouts.style'

function Main({ children }: { children: any }) {
  useDictionaries()
  return (
    <ThemeProvider theme={MainTheme}>
      <Stack sx={styles.mainWrapper}>
        {children}
      </Stack>
    </ThemeProvider>
  )
}
export default Main;
