import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import axios from 'axios';
import { dictionariesState, isDictionariesApiLoadingState, languageState } from './DictionariesStore';
import { countryState } from '../Countries/CountriesStore';

export const useDictionaries = () => {
  const country = useRecoilValue(countryState);
  const [dictionaries, setDictionaries] = useRecoilState(dictionariesState);
  const [isLoading, setLoading] = useRecoilState(isDictionariesApiLoadingState);
  const currentLanguage = useRecoilValue(languageState);

  useEffect(() => {
    if (Object.keys(dictionaries[currentLanguage]).length === 0 && isLoading === false) {
      setLoading(true);
    }
  }, [dictionaries, setDictionaries, currentLanguage]);

  useEffect(() => {
    if (isLoading === true) {
      const fetchDictionaries = async () => {
        try {
          const response = await axios.get(`https://member-calls2.unicity.com/hot/dictionary/v2/publish/${country.short}/Ushoplink`);
          setDictionaries(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      fetchDictionaries();
    }
  }, [isLoading, setLoading, setDictionaries, country.short]);
};

export const useTranslate = () => {
  const dictionaries = useRecoilValue(dictionariesState);
  const currentLanguage = useRecoilValue(languageState);

  const translate = (key: string) => dictionaries[currentLanguage.toLowerCase()][key] || '';

  return [translate]
};
