import { createTheme } from '@mui/system';

const theme = createTheme();

export const styles = {
  root: {
    background: '#03274C',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    '& .wrapper': {
      maxWidth: '1366px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      '@media (min-width: 1366px)': {
        justifyContent: 'space-between',
      },

      '& .copyright': {
        paddingLeft: '20px',
        display: 'none',
        width: '100%',

        '@media (min-width: 1366px)': {
          display: 'block',
          paddingLeft: '40px',
        }
      },

      '& .language-control': {
        paddingRight: '20px',
        paddingLeft: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',

        '@media (min-width: 1366px)': {
          paddingLeft: '0px',
          paddingRight: '40px',
          gap: '20px',
          justifyContent: 'end',
        }
      }
    }
  },
  countryButton: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#021F36',
    width: '133px',
    borderRadius: '7px',
    cursor: 'pointer',
    gap: '10px',

    '&:hover': {
      background: '#021F36',
    },

    img: {
      borderRadius: '3px'
    }
  },
  languageSelector: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#021F36',
    width: '187px',
    borderRadius: '7px',
    gap: '15px',

    '& .language-selector-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .select-language': {
        cursor: 'pointer',
        textTransform: 'uppercase',
        color: '#9EA9B9',

        '&:hover, &.active': {
          color: '#ffffff',
        }
      },

      '& .select-language-separator': {
        color: '#9EA9B9',
        padding: '0px 10px'
      }
    },

    '@media (min-width: 1366px)': {
      width: '197px',
    }
  }
};