import { Stack, ThemeProvider } from '@mui/material';
import { useUser } from 'Stores/User/useUser';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { MainTheme } from 'Cores/Layouts/MainTheme';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productsState } from 'Stores/Products/ProductStore';
import RenewalProducts from 'Components/Elements/LandingPageElements/RenewalProducts';
import SpinnerLoading from 'Components/Elements/Loading/SpinnerLoading';
import { isUserApiLoadingState } from 'Stores/User/UserStore';

function LandingPage() {
  const params = useParams();

  useUser({ hash: get(params, 'userHashed', '') });
  const products = useRecoilValue(productsState)
  const isLoading = useRecoilValue(isUserApiLoadingState);

  return (
    <Stack>
      {products.renewal.length > 0 && !isLoading ? <RenewalProducts /> : <SpinnerLoading />}
    </Stack>
  );
}

export default LandingPage;