import { Box, Skeleton, Stack } from '@mui/material'
import { styles } from './OrderSummary.style'

export default function OrderSummarySkeleton() {
  return (
    <Stack sx={styles.container}>
      <Stack gap='15px'>
        <Box sx={{
          height: '193px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '346px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <Box sx={{
          height: '229px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '346px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>
      </Stack>

      <Stack gap='15px'>
        <Box sx={{
          height: '209px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '636px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <Box sx={{
          height: '226px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '636px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
