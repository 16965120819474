import React, { useState } from 'react'
import mobiscroll from '@mobiscroll/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LoadingSubmitFormState, detailOrderSummaryState, errorPaymentFormState, paymentChannelState, paymentFormState } from 'Stores/OrderSummary/OrderSummaryStore';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { ServerEnv } from 'Cores/Countries/Environment/ServerEnv';
import { toCurl } from 'Utils/ToCurl';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { RenewalAPI } from 'Services/RenewalAPIs';
import styled from '@emotion/styled';
import CardValidate from 'Utils/CardValidate';
import SuperAlertDictV2 from 'Components/fragment/SuperAlertDictV2';
import { userState } from 'Stores/User/UserStore';
import CustomButton from '../CustomButton/CustomButton';
import BankWirePayment from './Components/BankWirePayment';
import CreditCardPayment from './Components/CreditCardPayment';
import { styles } from './OrderSummary.style'
import { ReactComponent as SVGCreditCard } from '../../../Assets/icons/jp-creditcard.svg'
import { ReactComponent as SVGBankWire } from '../../../Assets/icons/bankwire.svg'
import { ReactComponent as SVGArrow } from '../../../Assets/icons/jp-arrow.svg'

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light',
};

const PaymentBox = (props: any) => {
  const [paymentType, setPaymentType] = useRecoilState(paymentChannelState)
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessages, setErrorMessages] = useRecoilState(errorPaymentFormState)
  const detail = useRecoilValue(detailOrderSummaryState);
  const { userHashed } = useParams()
  const navigate = useNavigate();
  const [translate] = useTranslate()
  const uuid = searchParams.get('uuid')
  const [loadingSubmitForm, setLoadingSubmitForm] = useRecoilState(LoadingSubmitFormState)
  const [paymentForm, setPaymentForm] = useRecoilState(paymentFormState)

  const isCreditCard = () => paymentType.channel === 'creditcard'
  const isBankWire = () => paymentType.channel === 'bank_wire'
  const isOptionBox = () => paymentType.channel === ''

  const token = sessionStorage.getItem('tokenHydra')!;
  const baId = sessionStorage.getItem('baId')!;

  const onClickBack = () => {
    setPaymentType({ channel: '' })
    setErrorMessages([])
    navigate(`/${userHashed}/orderDetail?uuid=${uuid}`, { replace: true });
  }

  const handlePaymentSelected = (value: string) => {
    const selectValue = { channel: value }
    if (paymentType.channel === value) {
      setPaymentType({ channel: '' })
    } else {
      setPaymentType(selectValue)
    }
    setErrorMessages([])
  }

  const validateCardNumber = () => {
    const partsOfexpDate = paymentForm.exp_date.split("/")
    const cardValidatedCheck = CardValidate({
      countryCode: "JP",
      creditCard: {
        creditCardNumber: paymentForm.card_number,
        payer: paymentForm.name,
        creditCardExpires: `${partsOfexpDate[1]}-${partsOfexpDate[0]}`,
        creditCardSecurityCode: paymentForm.cvv
      }
    })
    return cardValidatedCheck
  }

  const submitForm = async () => {
    setLoadingSubmitForm(true)
    setErrorMessages([])
    let tempExpDate = paymentForm.exp_date.toString().split('/')
    tempExpDate = `${tempExpDate[1]}-${tempExpDate[0]}`
    if (isCreditCard()) {
      const cardValidate = validateCardNumber()
      if (cardValidate.success === false) {
        setErrorMessages(cardValidate.error_messages || [])
        setLoadingSubmitForm(false)
        return
      }
      const validateCardBody = {
        "country_code": "JPN",
        "platform": "ushop",
        "ba_id": baId,
        "credit_card": paymentForm.card_number
      }

      const response = await RenewalAPI.validateCreditCardApi(validateCardBody)
      if (!response.data.allowAccess) {
        setErrorMessages(translate('wp_err_not_accept_creditcard'))
        setLoadingSubmitForm(false)
        return
      }
    }
    props.submitForm()
  }

  const disabledButton = () => {
    if (isBankWire()) {
      return false
    }
    if (paymentForm.card_number.length < 15 || paymentForm.cvv === '' || paymentForm.name === '' || paymentForm.exp_date === '') {
      return true
    }
    return false
  }

  return (
    <>
      <Box sx={styles.paymentCard} style={{ zIndex: (props.isBackDropBtn ? 16 : 10) }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <Typography variant='label2'>
            {translate('payment')}
          </Typography>

          <Typography variant='caption5' fontWeight={400}>
            {translate(paymentType.channel)}
          </Typography>
        </Box>

        {isOptionBox() && <Box>
          <Box marginBottom='20px'>
            <Typography variant='caption4' fontWeight={600} color='#153862'>
              {translate('payment_options')}:
            </Typography>
          </Box>

          <Box sx={styles.creditCardBox} onClick={() => handlePaymentSelected('creditcard')}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}>
              <Box sx={styles.optionIcon}>
                <SVGCreditCard className='credit-card-icon' />
              </Box>
              <Typography variant='caption5' className='credit-card-text'>
                {translate('creditcard')}
              </Typography>
            </Box>

            <Box>
              <SVGArrow className='credit-card-arrow-icon' />
            </Box>
          </Box>

          <Divider sx={styles.dividerStyle} />

          <Box sx={styles.bankWireBox} onClick={() => handlePaymentSelected('bank_wire')}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}>
              <Box sx={styles.optionIcon}>
                <SVGBankWire className='bank-wire-icon' />
              </Box>
              <Typography variant='caption5' className='bank-wire-text'>
                {translate('bank_wire')}
              </Typography>
            </Box>

            <Box>
              <SVGArrow className='bank-wire-arrow-icon' />
            </Box>
          </Box>
        </Box>}

        {isCreditCard() && <CreditCardPayment />}

        {isBankWire() && <BankWirePayment />}
        {Object.keys(errorMessages).length > 0 && <Box
          sx={{ marginTop: '20px', width: '100%' }}>
          <SuperAlertDictV2
            list={errorMessages}
          />
        </Box>}

        {!isOptionBox() && <Box sx={styles.paymentButtonWrapper}>
          <Stack sx={styles.paymentButtonBox}>
            <CustomButton
              title={translate('payment')}
              onClick={submitForm}
              loading={loadingSubmitForm}
              disabled={disabledButton() || loadingSubmitForm}
            />
            <CustomButton
              title={translate('back')}
              onClick={onClickBack}
              bgColor='none'
              color='#003B6F'
              fontSize='18px'
              fontWeight={300}
              isColorDisabled='none'
              disabled={loadingSubmitForm}
            />
          </Stack>
        </Box>}
      </Box>

      {isOptionBox() && <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px'
      }}>
        <Box sx={{
          width: '485px',
        }}>
          <CustomButton
            title={translate('back')}
            onClick={onClickBack}
          />
        </Box>
      </Box>}
    </>
  )
}
export default PaymentBox