
import { atom } from 'recoil';
import { Product } from 'Constants/Interface/Product';
import { CheckoutInterface } from './CheckoutInterface';


export const CheckoutState = atom<CheckoutInterface>({
  key: 'checkoutState',
  default: {
    isSelectedRenewSet: false,
    renewalProductSelected: {} as Product,
    additionalProductSelected: [],
    cartItems: []
  }
})