import {
  Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Modal, Stack, ThemeProvider, Typography,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  orderDetailFormState,
  selectAddressState, selectTempAddressState,
} from 'Stores/OrderDetail/OrderDetailStore';
import styled from '@emotion/styled';
import { addressHistoryState } from 'Stores/AddressHistory/AddressHistoryStore';
import { SelectAddressType, SelectTempAddressType } from 'Constants/Interface/OrderDetail';
import { AddressHistoryItem } from 'Constants/Interface/AddressHistory/AddressHistoryItem';
import { AddressHistory } from 'Constants/Interface/AddressHistory/AddressHistory';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { Fragment } from 'react';
import CustomButton from '../CustomButton/CustomButton';

type Props = { open: boolean; handleClose: () => any }

type PropsDisPlayAddress = {
  displayAddress: string,
  displayName: string,
  addressHref: string,
  onSelect: any,
  selectTempAddress: SelectAddressType
}

function AddressHistoryModal(props: Props) {
  const addressHistory = useRecoilValue<AddressHistory>(addressHistoryState);
  const [selectAddress, setSelectAddress] = useRecoilState<SelectAddressType>(selectAddressState);
  const [selectTempAddress, setSelectTempAddress] = useRecoilState<SelectAddressType>(selectTempAddressState);
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);

  const [translate] = useTranslate()
  const onSelect = (addressHref: string) => {
    const findAddress = addressHistory.items.find((element: AddressHistoryItem) => element.addressHref === addressHref)
    if (findAddress) {
      setSelectTempAddress(findAddress)
    }
  }

  const onClickOK = () => {
    setSelectAddress(selectTempAddress)
    setAddressForm({
      ...addressForm,
      shipToAddress: selectTempAddress.shipToAddress,
      fullname: selectTempAddress.ushopShipToName
    });
    props.handleClose()
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
          '.MuiDialog-paper': {
            margin: '15px'
          }
        }
      }}
    >
      <Stack sx={{
        padding: '15px 15px 20px 15px',
        position: 'relative'
      }}>
        <Box sx={{ marginBottom: '5px' }}>
          <Typography variant='title2'>
            {translate('address_book')}
          </Typography>
        </Box>

        <Box sx={{
          height: '330px',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}>
          {
            addressHistory.items.map((element, index: number) => (
              <Fragment key={`${element.addressHref}-${index}`}>
                <AddressHistoryListListModal
                  displayName={element.ushopShipToName}
                  displayAddress={element.displayAddress}
                  addressHref={element.addressHref}
                  onSelect={onSelect}
                  selectTempAddress={selectTempAddress}
                />
              </Fragment>
            ))
          }
        </Box>
      </Stack>
      <DialogActions
        sx={{ justifyContent: 'space-between' }}
      >
        <Box
          sx={{ width: '50%' }}
        >
          <Button onClick={props.handleClose}>
            <Typography variant='title2' sx={{ textTransform: 'none' }}>
              {translate('urenew_close')}
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Box
            sx={{ width: '146px', height: '55px' }}
          >
            <CustomButton
              title={translate('ok')}
              bgColor='#479ded'
              disabled={!selectTempAddress.addressHref}
              onClick={onClickOK}
            />
          </Box>
        </Box>
      </DialogActions>
    </Dialog >
  );
}

function AddressHistoryListListModal(props: PropsDisPlayAddress) {

  const {
    displayName,
    displayAddress,
    addressHref,
    onSelect,

    selectTempAddress // << can't use recoil because it fast change 
  } = props

  const isSelect = selectTempAddress.addressHref === addressHref
  return (
    <Card
      onClick={() => onSelect(addressHref)}
      sx={isSelect ? selectAddressHistoryBorder : normalAddressHistoryBorder}
    >
      <Box>
        <Box>
          <Typography
            sx={isSelect ? selectAddressHistoryName : normalAddressHistoryName}
          >
            {displayName}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={isSelect ? selectAddressHistoryAddress : normalAddressHistoryAddress}
          >
            {displayAddress}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

const selectAddressHistoryBorder = {
  border: '2px solid #99BCDF',
  padding: '16px',
  marginBottom: '15px',
  cursor: 'pointer'
};

const selectAddressHistoryName = {
  "fontFamily": "Prompt",
  "fontSize": "18px !important",
  "fontStyle": "normal",
  "fontWeight": 600,
  "lineHeight": "normal"
}

const selectAddressHistoryAddress = {
  "color": "#003B6F",
  "fontFamily": "Prompt",
  "fontSize": "14px !important",
  "fontStyle": "normal",
  "fontWeight": 300,
  "lineHeight": "normal"
}

const normalAddressHistoryBorder = {
  border: '1px solid #99BCDF',
  padding: '16px',
  marginBottom: '15px',
  cursor: 'pointer'
};

const normalAddressHistoryName = {
  "fontFamily": "Prompt",
  "fontSize": "18px !important",
  "fontStyle": "normal",
  "fontWeight": 400,
  "lineHeight": "normal"
}

const normalAddressHistoryAddress = {
  "color": "#93AABF",
  "fontFamily": "Prompt",
  "fontSize": "14px !important",
  "fontStyle": "normal",
  "fontWeight": 300,
  "lineHeight": "normal",
}


export default AddressHistoryModal;
