import { selector, useRecoilCallback, useRecoilState, atom, useRecoilValue } from 'recoil';
import { cartState } from './CartInterface';
import { Product } from '../../Constants/Interface/Product';

export const useAddToCart = () => useRecoilCallback(({ snapshot, set }) => (product: Product, quantity: number = 1) => {
  const cartItems = snapshot.getLoadable(cartState).getValue() || [];

  const foundItemIndex = cartItems.findIndex((item) => item.product.id === product.id);

  if (foundItemIndex > -1) {
    const newCartItems = [...cartItems];
    newCartItems[foundItemIndex] = {
      ...newCartItems[foundItemIndex],
      quantity: newCartItems[foundItemIndex].quantity
    };
    set(cartState, newCartItems);
  } else {
    set(cartState, [...cartItems, { product, quantity }]);
  }
  
});

export const useRemoveFromCart = () => useRecoilCallback(({ snapshot, set }) => (productId: string) => {
  const cartItems = snapshot.getLoadable(cartState).getValue() || [];

  const newCartItems = cartItems.filter((item) => item.product.item_code !== productId)
  
  set(cartState, newCartItems);
});

export const isSelectBasicPack = selector({
  key: 'isSelectBasicPack',
  get: ({ get }) => {
    const cartItems = get(cartState);
    return cartItems[0]?.product.item_code === '36131';
  },
});