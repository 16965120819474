import { Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import { useDictionaries, useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { Fragment } from 'react';
import { styles } from './SuperAlertDictV2.style'


function SuperAlertDictV2(props: any) {
  const [translate] = useTranslate()
  const {
    list
  } = props
  return (
    <Box sx={styles.errorMessage}>
      {typeof list !== 'string' ? <>
        {list.map((error: string, index: number) => (
          <Stack
            key={index}
            sx={{
              borderBottom: '1px dashed #FE5D7C',
              width: '100%',
              textAlign: 'center',
              padding: '0 0 15px 0px',
              '&:last-child': {
                borderBottom: 'none',
                padding: 0
              }
            }}>
            <Typography variant="caption5" color={'#FE5D7C'}>
              {error.includes('_') ? translate(error) : error}
            </Typography>
            {/* <Typography variant="caption5" color={'#FE5D7C'}>{translate(error)}</Typography> */}
          </Stack>
        ))}
      </> : <Typography variant="caption5" color={'#FE5D7C'}>{list}</Typography>}

    </Box>
  );
}


export default SuperAlertDictV2;
