import { Box } from '@mui/material'
import Spinner from '../../../Assets/gif/loadingSpinner.gif'

type Props = {
  height?: string
};

function SpinnerLoading({ height }: Props) {
  return (
    <Box sx={{
      display: 'flex',
      minHeight: height || 'calc(100vh - 151px)',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={Spinner} alt="Spinner" width="85px" />
    </Box>
  )
}

SpinnerLoading.defaultProps = {
  height: '',
};

export default SpinnerLoading
