import { atom } from 'recoil';
import { CountriesInterface, CountryInterface } from './CountriesInterface';

export const countriesState = atom<CountriesInterface>({
  key: 'countriesState',
  default: {
    bg_img: {
      desktop: '',
      mobile: '',
    },
    data: {
      express: [],
      feelgreat: [],
      ushop: []
    },
    genealogy: {},
    icon: [],
    logo: {
      color: '',
      logo_url: '',
    },
    setting: {},
    success: false,
    title: {},
  },
});

export const countryState = atom<CountryInterface>({
  key: 'countryState',
  default: {
    country: '',
    roman_name: '',
    native_name: '',
    test: '',
    live: '',
    short: '',
    shorter: '',
    maintenance_mode: {
      maintenance_mode: false,
    },
    flagUrl: '',
    config: {
      currency: {
        code: '',
        name: '',
      },
      language: {
        default: '',
        list: [''],
        iso: {
          default: '',
          list: [''],
        },
      },
      languageSettings: {
        default: '',
        items: [{ name: '', code: '' }],
        list: []
      },
    },
  },
});

export const isCountryApiLoadingState = atom<boolean>({
  key: 'isCountryApiLoadingState',
  default: false,
});
