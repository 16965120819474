import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const SkeletonPaymentSuccess: React.FC = () => (
  <Box sx={Wrapper}>
    <Box sx={WrapperContent}>
      <Box sx={Card1}>
        <Skeleton
          animation="wave"
          height="100%"
          variant="rectangular"
          style={{ borderRadius: "12px" }}
        />
      </Box>
      <Box sx={Card2}>
        <Skeleton
          animation="wave"
          height="100%"
          variant="rectangular"
          style={{ borderRadius: "12px" }}
        />
      </Box>
    </Box>
  </Box>
);

const Wrapper = {
  width: "100%",
};

// reponsive mobile first

const WrapperContent = {
  display: "flex",
  flexDirection: "column",
  padding: "20px 15px",
  gap: "15px",

  "@media (min-width: 600px) and (max-width: 1024px)": {
    gap: "20px",
    alignItems: "center",
    padding: "40px 15px",
  },

  "@media (min-width: 1025px)": {
    gap: "30px",
    padding: "40px 15px",
    flexDirection: "row",
    justifyContent: "center",
  },
};

const Card1 = {
  width: "100%",
  height: "367px",
  maxWidth: "595px",

  "@media (min-width: 1025px)": {
    height: "440px",
    maxWidth: "646px",
  },
};

const Card2 = {
  width: "100%",
  height: "277px",
  maxWidth: "595px",

  "@media (min-width: 1025px)": {
    height: "365px",
    maxWidth: "440px",
  },
};

export default SkeletonPaymentSuccess;
