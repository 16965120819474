import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import mobiscroll from '@mobiscroll/react'
import { cartState } from 'Stores/Cart/CartInterface'
import { isBasicItemCode } from 'Stores/Products/ProductStore'
import ProductsCart from 'Components/Elements/Products/ProductCarts/ProductsCart'
import InformationForm from 'Components/Elements/OrderDetailElement/InformationForm'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isSelectBasicPack } from 'Stores/Cart/CartStore'
import { isLoadingOrderDetailState } from 'Stores/OrderDetail/OrderDetailStore'
import { guideState } from 'Stores/Guide/GuideStore'
import DeliveryTime from './DeliveryTime'
import { styles } from './OrderDetail.style'
import OrderDetailSkeleton from './OrderDetailSkeleton'
import PopupOrderDetailGuide from '../UrenewGuide/PopupOrderDetailGuide'

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light'
}

function OrderDetail() {
  const isBasicPackSelected = useRecoilValue(isSelectBasicPack)
  const basicItemCode = useRecoilValue(isBasicItemCode)
  const [guide, setGuide] = useRecoilState(guideState)
  const isLoading = useRecoilValue(isLoadingOrderDetailState)
  const [isStep, setIsStep] = useState<number>(0)
  const cartItems = useRecoilValue(cartState)

  const isShowOrderProductCardGuide = () => (
    !guide.isOrderDetailGuide && guide.isProductCardGuide && isStep === 1 && cartItems.length >= 2 && !isLoading
  )

  useEffect(() => {
    if (!guide.isOrderDetailGuide && cartItems.length >= 2) {
      setIsStep(1);
    }

    let newGuide: any = {}
    const sessionGuide: any = sessionStorage.getItem('isGuide')
    if (cartItems.length > 0 && cartItems[0].product.item_code === basicItemCode) {
      newGuide = {
        ...JSON.parse(sessionGuide),
        "isOrderDetailGuide": false,
        "isProductCardGuide": false,
        "isOrderSummaryGuide": false
      }
    } else {
      newGuide = JSON.parse(sessionGuide)
    }
    setGuide(newGuide)
  }, [cartItems])

  return (
    <Stack sx={{ minHeight: 'inherit' }}>
      {guide.isOrderDetailGuide && !isLoading && <Box sx={styles.backDrop}></Box>}
      {isShowOrderProductCardGuide() && <Box sx={styles.backDrop}></Box>}

      {!isLoading && <Stack sx={styles.container}>
        <Stack sx={{
          gap: '15px',
          width: '100%'
        }}>
          {!isBasicPackSelected && <Box sx={{ zIndex: (isStep === 0 ? 14 : 10) }}>
            <DeliveryTime />
          </Box>}
          <Box sx={(isStep === 1 && guide.isProductCardGuide) ? { zIndex: 14 } : {}}>
            <ProductsCart isStep={isStep} showBackDrop={isShowOrderProductCardGuide()} />
          </Box>
        </Stack>
        <Box sx={isStep >= 3 ? { zIndex: 14 } : {}}>
          <InformationForm isStep={isStep} showBackDrop={(guide.isOrderDetailGuide)} />
        </Box>

        {guide.isOrderDetailGuide && !isLoading && <PopupOrderDetailGuide step={isStep} setStep={setIsStep} />}
      </Stack>}

      {isLoading && <OrderDetailSkeleton />}
    </Stack >
  )
}

export default OrderDetail


