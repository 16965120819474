import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BuildBasename } from 'Cores/Helpers/CountryFromPathname';
import { countryState } from 'Stores/Countries/CountriesStore';
import { isSizeWindowState } from 'Stores/SizeWindow/SizeWindowStore';
import ResizeObserver from 'react-resize-observer';
import ProductsTestPage from 'Components/Elements/Tests/ProductsTestPage';
import LanguageAndDictionariesTestPage from 'Components/Elements/Tests/LanguageAndDictionariesTestPage';
import UserTestPage from 'Components/Elements/Tests/UserTestPage';
import ScrollToTop from 'Components/Elements/ScrollToTop/ScrollToTop';
import { usePeriod } from 'Stores/Period/usePeriod';
import PaymentSuccessPage from 'Components/Pages/PaymentSuccessPage';
import OrderDetailPage from 'Components/Pages/OrderDetailPage';
import Footer from 'Cores/Layouts/Footer/Footer';
import OrderSummaryPage from '../Components/Pages/OrderSummaryPage';
import Main from '../Cores/Layouts/Main';
import Header from '../Cores/Layouts/Header';
import LandingPage from '../Components/Pages/LandingPage';

function AppRouters() {
  const country = useRecoilValue(countryState);
  const [sizeWindow, setSizeWindow] = useRecoilState(isSizeWindowState)
  usePeriod();

  return (
    <BrowserRouter basename={`${BuildBasename(country.country)}`}>
      <ResizeObserver
        onResize={size => {
          setSizeWindow(size.width)
        }}
      />
      <ScrollToTop />
      <Header />
      <Main>
        <Suspense>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/:userHashed" element={<LandingPage />} />
            <Route path="/:userHashed/orderDetail" element={<OrderDetailPage />} />
            <Route path="/:userHashed/orderSummary" element={<OrderSummaryPage />} />
            <Route path="/test/user/:userHashed" element={<UserTestPage />} />
            <Route path="/test/products" element={<ProductsTestPage />} />
            <Route path="/test/language" element={<LanguageAndDictionariesTestPage />} />
            <Route path="/payment-success/:id" element={<PaymentSuccessPage />} />
          </Routes>
        </Suspense>
      </Main>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRouters;
