import { AppBar, Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { countryState } from '../../Stores/Countries/CountriesStore';
import { ReactComponent as LogoIcon } from '../../Assets/svg/logo-wo-tm.svg';
import { ReactComponent as SVGHamBars } from '../../Assets/icons/menu.svg';
import { CountryInterface } from '../../Stores/Countries/CountriesInterface';
import { styles } from './Layouts.style'

function Header() {
  const country: CountryInterface = useRecoilValue(countryState);

  const onClickMenu = () => {
    const destination = `//ushop.unicity.com/japan/menu`
    window.location.href = destination
  };

  return (
    <AppBar
      id='header'
      sx={styles.headerWrapper}>
      <Box sx={styles.headerBoxWidth}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Box
            onClick={onClickMenu}
            sx={styles.headerLogoMenu}>
            <SVGHamBars />
          </Box>
          <Box sx={styles.headerLogo}>
            <LogoIcon />
          </Box>
        </Box>

        <Box sx={{
          padding: '0 21px',
          '@media (min-width: 768px)': {
            padding: '0 30px',
          },
        }}>
          <Typography variant='caption5'>{country.country}</Typography>
        </Box>
      </Box>
    </AppBar>
  );
}

export default Header;
