import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { checkCardType } from 'Utils/CheckCardType';
import { isLoadingOrderDetailState, orderDetailFormState, selectAddressState } from 'Stores/OrderDetail/OrderDetailStore';
import { addressHistoryState } from 'Stores/AddressHistory/AddressHistoryStore';
import { isBasicItemCode, productsState } from 'Stores/Products/ProductStore';
import { cartState } from 'Stores/Cart/CartInterface';
import { cardTypeState, detailOrderSummaryState, isLoadingState, paymentFormState } from 'Stores/OrderSummary/OrderSummaryStore';
import { userState } from 'Stores/User/UserStore';
import { countryState } from 'Stores/Countries/CountriesStore';

const getFormat = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingOrderDetailState)
  const [cardType, setCardType] = useRecoilState(cardTypeState)
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get("uuid")
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);
  const [selectAddress, setSelectAddress] = useRecoilState(selectAddressState);
  const products = useRecoilValue(productsState);
  const [cartItems, setCartItems] = useRecoilState(cartState)
  const token = sessionStorage.getItem('tokenHydra')!;
  const baId = sessionStorage.getItem('baId')!;
  const [user, setUser] = useRecoilState(userState);
  const country = useRecoilValue(countryState);
  const [addressHistory, setAddressHistory] = useRecoilState(addressHistoryState);
  const basicItemCode = useRecoilValue(isBasicItemCode)

  useEffect(() => {
    getDetail()
  }, [products]);

  const getDetail = async () => {
    setIsLoading(true)
    try {
      if (products.items.length > 0) {
        const getUserResponse = await RenewalAPI.getUser(baId, token, country.short);
        setUser(getUserResponse.data.ushop.profile)

        const responseAddressHistory = await RenewalAPI.getAddressHistory(baId, token, country.short);
        setAddressHistory({
          ...responseAddressHistory.data,
        });

        const isCalOrder = /orderSummary/.test(window.location.pathname)
        const result = await RenewalAPI.getOrderSummaryFormat(uuid!, isCalOrder)
        const itemsJson = JSON.parse(result.data.ushop.detail_page.items)
        const cart = itemsJson.map((item: any) => {
          if (item.item_code === '32120' || item.item_code === '35413' || item.item_code === basicItemCode) {
            const findItems = products.renewal.find((product) => product.item_code === item.item_code)
            return {
              product: findItems,
              quantity: item.qty
            }
          }
          const findItems = products.items.find((product) => product.item_code === item.item_code)
          return {
            product: findItems,
            quantity: item.qty
          }
        })
        const { phone } = result.data.ushop.detail_page

        let formattedPhone = ''
        if (phone) {
          if (phone.length === 10) {
            formattedPhone = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
          } else if (phone.length === 11) {
            formattedPhone = phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
          } else {
            formattedPhone = phone;
          }
        }

        const convertValueToText = (value: any) => {
          if (result.data.ushop.detail_page.shipment_time_label === 'none' || result.data.ushop.detail_page.shipment_time_label === 'Not Specified') {
            return value;
          }
          const [start, end] = value.split('/');

          const startTime = start.slice(0, 5);
          const endTime = end.slice(0, 5);

          const textFormat = `${startTime} - ${endTime}`;
          return textFormat;

        }

        setCartItems(cart)
        setSelectAddress({
          ...selectAddress,
          displayAddress: result.data.ushop.urenew.formatContact.display_address,
          ushopShipToName: result.data.ushop.urenew.formatContact.name
        })
        setAddressForm({
          ...result.data.ushop.detail_page,
          phone: formattedPhone,
          shipment_time_label: convertValueToText(result.data.ushop.detail_page.shipment_time)

        })
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    } catch (error) {
      setIsLoading(false)
      console.warn(error)
    }
  }
};

export default getFormat;
