import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { countryState } from 'Stores/Countries/CountriesStore';
import { selectPrefectureState } from './OrderDetailStore';

export default function usePrefecture() {
  const [prefecture, setPrefecture] = useRecoilState(selectPrefectureState);
  const country = useRecoilValue(countryState);

  useEffect(() => {
    fetchDataPrefecture()
  }, [country.short])

  const fetchDataPrefecture = async () => {
    try {
      const res = await RenewalAPI.getPrefecture(country.short)
      if (res.data) {
        setPrefecture(res.data.data)
      }
    } catch (error) {
      console.error(error);
    }
  }
}
