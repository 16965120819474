import { Box, Skeleton, Stack } from '@mui/material'
import { styles } from './OrderDetail.style'

export default function OrderDetailSkeleton() {
  return (
    <Stack sx={styles.container}>
      <Stack gap='15px'>
        <Box sx={{
          height: '91px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '346px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>

        <Box sx={{
          height: '317px',
          '@media (min-width: 768px)': {
            width: '540px',
          },
          '@media (min-width: 1366px)': {
            width: '346px',
          }
        }}>
          <Skeleton
            animation="wave"
            height="100%"
            variant="rectangular"
            style={{ borderRadius: "12px" }}
          />
        </Box>
      </Stack>

      <Box sx={{
        height: '376px',
        '@media (min-width: 768px)': {
          width: '540px',
        },
        '@media (min-width: 1366px)': {
          width: '760px',
        }
      }}>
        <Skeleton
          animation="wave"
          height="100%"
          variant="rectangular"
          style={{ borderRadius: "12px" }}
        />
      </Box>
    </Stack>
  )
}
