import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Stack, Typography, ThemeProvider } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { countryState } from 'Stores/Countries/CountriesStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoadingOrderDetailState, isLoadingValidateOrderDetailState, orderDetailFormState, selectAddressState } from 'Stores/OrderDetail/OrderDetailStore';
import { getDataInfoUser, userState } from 'Stores/User/UserStore';
import { CartInterface, cartState } from 'Stores/Cart/CartInterface';
import { DataToValidateInterface } from 'Stores/OrderDetail/DataToValidateInterface';
import { RenewalAPI } from 'Services/RenewalAPIs';
import SuperAlertDictV2 from 'Components/fragment/SuperAlertDictV2';
import { isSelectBasicPack } from 'Stores/Cart/CartStore';
import { styles } from './OrderDetail.style';
import AddressHistoryForm from './AddressHistoryForm';
import CustomButton from '../CustomButton/CustomButton';
import NewAddress from './NewAddress';

function InformationForm(props: any) {
  const navigate = useNavigate()
  const [translate] = useTranslate()
  const isLoading = useRecoilValue(isLoadingOrderDetailState)
  const { userHashed } = useParams();
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState)
  const userInfo = getDataInfoUser()
  const cartItems = useRecoilValue(cartState)
  const country = useRecoilValue(countryState);
  const [selectAddress, setSelectAddress] = useRecoilState(selectAddressState);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingValidate, setLoadingValidate] = useRecoilState(isLoadingValidateOrderDetailState)
  const [errorMessage, setErrorMessage] = useState<string[]>([])

  const isBasicPackSelected = useRecoilValue(isSelectBasicPack)

  const token = sessionStorage.getItem('tokenHydra')

  useEffect(() => {
    // Function to update the isMobile state based on the viewport width
    const checkIsMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check when the component mounts
    checkIsMobile();

    // Add an event listener to check when the window is resized
    window.addEventListener('resize', checkIsMobile);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    setAddressForm({
      ...addressForm,
      selectAddressHistory: 'addressHistory',
      shipToAddress: selectAddress.shipToAddress,
      fullname: selectAddress.ushopShipToName
    })
  }, [])

  const dataToValidate = () => {
    const productItem = cartItems.map((item: CartInterface) => ({ item_code: item.product.item_code, qty: item.quantity }))
    let data = {
      fullname: addressForm.fullname,
      email: addressForm.email,
      phone: addressForm.phone.replace(/-/g, ''),
      shipment_options: "delivery",
      selectAddressHistory: addressForm.selectAddressHistory,
      shipment_time: addressForm.shipment_time,
      send_confirmation: true,
      send_tax_invoice: addressForm.send_tax_invoice,
      items: JSON.stringify(productItem),
      ...userInfo,
    } as DataToValidateInterface
    if (addressForm.selectAddressHistory === 'addressHistory') {
      data = {
        ...data,
        shipToAddress: addressForm.shipToAddress,
      }
    } else {
      data = {
        ...data,
        house_number: addressForm.house_number,
        town: addressForm.town,
        district: addressForm.district,
        prefecture: addressForm.prefecture,
        zip: addressForm.zip,
      }
    }
    return data
  }

  const onClickContinue = async () => {
    setErrorMessage([])
    setLoadingValidate(true)
    const data = dataToValidate()
    try {
      const response = await RenewalAPI.postSelectAddressHistory(data)
      if (response.success) {
        if (addressForm.selectAddressHistory === 'new') {
          newAddressHistory(response.uuid)
        } else {
          setTimeout(() => {
            navigate(`/${userHashed}/orderSummary?uuid=${response.uuid}`, { replace: true });
            setLoadingValidate(false)
          }, 1000)
        }
      } else {
        setErrorMessage(response.Message_v2)
        setLoadingValidate(false)
      }
    } catch (err) {
      setErrorMessage(['format_api_error'])
      setLoadingValidate(false)
    }
  }

  const newAddressHistory = async (uuid: string) => {
    try {
      const newData = {
        shipToName: {
          fullName: addressForm.fullname,
          firstName: addressForm.fullname,
          lastName: ''
        },
        shipToAddress: {
          address1: addressForm.house_number,
          address2: `${addressForm.prefecture}${addressForm.district}${addressForm.town}`,
          city: '',
          state: '',
          zip: addressForm.zip,
          country: country.shorter
        }
      }
      const res = await RenewalAPI.saveNewAddressHistory(newData, userInfo.login_id, country.short, token)
      if (res) {
        setTimeout(() => {
          navigate(`/${userHashed}/orderSummary?uuid=${uuid}`, { replace: true });
          setLoadingValidate(false)
        }, 1000)
      }
    } catch (error) {
      setTimeout(() => {
        navigate(`/${userHashed}/orderSummary?uuid=${uuid}`, { replace: true });
        setLoadingValidate(false)
      }, 1000)
    }
  }

  const onClickBack = () => {
    navigate(`/${userHashed}`, { replace: true })
  }

  const onClickChangeFormAddress = (value: string) => {
    setErrorMessage([])
    if (value === 'addressHistory') {
      setAddressForm({
        ...addressForm,
        selectAddressHistory: value,
        shipToAddress: selectAddress.shipToAddress,
        fullname: selectAddress.ushopShipToName
      })
    } else {
      setAddressForm({
        ...addressForm,
        selectAddressHistory: value,
      })
    }
  }

  return (
    <Box sx={styles.informationCard}>
      {(props.showBackDrop && props.isStep > 3 && props.isStep <= 4) && <Box sx={styles.backDropShowNewAddress}></Box>}

      {(props.showBackDrop && props.isStep === 5) && <Box sx={styles.backDropShipToNameTop}></Box>}
      {(props.showBackDrop && props.isStep === 5) && <Box sx={styles.backDropShipToNameBottom}></Box>}

      <Box sx={styles.informationWrapper}>
        <Typography variant='label2' fontWeight={700}>
          {isBasicPackSelected ? `${translate('contact')}` : `${translate('ship_to')}`}
        </Typography>

        {addressForm.selectAddressHistory === 'addressHistory' && !isBasicPackSelected && isLoading === false && <Box onClick={() => onClickChangeFormAddress('new')}>
          <Typography variant='caption3'>
            {translate('new')}
          </Typography>
        </Box>}

        {addressForm.selectAddressHistory === 'new' && <Box onClick={() => onClickChangeFormAddress('addressHistory')}>
          <Typography variant='caption3'>
            {translate('urenew_cancel')}
          </Typography>
        </Box>}
      </Box>

      {addressForm.selectAddressHistory === 'addressHistory' && <AddressHistoryForm />}
      {addressForm.selectAddressHistory === 'new' && <NewAddress />}

      {errorMessage.length > 0 && <Box
        sx={{ marginTop: '20px', width: '100%' }}>
        {/* block error for mobile */}
        <SuperAlertDictV2
          list={errorMessage}
        />
      </Box>}

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        '@media (min-width: 1366px)': {
          justifyContent: 'end',
        }
      }}>
        <Stack sx={{
          width: '100%',
          gap: '10px',
          '@media (min-width: 768px)': {
            width: '345px'
          }
        }}>
          <CustomButton
            title={translate('continue')}
            bgColor='#479ded'
            isOpacity={loadingValidate ? 0.5 : 1}
            loading={loadingValidate}
            disabled={loadingValidate}
            onClick={onClickContinue}
          />
          <CustomButton
            title={translate('back')}
            bgColor='white'
            color='#153862'
            onClick={onClickBack}
            disabled={loadingValidate}
            isColorDisabled='none'
          />
        </Stack>
      </Box>
    </Box >
  );
}

export default InformationForm;
