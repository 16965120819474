import styled from '@emotion/styled';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { MainTheme } from 'Cores/Layouts/MainTheme';
import { Spinner } from '../Spinner/Spinner';

type Props = {
  title?: string | string[];
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
  bgColor?: string;
  isColorDisabled?: string;
  isOpacity?: number;
  isCenterLoading?: boolean;
  fontSize?: string;
  fontWeight?: number;
  border?: string;
};

interface StyleButtonProps {
  backgroundColor?: string;
  isColorDisabled?: string;
  border?: string;
}

const spinnerCenter = {
  width: '100%'
};

const spinnerRight = {
  position: 'absolute',
  right: 25,
  display: 'flex',
  alignItems: 'center',
};

const StyleButton = styled.button<StyleButtonProps>`
    width: 100%;
    border-radius: 36px;
    background: ${(props) => props.backgroundColor || '#079FF4'};
    border: ${(props) => props.border || 'none'};
    cursor: pointer;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &:disabled {
        background: ${(props) => props.isColorDisabled || '#CCDEEF'};
    }
`;

function CustomButton({
  title, onClick, loading, disabled, color, bgColor, fontSize, border, fontWeight, isColorDisabled, isOpacity, isCenterLoading = false
}: Props) {
  return (
    <StyleButton onClick={onClick} disabled={disabled} backgroundColor={bgColor} isColorDisabled={isColorDisabled} border={border}>

      {
        loading && (
          <>
            {isCenterLoading === false && <Typography variant="label" color={color || '#fff'} fontWeight={fontWeight || 500} sx={{ opacity: isOpacity, fontSize: fontSize || '20px' }}>
              {title}
            </Typography>}

            <Box sx={isCenterLoading === false ? spinnerRight : spinnerCenter}>
              <Spinner />
            </Box>
          </>
        )
      }

      {
        !loading && (
          <Typography variant="label" color={color || '#fff'} fontWeight={fontWeight || 500} sx={{ opacity: isOpacity, fontSize: fontSize || '20px' }}>
            {title}
          </Typography>
        )
      }

    </StyleButton>
  );
}

CustomButton.defaultProps = {
  title: '',
  onClick: () => {},
  loading: false,
  disabled: false,
  color: '',
  bgColor: '',
  isColorDisabled: '',
  isOpacity: 1,
  isCenterLoading: false,
  fontSize: '20px',
  fontWeight: 500,
  border: '',
};

export default CustomButton;

