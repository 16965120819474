/* eslint-disable filenames/match-exported */
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { selectPrefectureState } from 'Stores/OrderDetail/OrderDetailStore';
import { PrefectureListSelectInterface, SelectPrefecture } from 'Constants/Interface/OrderDetail';
import { Box, TextField, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import mobiscroll from '@mobiscroll/react';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { ReactComponent as ArrowIcon } from '../../../../Assets/icons/arrow-down-icon.svg'

type Props = {
  label?: string | string[];
  type?: string;
  variant?: any;
  className?: string;
  inputProps?: any;
  value?: string;
  name?: string;
  onSet?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputSelectPrefecture = ({ label, type, variant, className, inputProps, value, name, onSet, onChange }: Props) => {
  const prefecture = useRecoilValue(selectPrefectureState);
  const currentLanguage = useRecoilValue(languageState);
  const myRef = useRef<any>(null)

  const handleOnClick = () => {
    if (myRef.current) {
      myRef.current.instance.show();
    }
  }

  const preFectureListSelect = prefecture.map((item: SelectPrefecture) => {
    const preFectureTextDisplay = currentLanguage === 'en' ? item.city_roman : item.city_native
    return {
      value: preFectureTextDisplay,
      text: preFectureTextDisplay,
      html: preFectureTextDisplay
    }
  }).filter((x) => x.value !== undefined) as PrefectureListSelectInterface[];

  return (
    <Box>
      <UInputStyled className={className || ''}>
        <TextField
          label={label}
          variant={variant}
          value={value}
          type={type}
          name={name}
          InputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'false',
            endAdornment: (
              <IconButton edge="end">
                <ArrowIcon />
              </IconButton>
            ),
            ...inputProps,
          }}
          onChange={onChange}
          onClick={handleOnClick}
        />
      </UInputStyled>
      <mobiscroll.Select
        ref={myRef}
        filter
        showInput={false}
        data={preFectureListSelect}
        display={'center'}
        touchUi={false}
        buttons={[]}
        rows={7}
        height={35}
        maxWidth={450}
        focusOnClose={false}
        onSet={onSet}
        showOverlay={true}
        filterPlaceholderText={currentLanguage === 'ja' ? '' : 'Search'}
      />
    </Box>
  )
}

const UInputStyled = styled.div`
    width: 100%;
    .MuiFormControl-root {
        &.MuiTextField-root {
            font-family: 'Prompt, sans-serif';
            width: 100%;
            height: 55px;
           
            .MuiInputBase-root {
                background-color: #ffffff;
                border: 1px solid #A0BBDC;
                border-radius: 8px;
                height: 55px;
                display: flex;
                align-items: center;
                color: #000000;
                &::after,
                &::before {
                    border: 0;
                }
            }

            .MuiInputLabel-root {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 29px;
                color: #8A9BB0;
                margin-bottom: 0px;
                top: -2px;
                transform: translate(19px, 16px) scale(1);
                
                &.MuiInputLabel-shrink {
                    transform: translate(19px, 4px) scale(0.8);
                    top: 1px;
                }
            }

            .MuiOutlinedInput-input {
                color: #000000;
                right: 30px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                padding: 0;
                padding: 25px 12px 5px 18px ;
            }

            .MuiOutlinedInput-notchedOutline {
                display: none;
            }

            .MuiIconButton-root {
                margin-right: -8px;
            }
            
        }
    }
`;

InputSelectPrefecture.defaultProps = {
  label: '',
  type: '',
  variant: undefined,
  className: '',
  inputProps: undefined,
  value: '',
  name: '',
  onSet: undefined,
}

export default InputSelectPrefecture;