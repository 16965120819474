import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { CountryFromPathname } from 'Cores/Helpers/CountryFromPathname';
import { footerState } from 'Stores/Footer/FooterStore';
import { countriesState, countryState, isCountryApiLoadingState } from './CountriesStore';
import { CountryInterface } from './CountriesInterface';

export default function useCountries() {
  const [country, setCountry] = useRecoilState(countryState);
  const [countries, setCountries] = useRecoilState(countriesState);
  const [footerData, setFooterData] = useRecoilState(footerState);
  const [isLoading, setIsLoading] = useRecoilState(isCountryApiLoadingState);

  useEffect(() => {
    if (isLoading === false && countries.data.ushop.length === 0) {
      setIsLoading(true);
    }
  }, [countries.data.ushop.length, isLoading, setIsLoading]);

  useEffect(() => {
    if (isLoading) {
      const GetCountries = async () => {
        try {
          const [
            response,
            responseFooter
          ] = await Promise.all([
            RenewalAPI.getCountry(),
            RenewalAPI.getCurrency()
          ])

          setFooterData(responseFooter.footer);
          setCountries(response.data);
          setIsLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn({ error });
        }
      };

      GetCountries();
    }
  }, [isLoading, setCountries, setIsLoading]);

  useEffect(() => {
    if (countries.data.ushop.length > 0 && country.country === '') {
      const countryFromPathName = CountryFromPathname()
      const countryFound = countries.data.ushop.find((country: CountryInterface) => country.country.toLowerCase() === countryFromPathName.toLowerCase())
      if (countryFound) {
        setCountry(countryFound)
      }
    }
  }, [countries, country, setCountry]);
}
