import styled from '@emotion/styled'
import { Stack } from '@mui/material'

const ProductCard = styled(Stack)`
    margin-top: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 400px;
    min-height: 370px;
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
export const ProductStyled = {
  ProductCard
}