/* eslint-disable react/require-default-props */
import { useState } from 'react'
import styled from '@emotion/styled';
import { TextField, IconButton } from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../../Assets/icons/remove-icon.svg'

type Props = {
  label?: string
  type?: string
  variant?: any
  className?: string
  inputProps?: any
  value?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  clearinput?: (fieldName: string) => void;
  disabled?: boolean
};

const CustomInputPassword = ({ label, type, variant, className, inputProps, value, onChange, name, clearinput, disabled }: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleInputFocus = () => {
    setIsFocused(true)
  }

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClearInput = () => {
    if (name && clearinput) {
      clearinput(name);
    }
  };

  return (
    <UInputStyled className={className || ''}>
      <TextField
        label={label}
        variant={variant}
        value={value}
        type={type}
        name={name}
        disabled={disabled}
        InputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          spellCheck: 'false',
          endAdornment: isFocused && value && (
            <IconButton onClick={handleClearInput} onMouseDown={handleMouseDown} edge="end">
              <RemoveIcon />
            </IconButton>
          ),
          ...inputProps,
        }}
        onChange={onChange}
        onFocus={() => handleInputFocus()}
        onBlur={() => handleInputBlur()}
      />
    </UInputStyled>
  );
}

const UInputStyled = styled.div`
    width: 100%;
    .MuiFormControl-root {
        &.MuiTextField-root {
            font-family: 'Prompt, sans-serif';
            width: 100%;
            height: 55px;
           
            .MuiInputBase-root {
                background-color: #ffffff;
                border: 1px solid #A0BBDC;
                border-radius: 8px;
                height: 55px;
                display: flex;
                align-items: center;
                color: #000000;
                &::after,
                &::before {
                    border: 0;
                }
            }

            .MuiInputLabel-root {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 29px;
                color: #8A9BB0;
                margin-bottom: 0px;
                top: -2px;
                transform: translate(19px, 16px) scale(1);
                
                &.MuiInputLabel-shrink {
                    transform: translate(19px, 5px) scale(0.8);
                    top: 1px;
                }
            }

            .MuiOutlinedInput-input {
                color: #000000;
                right: 30px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                padding: 0;
                padding: 25px 12px 4px 18px ;
                -webkit-text-security: disc;
                -moz-webkit-text-security: disc;
                -moz-text-security: disc;   
            }

            .MuiOutlinedInput-notchedOutline {
                display: none;
            }

            .MuiIconButton-root {
                margin-top: 18px;
            }
            
        }
    }
`;

export default CustomInputPassword;
