import axios from 'axios';
import Bowser from 'bowser';
import { ServerEnv } from 'Cores/Countries/Environment/ServerEnv';
import { PaymentFormType, SendEmailDataType, UpdataLogDataType } from 'Constants/Interface/OrderSummary';
import httpHelper from './HttpHelper';

const getCurrency = async () => {
  try {
    const response = await axios.get(`https://${ServerEnv.MemberCalls2()}.unicity.com/footer/data/JPN`);
    return response.data;
  } catch (error) {
    console.warn({ error });
  }
};

const getCountry = async () => {
  const url = httpHelper.Proxy({
    originalUrl: `https://member-calls2.unicity.com/settings/countryV2/enable/all`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.get(url, {
    headers,
  });
};

const getIdBroker = async (userId: string) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/remoteStorage/broker/${userId}`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.get(url, {
    headers,
  });
};

const getPeriod = async (countryCode: string, joinPeriod: string) => {
  try {
    const data: any = {
      country: countryCode,
      system: 'AO',
      joinPeriod: joinPeriod || '',
    };
    const response = await axios.post(`https://${ServerEnv.MemberCalls2()}.unicity.com/topup/period`, data);

    return response.data;
  } catch (error) {
    console.warn({ error });
  }
};

const getAddressHistory = (baId?: string, token?: string, countryCode?: string): Promise<any> => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/addressHistory`,
    queryString: {
      baId,
      ushopCountryCode: countryCode,
      isQa: ServerEnv.MemberCalls2() === 'member-calls2-dev' ? 1 : 0
    },
  });

  const headers = {
    'Content-Type': 'application/json',
    'Authorization-Hydra': token,
  };

  return axios.get(url, {
    headers,
  });
};

const postSelectAddressHistory = async (body: object) => {

  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/JPN/renewal/v2/address`,
    queryString: {},
  });

  const response = await axios.post(url, {
    ...body
  });
  return response.data;
};

const saveNewAddressHistory = async (body: object, baId?: string, countryCode?: string, token?: string | null) => {
  const queryString = ServerEnv.MemberCalls2() === 'member-calls2-dev' ? {
    isQa: '1'
  } : {}
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/addressHistory?baId=${baId}&ushopCountryCode=${countryCode}`,
    queryString,
  });

  const data = {
    ...body
  }

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Hydra': `Bearer ${token}`,
    }
  };

  return axios.post(url, data, headers)
};

const postSelectNewAddress = async (newAddressForm: any, items: any) => {
  const bowser = () => Bowser.getParser(window.navigator.userAgent);
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/JPN/renewal/v2/address`,
    queryString: {},
  });
  const response = await axios.post(url, {
    items: JSON.stringify(items),
    shipment_options: 'delivery',
    selectAddressHistory: 'new',
    ...newAddressForm,
  });
  return response.data;
};

const getOrderSummaryFormat = async (uuid: string, isCalOrder: boolean) => {
  let apiUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/JPN/renewal/detail-v2/${uuid}`
  if (isCalOrder) {
    apiUrl += '?ordercalC=1'
  } else {
    apiUrl += '?ordercalC=0'
  }
  const url = httpHelper.Proxy({
    originalUrl: apiUrl,
    queryString: {},
  })

  return axios.get(url)
}

const createPayment = async (uuid: string) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/hydra-value/renewal/JPN${ServerEnv.MemberCalls2() === 'member-calls2-dev' ? '?dev=1' : ''}`,
    queryString: {},
  });
  const data = {
    uuid,
    source_path: window.location.href,
  };
  return axios.post(url, data);
};

const paymentHydra = async (baToken: string, data: any, country: string) => {
  const url = httpHelper.Proxy({
    originalUrl: `${ServerEnv.Hydra()}/customers/me/orders`,
    queryString: {},
  });

  // const data = {
  //   ...transaction
  // }

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': baToken,
      'X-Application': `asia.urenew.${country}`
    }

  };

  return axios.post(url, data, headers)
}

const updateLog = async (dataObject: UpdataLogDataType) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/hydra-log`,
    queryString: {},
  })
  const data = {
    ...dataObject
  }
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return axios.post(url, data, headers)
}

const sendEmail = async (dataObject: SendEmailDataType) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-v2/public/JPN/shop`,
    queryString: {},
  })

  const data = {
    ...dataObject
  }
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return axios.post(url, data, headers)
}

const getUser = (baId?: string, token?: string, countryCode?: string): Promise<any> => {
  const queryString = ServerEnv.MemberCalls2() === 'member-calls2-dev' ? {
    isQa: '1'
  } : {}
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/onself?ushopCountryCode=${countryCode}&baId=${baId}&collapse=hydra`,
    queryString,
  });

  const headers = {
    'Content-Type': 'application/json',
    'Authorization-Hydra': token,
  };

  return axios.get(url, {
    headers,
  });
};

const getPrefecture = (countryCode?: string): Promise<any> => {
  const url = httpHelper.Proxy({
    originalUrl: `https://member-calls2.unicity.com/unishop-fn-misc/city/country/${countryCode}`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.get(url, {
    headers,
  });
};

const getZipcode = (countryCode?: string, keyword?: string): Promise<any> => {
  const url = httpHelper.Proxy({
    originalUrl: `https://member-calls2.unicity.com/unishop-fn-misc/city/search?country_code=${countryCode}&keyword=${keyword}`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.get(url, {
    headers,
  });
};

const HydraWorldPayReqForm = (CreditcardNumber: string, country: string) => {
  const urlApi = `${ServerEnv.Hydra()}/ddc`
  const apiUrl = httpHelper.Proxy({
    originalUrl: urlApi,
    queryString: {},
  })

  const headers = {
    headers: {
      'X-Application': `asia.urenew.${country}`
    }
  }

  return axios.post(
    apiUrl,
    JSON.stringify({
      bin: CreditcardNumber
    }),
    headers
  )
}

const HydraOrderStatus = (orderId: string, baToken: string, country: string) => {
  const url = httpHelper.Proxy({
    originalUrl: `${ServerEnv.Hydra()}/orders?id.unicity=${orderId}`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': baToken,
    'X-Application': `asia.urenew.${country}`
  };

  return axios.get(url, {
    headers,
  });
}

const getIsShowGuide = async (baId: string) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/remoteStorage/data/urenew-guide-${baId}`,
    queryString: {},
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.get(url, {
    headers,
  });
};

const postIsShowGuide = async (baId: string, body: object) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/remoteStorage/data/urenew-guide-${baId}`,
    queryString: {},
  });

  const response = await axios.post(url, {
    ...body
  });
  return response.data;
};
const validateCreditCardApi = (body: any) => {
  const url = httpHelper.Proxy({
    originalUrl: `https://member-calls2.unicity.com/validate/creditCard/checkValidate`,
    queryString: {},
  });
  return axios.post(url, body);
}

export const RenewalAPI = {
  getCurrency,
  getIdBroker,
  getPeriod,
  getAddressHistory,
  postSelectAddressHistory,
  postSelectNewAddress,
  getOrderSummaryFormat,
  createPayment,
  paymentHydra,
  updateLog,
  sendEmail,
  getUser,
  getPrefecture,
  getZipcode,
  getCountry,
  saveNewAddressHistory,
  HydraWorldPayReqForm,
  HydraOrderStatus,
  getIsShowGuide,
  postIsShowGuide,
  validateCreditCardApi
};
