import { atom } from 'recoil';

interface GuideInterface {
  isLandingPageGuide: boolean,
  isOrderDetailGuide: boolean,
  isProductCardGuide: boolean,
  isOrderSummaryGuide: boolean,

}

export const guideState = atom<GuideInterface>({
  key: 'guideState',
  default: {
    isLandingPageGuide: true,
    isOrderDetailGuide: true,
    isProductCardGuide: true,
    isOrderSummaryGuide: true,
  },
});

interface StepGuideProductCartInterface {
  stepPopup: number
}

export const guideProductCartState = atom<StepGuideProductCartInterface>({
  key: 'guideProductCartState',
  default: {
    stepPopup: 0,
  }
})