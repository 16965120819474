import { Button, Container, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { countryState } from '../../../Stores/Countries/CountriesStore';
import { languageState } from '../../../Stores/Dictionaries/DictionariesStore';
import { useDictionaries, useTranslate } from '../../../Stores/Dictionaries/useDictionaries';
import { CountryInterface } from '../../../Stores/Countries/CountriesInterface';

function LanguageAndDictionariesTestPage() {
  const [translate] = useTranslate();
  const country: CountryInterface = useRecoilValue(countryState);
  const [language, setLanguage] = useRecoilState(languageState);

  return (
    <Stack>
      <Container>
        <h2>Change Language</h2>
        <h3>{`Current Language: ${language}`}</h3>
        <ul>
          <li>
            example to translate &quot;error_password_not_match&quot; =
            {translate('error_password_not_match')}
          </li>
        </ul>
        {country.config.language.iso.list.map((lang: any) => (
          <Button
            key={lang}
            onClick={() => {
              setLanguage(lang.toLowerCase());
            }}
          >
            {lang}
          </Button>
        ))}
      </Container>
    </Stack>
  );
}

export default LanguageAndDictionariesTestPage;
