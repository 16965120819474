import { Stack, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { CheckoutState } from 'Stores/Checkout/CheckoutStore';
import ProductListLayout from './ProductComponents/ProductListLayout';
import AddMoreProducts from './ProductComponents/AddMoreProducts';

function ProductList() {
  const checkout = useRecoilValue(CheckoutState)

  return (
    <Stack>
      <ProductListLayout />
      <Divider sx={{ marginTop: '40px', borderColor: '#AFBED5' }} />
      {checkout.renewalProductSelected.item_code && checkout.isSelectedRenewSet && <Stack>
        <AddMoreProducts />
      </Stack>}
    </Stack>
  );
}

export default ProductList;
