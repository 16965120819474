import * as valid from 'card-validator'

/**
 * Validate credit card
 *
 * Example cardInfo
 * ```js
 * {
 * 	countryCode: "JP",
 * 	creditCard: {
 * 	  creditCardNumber: '345177925488348',
 * 	  payer: 'test',
 *    creditCardExpires: '2030-10',
 * 	  creditCardSecurityCode: '7373'
 * 	}
 * }
 * ```
 * ---
 * if there are no any errors it will return
 * ```js
 * { success: true }
 * ```
 * otherwise
 * ```js
 * { success: false, error_messages: ['some error']}
 * ```
 * ---
 * `countryCode`: must be `TH | JP | AU | NZ`\
 * `creditCardNumber`: is a number with no whitespace\
 * `payer`: a-z, A-Z, 0-9, whitespace\
 * `creditCardExpires`: format must be like this -> '2030-10'\
 * `creditCardSecurityCode`: a number with a length between 3 - 4
 *
 * @param {object} cardInfo
 * @returns {object}
 */
function CardValidate(cardInfo: {
    countryCode: string
    creditCard: {
        creditCardNumber: string
        payer: string
        creditCardExpires: string
        creditCardSecurityCode: string
    }
}): { success: boolean; error_messages?: string[] } {
  // Mutable object for pass by reference
  const context: {
        cardType?: string
        errors: string[]
        success: boolean
    } = {
      cardType: undefined,
      errors: [],
      success: true
    }
  const { countryCode: dsCountryCOde, creditCard: dsCreditCard } = cardInfo
  const {
    payer: dsPayer,
    creditCardNumber: dsCreditCardNumber,
    creditCardExpires: dsCreditCardExpires,
    creditCardSecurityCode: dsCreditCardSecurityCode
  } = dsCreditCard

  const countryCode = dsCountryCOde
  const cardNumber = dsCreditCardNumber
  const payer = dsPayer
  const expiredDate = dsCreditCardExpires
  const cvv = dsCreditCardSecurityCode

  validateNumber(context, cardNumber)
  validatePayer(context, payer)
  validateExpirationDate(context, expiredDate)
  validateCountry(context, countryCode)
  validateCVV(context, cvv)

  if (!context.success) {
    return { success: false, error_messages: context.errors }
  }

  return { success: true }
}

function pushError(context: { errors: string[]; success: boolean }, errorMsg: string) {
  context.errors.push(errorMsg)
  context.success = false
}

function validateNumber(context: { cardType?: string; errors: string[]; success: boolean }, cardNumber: string) {
  if (!cardNumber) {
    pushError(context, 'u_all_creditcard_not_found')
    return
  }

  const numberValidation = valid.number(cardNumber)
  if (!numberValidation.isValid || /^[0-9]*$/g.test(cardNumber) === false) {
    pushError(context, 'creditcard_invalid')
  }

  context.cardType = numberValidation?.card?.type
}

function validatePayer(context: { errors: string[]; success: boolean }, payer: string) {
  if (payer === '') {
    pushError(context, 'u_all_cardholder_not_found')
    return
  }

  // Does not match a-z, A-Z, 0-9, whitespace, "." (dot)
  if (/^[a-zA-Z0-9\s\.]*$/g.test(payer) === false) {
    pushError(context, 'u_all_cardholder_invalid')
  }
}

function validateExpirationDate(context: { errors: string[]; success: boolean }, expiredDate: string) {
  if (expiredDate === undefined) {
    pushError(context, 'u_all_expired_not_found')
    return
  }

  const { isValid } = valid.expirationDate(expiredDate)
  if (!isValid || /^\d{4}-\d{2}$/g.test(expiredDate) === false) {
    pushError(context, 'u_all_expired_invalid')
  }
}

function validateCountry(context: { cardType?: string; errors: string[]; success: boolean }, countryCode: string) {
  if (countryCode === undefined) {
    pushError(context, 'u_all_country_code_not_found')
    return
  }

  const isCountrySupport = ['TH', 'JP', 'AU', 'NZ'].includes(countryCode)
  if (!isCountrySupport) {
    pushError(context, 'u_all_country_code_is_not_support')
  }

  if (!context.cardType) {
    return
  }

  // Todo: allow JCB for TH only
  if (['TH', 'AU', 'NZ'].includes(countryCode)) {
    const isAccept = ['visa', 'mastercard', 'jcb'].includes(context.cardType)
    if (!isAccept) {
      pushError(context, 'u_all_only_accept_visa_or_mastercard')
    }
  }

  if (countryCode === 'JP') {
    const acceptList = ['visa', 'mastercard', 'american-express', 'jcb']
    const isAccect = acceptList.includes(context.cardType)
    if (!isAccect) {
      pushError(context, `u_all_jp_not_accept_${context.cardType}`)
    }
  }
}

function validateCVV(context: { cardType?: string; errors: string[]; success: boolean }, cvv: string) {
  if (!cvv) {
    pushError(context, 'u_all_securitycode_not_found')
    return
  }

  const cvvLength = context.cardType === 'american-express' ? 4 : 3
  const { isValid } = valid.cvv(cvv, cvvLength)
  if (!isValid) {
    pushError(context, 'u_all_securitycode_invalid')
  }
}

export default CardValidate
