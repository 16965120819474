import React from 'react';
import { Container, Stack } from '@mui/material';
import useProducts from '../../../Stores/Products/useProducts';
import ProductList from '../Products/ProductList';

function ProductsTestPage() {
  useProducts();
  return (
    <Stack>
      <Container>
        <ProductList />
      </Container>
    </Stack>
  );
}

export default ProductsTestPage;
