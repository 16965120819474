import React from 'react';
import { RecoilRoot } from 'recoil';
import Init from './Init';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

function App() {
  return (
    <RecoilRoot>
      <Init />
    </RecoilRoot>
  );
}

export default App;
