import Bowser from 'bowser';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Authen } from 'Constants/Interface/Authen';
import { GetNativeName } from 'Cores/Helpers/getNativeName';
import { periodState } from '../Period/PeriodStore';

interface HumanName {
  firstName: string
  lastName: string
  fullName: string
  [key: string]: string
}

interface Id {
  unicity: string
}
interface UserAbstract {
  [key: string]: string | HumanName | Id | any;
}

export const LIST_MAPPING_BA_STATUS = [
  {
    code: 'A',
    status: 'Active',
    type: 'Associate',
    description: 'Active',
    priceType: 'wholesale',
  },
  {
    code: 'B',
    status: 'Pending',
    type: 'Associate',
    description: 'Pending',
    priceType: 'wholesale',
  },
  {
    code: 'C',
    status: 'Active',
    type: 'Customer',
    description: 'Customer',
    priceType: 'retail',
  },
  {
    code: 'D',
    status: 'Defective',
    type: 'Associate',
    description: 'Balance (CHE, DEU), Defective (JP)',
    priceType: 'wholesale',
  },
  {
    code: 'E',
    status: 'Active',
    type: 'Employee',
    description: 'Employee',
    priceType: 'employee',
  },
  {
    code: 'F',
    status: 'Active',
    type: 'Family',
    description: 'Family Pricing',
    priceType: 'employee',
  },
  {
    code: 'H',
    status: 'Active',
    type: 'WholesaleCustomer',
    description: 'Wholesale Customer',
    priceType: 'wholesale',
  },
  {
    code: 'L',
    status: 'Active',
    type: 'Performance Labs',
    description: 'Performance Labs',
    priceType: 'wholesale',
  },
  {
    code: 'M',
    status: 'Active',
    type: 'Member',
    description: 'Member',
    priceType: 'wholesale',
  },
  {
    code: 'P',
    status: 'Active',
    type: 'PreferredCustomer',
    description: 'Preferred Customer',
    priceType: 'preferred',
  },
  {
    code: 'R',
    status: 'Retired',
    type: 'Associate',
    description: 'Retired',
    priceType: null,
  },
  {
    code: 'S',
    status: 'Suspended',
    type: 'Associate',
    description: 'Suspended',
    priceType: null,
  },
  {
    code: 'T',
    status: 'Terminated',
    type: 'Associate',
    description: 'Terminated',
    priceType: null,
  },
];

export const userState = atom<UserAbstract>({
  key: 'userStore',
  default: {},
});

export const isUserApiLoadingState = atom<boolean>({
  key: 'isUserApiLoadingState',
  default: false,
});

export const authState = atom<Authen>({
  key: 'authState',
  default: {
    baId: '',
    token: '',
    customerHref: '',
    language: '',
  },
});

export function getBaStatus() {
  const user = useRecoilValue(userState)
  
  const baCode = LIST_MAPPING_BA_STATUS.find((element: any) => {
    const { status, type } = element
    if (user.status === status && user.type === type) return status
  })
  
  const { code } = baCode || {}

  return code
}

export function getDataInfoUser() {
  const bowser = () => Bowser.getParser(window.navigator.userAgent);
  const user = useRecoilValue(userState)
  const period = useRecoilValue(periodState)
  const allData = {
    period: period.period,
    ba_status: getBaStatus(),
    login_id: (user.id as Id)?.unicity,
    login_name: (user.humanName as HumanName)?.fullName,
    login_name_native: user.humanName ? GetNativeName({ humanName: user.humanName, nameType: 'nativeName' }) : '',
    platform: bowser().getOSName(),
    agent: "MLBS-URENEW",
    medium: "Internet",
  }
  return allData
}
