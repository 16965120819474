import { useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import mobiscroll from '@mobiscroll/react';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { useRecoilState } from 'recoil';
import { orderDetailFormState } from 'Stores/OrderDetail/OrderDetailStore';
import { styles } from './OrderDetail.style'

function DeliveryTime() {
  const [translate] = useTranslate();
  const myRef = useRef<any | string[]>(null)
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState)

  const data = [
    { value: 'None', text: translate('urenew_none').toLocaleString() },
    { value: '08:00:00/12:00:00', text: '08:00 - 12:00' },
    { value: '14:00:00/16:00:00', text: '14:00 - 16:00' },
    { value: '16:00:00/18:00:00', text: '16:00 - 18:00' },
    { value: '18:00:00/20:00:00', text: '18:00 - 20:00' },
    { value: '19:00:00/21:00:00', text: '19:00 - 21:00' },
  ]

  const showSelect = () => {
    if (myRef.current) {
      myRef.current.instance.show();
    }
  }

  const setValue = (_: any, inst: any) => {
    const selected = inst.getVal()
    const displayLabel = data.find((item) => item.value === selected)?.text || 'none'
    setAddressForm({ ...addressForm, shipment_time: selected, shipment_time_label: displayLabel });
  };


  return (
    <Box sx={styles.orderDetailCard}>
      <Stack width='100%' gap='10px'>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Box>
            <Typography variant='caption4' fontWeight={600}>
              {translate('urenew_delivery_time')}:
            </Typography>
          </Box>
          <Box onClick={showSelect}>
            <Typography variant='caption3'>
              {translate('change')}
            </Typography>
          </Box>
        </Box>
        <Box>
          {(addressForm.shipment_time_label.toLocaleString().toLowerCase() === 'none' || addressForm.shipment_time_label.toLocaleString().toLowerCase() === 'not specified') ? <Typography variant='caption5' color={'#8A9BB0'}>
            {translate('not_specified')}
          </Typography> : <Typography variant='caption5' color={'#003B6F'}>
            {addressForm.shipment_time_label}
          </Typography>}
        </Box>

        <mobiscroll.Select
          ref={myRef}
          showInput={false}
          display='center'
          touchUi={true}
          data={data}
          onSet={setValue}
          placeholder={translate('urenew_none').toLocaleString()}
          cancelText={translate('urenew_cancel').toLocaleString()}
          setText={translate('ok').toLocaleString()}
          showOverlay={true}
        />
      </Stack>
    </Box>
  );
}

export default DeliveryTime;
