import axios from 'axios';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { isPeriodApiLoadingState, periodState } from './PeriodStore';
import { countryState } from '../Countries/CountriesStore';

export function usePeriod() {
  const [periodList, setPeriodList] = useRecoilState(periodState);
  const [isLoading, setIsLoading] = useRecoilState(isPeriodApiLoadingState);
  const country = useRecoilValue(countryState);

  useEffect(() => {
    if (isLoading === false && periodList.period === '') {
      setIsLoading(true);
    }
  }, [periodList, isLoading, setIsLoading]);

  useEffect(() => {
    if (isLoading) {
      const GetPeriodList = async () => {
        try {
          const response = await RenewalAPI.getPeriod(country.shorter, '');
          setPeriodList(response.message[0]);
          setIsLoading(false);
        } catch (error) {
          /** TODO: catch */
          console.warn({ error });
        }
      };

      GetPeriodList();
    }
  }, [isLoading, setPeriodList, setIsLoading]);
}
