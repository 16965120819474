/* eslint-disable @typescript-eslint/naming-convention */
import { Product } from 'Constants/Interface/Product';

const isJSONValid = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export const getProductNameOnConfig = (product: Product) => {
  const {
    remarks,
    item_name
  } = product
  let productName = item_name
  const checkJSON = isJSONValid(remarks)
  if (checkJSON) {
    const remarksJSON = remarks ? JSON.parse(remarks) : undefined
    if (remarksJSON) {
      if (remarksJSON.product_name_alt) {
        productName = remarksJSON.product_name_alt
      }
    }
  }

  return productName
}

export const delay = (ms: number | undefined) => new Promise(resolve => { setTimeout(resolve, ms) })