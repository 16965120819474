import React, { useEffect, useState } from 'react'
import { Box, Typography, ThemeProvider, Modal, InputBase } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { MainTheme } from '../../../../Cores/Layouts/MainTheme'
import ProductMoreList from '../ProductMoreList'
import { productsState } from '../../../../Stores/Products/ProductStore'
import { useDictionaries, useTranslate } from '../../../../Stores/Dictionaries/useDictionaries'

const AddMoreProducts = (props: any) => {
  const products = useRecoilValue(productsState)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [inputText, setInputText] = useState('')
  const [selectProduct, setSelectProduct] = useState('')
  const [moreProducts, setMoreProducts] = useState<string[]>([])
  const [getCheckboxItemCode, setGetCheckboxItemCode] = useState(false)
  const [translate] = useTranslate();
  const inputHandler = (e: any) => {
    const lowerCase = e.target.value
    setInputText(lowerCase)
  }
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    loadeData()
  }, [products.renewal])

  const loadeData = () => {
    products.renewal.map(item => {
      setMoreProducts((prevData: any) => {
        const filter = item
        return [...prevData, filter]
      })
    })
    setLoading(false)
  }

  const selectItemCodeProduct = (data: any) => {
    props.selectItemCodeProduct(data)
    setOpen(false)
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <Typography onClick={handleOpen} sx={custonTextUnderCardAddMore}>
                + {translate('urenew_add_more_product')}
      </Typography>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Boss}>
          <div className="main">
            <Box className="search" sx={customBoxSearch}>
              <InputBase id="searchItem" sx={customInputSearch} onChange={inputHandler} fullWidth />
            </Box>
            <ProductMoreList input={inputText} data={products.items} selectItemCodeProduct={selectItemCodeProduct} />
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  )
}

export default AddMoreProducts

const custonTextUnderCardAddMore = {
  fontFamily: 'Prompt',
  fontWeight: 300,
  fontSize: '24px',
  lineHeight: '30px',
  color: '#003B6F',
  cursor: 'pointer',
  '@media (max-width: 428px)': {
    fontSize: '20px',
    marginTop: '40px'
  }
}


const Boss = {
  position: 'absolute',
  width: '345px',
  height: '287px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  bgcolor: '#fff'
}


const customBoxSearch = {
  backgroundColor: '#eae9e9',
  padding: '10px'
}


const customInputSearch = {
  height: '32px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  borderBottom: '0px !important',
  fontFamily: 'Prompt',
  color: '#003B6F',
  'input': {
    textAlign: 'center',
    fontSize: '1rem',
  }
}