import { Box, Typography } from '@mui/material';
import { useAddToCart } from 'Stores/Cart/CartStore';
import { Product } from 'Constants/Interface/Product';

const custonText = {
  fontFamily: 'Prompt',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '30px',
  color: '#003B6F',
  cursor: 'pointer',
};

const customBoxList = {
  backgroundColor: '#fff',
  maxHeight: 200,
  overflow: 'auto',
  paddingLeft: '5%',
  paddingRight: '5%',
  '&:hover': {
    backgroundColor: '#079FF4',
  },
  // ::-webkit-scrollbar-thumb {
  //   background-color: #d4aa70;
  //   border-radius: 100px;
  // }
};
function ProductMoreList(props: any) {

  const addToCart = useAddToCart();

  const filteredData = props.data.filter((item: any) => {
    if (props.input === '') {
      return item;
    }
    return item.item_name.english.includes(props.input);
  });

  const onClickSelectProduct = (product: Product) => {
    props.selectItemCodeProduct(product);
    addToCart(product,1);
  };

  return (
    <Box style={customBoxList}>
      {filteredData.map((item: any) => (
        <Typography key={item.id} sx={custonText} onClick={() => onClickSelectProduct(item)}>
          {item.item_name.english}
        </Typography>
      ))}
    </Box>
  );
}

export default ProductMoreList;
