import { useState } from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useDictionaries, useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoadingOrderDetailState, isLoadingValidateOrderDetailState, orderDetailFormState, selectAddressState } from 'Stores/OrderDetail/OrderDetailStore';
import usePrefecture from 'Stores/OrderDetail/usePrefecture';
import { cartState } from 'Stores/Cart/CartInterface';
import { isSelectBasicPack } from 'Stores/Cart/CartStore';
import { SpinnerV2 } from '../Spinner/SpinnerV2';
import AddressHistoryModal from './AddressHistoryModal';
import CustomInput from '../UInput/CustomInput';
import { styles } from './OrderDetail.style'

function AddressHistoryForm() {
  usePrefecture()
  const [translate] = useTranslate();
  const isLoadingAddressHistory = useRecoilValue(isLoadingOrderDetailState);
  const selectAddress = useRecoilValue(selectAddressState);
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);
  const loadingValidate = useRecoilValue(isLoadingValidateOrderDetailState)
  // [have hard code don't forgot remove it]
  const [openAddressList, setOpenAddressList] = useState(false)

  const handleClose = () => setOpenAddressList(false)

  const isBasicPackSelected = useRecoilValue(isSelectBasicPack)

  const openModalSelectAddressHistory = async () => {
    setOpenAddressList(true)
  }

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === 'phone') {
      if (value.length <= 13) {
        const sanitizedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters

        let formattedValue = '';

        if (sanitizedValue.length === 10) {
          formattedValue = sanitizedValue.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
        } else if (sanitizedValue.length === 11) {
          formattedValue = sanitizedValue.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
        } else {
          formattedValue = sanitizedValue; // No formatting for other lengths
        }

        setAddressForm({ ...addressForm, [name]: formattedValue });
      }
    } else if (name === 'zip') {
      const newValue = value.replace(/[^0-9.]/g, '');
      setAddressForm({ ...addressForm, [name]: newValue });
    } else {
      setAddressForm({ ...addressForm, [name]: value });
    }
  };

  const handleClearInput = (fieldName: string) => {
    setAddressForm({ ...addressForm, [fieldName]: '' });
  }

  return (
    <Stack width='100%'>
      {/* Block Address History */}
      <Box>
        <Stack gap='20px'>
          <Card sx={{
            minHeight: '35px',
            position: 'relative',
            padding: '16px',
            border: isBasicPackSelected || isLoadingAddressHistory ? '' : '1px solid #99BCDF',
            backgroundColor: !isBasicPackSelected ? 'unset' : '#E1E1E1',
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              {isLoadingAddressHistory && <Box sx={styles.overlayDisable}>
                <SpinnerV2 color={'#97BFF6'} />
              </Box>}
              <Stack gap='10px'>
                <Typography
                  variant='caption4'
                  sx={styles.textAddressHistoryTitle}>
                  {selectAddress.ushopShipToName}
                </Typography>
                <Typography variant='label4' fontWeight={300}>
                  {selectAddress.displayAddress}
                </Typography>
              </Stack>
              {!isBasicPackSelected && <Box
                onClick={() => openModalSelectAddressHistory()}
                sx={{ width: '20%', textAlign: 'right' }}
              >
                <Typography variant='caption3'>
                  {!isLoadingAddressHistory && translate('change')}
                </Typography>
              </Box>}
            </Box>
          </Card>

          <Grid container spacing={'20px'}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                type='tel'
                name='phone'
                label={translate('contact_number')}
                value={addressForm.phone || ''}
                onChange={onChangeForm}
                clearinput={handleClearInput}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 11 }}
                disabled={loadingValidate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                type='email'
                name='email'
                label={translate('email')}
                value={addressForm.email || ''}
                onChange={onChangeForm}
                clearinput={handleClearInput}
                disabled={loadingValidate}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box >

      <AddressHistoryModal open={openAddressList} handleClose={handleClose} />
    </Stack >
  );
}

export default AddressHistoryForm;
