import { Box, Stack } from '@mui/material';
import OrderDetail from 'Components/Elements/OrderDetailElement/OrderDetail'
import { cartState } from 'Stores/Cart/CartInterface';
import getFormat from 'Stores/OrderDetail/getFormat';
import useOrderDetail from 'Stores/OrderDetail/useOrderDetail';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export default function OrderDetailPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get("uuid")
  const cartItems = useRecoilValue(cartState)
  const navigate = useNavigate()
  const { userHashed } = useParams();

  useEffect(() => {
    if (cartItems.length === 0 && !uuid) {
      navigate(`/${userHashed}`, { replace: true })
    }
  }, [])

  if (uuid) {
    getFormat()
  } else {
    useOrderDetail()
  }

  return (
    <OrderDetail />
  )
}
