import { Box, Divider, Stack } from '@mui/material'
import styled from '@emotion/styled'
import { useTranslate } from 'Stores/Dictionaries/useDictionaries'
import './style.css'

type Props = {
  onClickDoneGuide: () => void
};

const PopupLandingGuide = ({ onClickDoneGuide }: Props) => {
  const [translate] = useTranslate()

  return (
    <Box
      sx={{
        position: 'absolute',
        right: '15%',
        top: 80,
        zIndex: 9999,
        '@media (min-width: 768px)': {
          right: 286,
          top: -161
        }
      }}>
      <Box className={`popup-centurion arrow-landing-page`}>
        <Box
          sx={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#0057AC',
            position: 'relative',
            zIndex: 998,
            textAlign: 'left',
            color: '#EAEBEB'
          }}>
          <Stack sx={{ fontSize: '12px', fontWeight: 300, gap: '3px' }}>
            <Box sx={{ fontSize: '14px', fontWeight: 500 }}>{translate('urenew_guide_landing_page_title')}</Box>
            <Box>{translate('urenew_guide_landing_page_content')}</Box>
          </Stack>

          <Divider sx={{ background: '#CCC', marginTop: '16px', marginBottom: '8px' }} />

          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <CustomButton onClick={onClickDoneGuide}>
              {translate('urenew_guide_done')}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const CustomButton = styled.button<any>`
  padding: 6px 16px;
  color: #fff;
  background: #009ecf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`

export default PopupLandingGuide
