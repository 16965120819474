import styled from '@emotion/styled'
import Loader from '../../../Assets/Loader.svg'
export const Spinner = () => <SpinnerIcon src={Loader} />

const SpinnerIcon = styled('img')({
  animation: 'rotation 1s infinite linear',
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(359deg)'
    }
  }
})
