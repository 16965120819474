import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDictionaries, useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ReactComponent as GlobalSVG } from '../../../Assets/svg/gobal.svg';
import { countryState } from '../../../Stores/Countries/CountriesStore';
import { languageState } from '../../../Stores/Dictionaries/DictionariesStore';
import Spinner from '../../../Assets/gif/loadingSpinner.gif'
import CountryModal from '../CountryModal/CountryModal';
import { styles } from './Footer.style';

function Footer() {
  const country = useRecoilValue(countryState);
  const [language, setLanguage] = useRecoilState(languageState);

  const [translate] = useTranslate()

  const listLanguage = country.config?.languageSettings?.items
  const nameCountry = language === 'en' ? country.roman_name : country.native_name

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleSetLanguage = (value: string) => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setLanguage(value)
    }, 1000)
  }

  return (
    <Box component="footer" sx={styles.root}>
      <Box className="wrapper">
        <Box className="copyright">
          <Typography variant='caption5' color="#ffffff">
            {translate('urenew_footer_copy')}
          </Typography>
        </Box>

        <Box className="language-control">
          <Button
            onClick={handleOpen}
            sx={styles.countryButton}>
            <img src={country.flagUrl} alt="image-flag" width={22} />
            <Typography variant='caption5' color="#ffffff" paddingTop="1px">
              {nameCountry.toUpperCase()}
            </Typography>
          </Button>

          <CountryModal open={open} setOpen={setOpen} />

          <Box sx={styles.languageSelector}>
            {loading ? <img src={Spinner} alt="loading" width={20} /> : <GlobalSVG />}
            <Box className='language-selector-wrapper'>
              {listLanguage.map((item, index) => (
                <Box key={`lang-${index}`}>
                  <Typography
                    className={`select-language ${language === item.code.toLowerCase() && 'active'}`}
                    variant='caption5'
                    onClick={() => handleSetLanguage(item.code.toLowerCase())}>
                    {item.name}
                  </Typography>
                  {listLanguage.length - 1 === index ? '' : <Typography
                    className='select-language-separator'
                    variant='caption5'>
                   |
                  </Typography>}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer;

