/* eslint-disable filenames/match-exported */
import { Stack, Typography } from '@mui/material';
import { Product } from 'Constants/Interface/Product';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { useDictionaries, useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAddToCart } from 'Stores/Cart/CartStore';
import { cartState } from 'Stores/Cart/CartInterface';
import CustomCheckBox from 'Components/Elements/CustomCheckBox/CustomCheckBox';
import { CheckoutState } from 'Stores/Checkout/CheckoutStore';

import { ProductStyled } from './ProductStyled';

interface ProductViewProps {
    item: Product
    qty: number
}

function ProductView({ item, qty }: ProductViewProps) {
  const [translate] = useTranslate()

  const language = useRecoilValue(languageState)

  const [cartItem, setCartItem] = useRecoilState(cartState)

  const [checkout, setCheckout] = useRecoilState(CheckoutState)

  const onRenewalSelected = (item: Product) => {
    if (cartItem.length > 0 || checkout.renewalProductSelected.item_code === item.item_code) {
      setCartItem([])
      setCheckout({
        ...checkout,
        renewalProductSelected: {} as Product
      })
    } else {
      const itemInCart = {
        product: item,
        quantity: 1
      }

      setCartItem([itemInCart])
      setCheckout({
        ...checkout,
        renewalProductSelected: item
      })
    }
  }

  return <ProductStyled.ProductCard>
    <Stack position="absolute" left="10px" top="0px">
      <CustomCheckBox checked={item.item_code === checkout.renewalProductSelected.item_code ? true : undefined} onClick={() => onRenewalSelected(item)} />
    </Stack>
    <Stack textAlign="center">
      <Typography variant="textHeaderCard">Renewal</Typography>
    </Stack>
    <Stack mt="35px">
      <img src={item.image_url} style={{ height: '100px' }} />
    </Stack>
    <Stack textAlign="center" mt="50px" justifyContent="center">
      <Typography variant="textHeaderCard" textAlign="center">
        {item.item_name[language === 'en' ? 'english' : 'native']}
      </Typography>
    </Stack>
    <Stack mt="30px">
      <Typography variant="textCodeCard">{translate('code')}: {item.item_code}</Typography>
    </Stack>
    <Stack mt="15px">
      <Typography variant="textPriceCard">{translate('price')}: {item.price}</Typography>
    </Stack>
  </ProductStyled.ProductCard>
}

export default ProductView