import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { detailOrderSummaryState } from 'Stores/OrderSummary/OrderSummaryStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { styles } from './OrderSummary.style'

const ShipToBox = () => {
  const detail = useRecoilValue(detailOrderSummaryState);
  const [translate] = useTranslate()
  const phone = detail?.urenew.formatContact.phone
  
  let formattedPhone = ''
  if (phone) {
    if (phone.length === 10) {
      formattedPhone = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    } else if (phone.length === 11) {
      formattedPhone = phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
    } else {
      formattedPhone = phone;
    }
  }
  
  return (
    <Box sx={styles.shipToCard}>
      <Typography variant='label2' fontWeight={700}>
        {translate('ship_to')} :
      </Typography>

      <Divider sx={styles.dividerStyle} />

      <Stack gap='10px'>
        <Box>
          <Typography variant='label5' fontWeight={600} color='#153862'>
            {detail?.urenew.formatContact.name}
          </Typography>
        </Box>

        <Box>
          <Typography variant='caption5'>
            {detail?.urenew.formatContact.display_address}
          </Typography>
        </Box>

        <Stack>
          <Typography variant='caption5'>
            {detail?.urenew.formatContact.email}
          </Typography>
          <Typography variant='caption5'>
            {formattedPhone}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
export default ShipToBox