import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { countryState } from 'Stores/Countries/CountriesStore';
import { RenewalAPI } from 'Services/RenewalAPIs';
import { orderDetailFormState } from 'Stores/OrderDetail/OrderDetailStore';
import { guideState } from 'Stores/Guide/GuideStore';
import {
  isUserApiLoadingState, userState, authState,
} from './UserStore';
interface UseUserInterfaceProps {
  hash: string
}

export function useUser({ hash }: UseUserInterfaceProps) {
  const [isLoading, setIsLoading] = useRecoilState(isUserApiLoadingState);
  const [auth, setAuth] = useRecoilState(authState);
  const [user, setUser] = useRecoilState(userState);
  const [guide, setGuide] = useRecoilState(guideState);
  const country = useRecoilValue(countryState);
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState)

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setIsLoading(true)
    try {
      const response = await RenewalAPI.getIdBroker(hash);
      if (response) {
        const { baId, token } = response.data.payload;

        sessionStorage.setItem('tokenHydra', token);
        sessionStorage.setItem('baId', baId);

        const getUserResponse = await RenewalAPI.getUser(baId, token, country.short);
        if (getUserResponse.data) {
          setAuth(response.data.payload);
          setUser(getUserResponse.data.ushop.profile);

          const { mobilePhone, homePhone } = getUserResponse.data.ushop.profile
          const phone = mobilePhone || homePhone

          let formattedPhone = ''
          if (phone) {
            if (phone.length === 10) {
              formattedPhone = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
            } else if (phone.length === 11) {
              formattedPhone = phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
            } else {
              formattedPhone = phone;
            }
          }

          setAddressForm({
            ...addressForm,
            selectAddressHistory: 'addressHistory',
            email: getUserResponse.data.ushop.profile.email,
            phone: formattedPhone,
          })

          setTimeout(() => {
            setIsLoading(false);
          }, 1000)
        }

        // get check show guide
        const getGuideResponse = await RenewalAPI.getIsShowGuide(baId)
        if (getGuideResponse.data) {
          const isGuide: any = {
            ...guide,
            isLandingPageGuide: getGuideResponse.data.isLandingPageGuide,
            isOrderDetailGuide: getGuideResponse.data.isOrderDetailGuide,
            isProductCardGuide: getGuideResponse.data.isProductCardGuide,
            isOrderSummaryGuide: getGuideResponse.data.isOrderSummaryGuide,
          }
          sessionStorage.setItem('isGuide', JSON.stringify(isGuide))
          setGuide(isGuide)
        }
      }
    } catch (error) {
      console.warn(error);
      const isGuide: any = {
        ...guide,
        isLandingPageGuide: true,
        isOrderDetailGuide: true,
        isProductCardGuide: true,
        isOrderSummaryGuide: true,
      }
      sessionStorage.setItem('isGuide', JSON.stringify(isGuide))
      setGuide(isGuide)
    }
  };
}
const createToken = (baID: string, now = new Date()) => {
  const date = now.getUTCDate();
  const weekDay = now.getUTCDay() + 1;
  const modeWeekDay = (date % weekDay) + 1;
  const hash = (baID || '')
    .toString()
    .split('')
    // .map((c) => parseInt(c) % modeWeekDay)  this code missing parseInt() radix ! 
    .map((c) => parseInt(c, 10) % modeWeekDay)
    .join('');
  return `${hash}${weekDay}${date}`;
};
