import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { orderDetailFormState } from 'Stores/OrderDetail/OrderDetailStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { Grid } from '@mui/material';
import CustomInput from '../UInput/CustomInput';
import ZipSearch from './Components/ZipSearch';
import SelectPrefecture from './Components/SelectPrefecture';

const NewAddress = () => {
  const [addressForm, setAddressForm] = useRecoilState(orderDetailFormState);
  const [translate] = useTranslate()
  
  useEffect(() => {
    setAddressForm({
      ...addressForm,
      'fullname': '',
      'house_number': '',
      'district': '',
      'town': '',
      'prefecture': '',
      'zip': ''
    })
  }, [])

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === 'phone') {
      if (value.length <= 13) {
        const sanitizedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters

        let formattedValue = '';

        if (sanitizedValue.length === 10) {
          formattedValue = sanitizedValue.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
        } else if (sanitizedValue.length === 11) {
          formattedValue = sanitizedValue.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
        } else {
          formattedValue = sanitizedValue; // No formatting for other lengths
        }

        setAddressForm({ ...addressForm, [name]: formattedValue });
      }
    } else if (name === 'zip') {
      const newValue = value.replace(/[^0-9.]/g, '');
      setAddressForm({ ...addressForm, [name]: newValue });
    } else {
      setAddressForm({ ...addressForm, [name]: value });
    }
  };

  const handleClearInput = (fieldName: string) => {
    setAddressForm({ ...addressForm, [fieldName]: '' });
  }

  const handleOnSetPrefecture = (value: { valueText: string }) => {
    setAddressForm({ ...addressForm, 'prefecture': value.valueText });
  }

  return (
    <Grid container spacing={'20px'}>
      <Grid item xs={12} sm={6}>
        <CustomInput
          type='text'
          label={translate('urenew_full_name')}
          name='fullname'
          value={addressForm.fullname}
          onChange={onChangeForm}
          clearinput={handleClearInput}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ZipSearch
          type='tel'
          label={translate('zip_code')}
          name='zip'
          value={addressForm.zip}
          onChange={onChangeForm}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectPrefecture
          type='text'
          label={translate('urenew_prefecture')}
          name='prefecture'
          value={addressForm.prefecture}
          onChange={onChangeForm}
          onSet={handleOnSetPrefecture}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomInput
          type='text'
          label={translate('district')}
          name='district'
          value={addressForm.district}
          onChange={onChangeForm}
          clearinput={handleClearInput}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomInput
          type='text'
          label={translate('urenew_town')}
          name='town'
          value={addressForm.town}
          onChange={onChangeForm}
          clearinput={handleClearInput}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomInput
          type='text'
          label={translate('urenew_house_number')}
          name='house_number'
          value={addressForm.house_number}
          onChange={onChangeForm}
          clearinput={handleClearInput}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomInput
          type='tel'
          label={translate('contact_number')}
          name='phone'
          value={addressForm.phone}
          onChange={onChangeForm}
          clearinput={handleClearInput}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 11 }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomInput
          type='email'
          label={translate('email')}
          name='email'
          value={addressForm.email}
          onChange={onChangeForm}
          clearinput={handleClearInput}
        />
      </Grid>
    </Grid>
  )
}

export default NewAddress