import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import mobiscroll from '@mobiscroll/react';
import { paymentChannelState, paymentFormState } from 'Stores/OrderSummary/OrderSummaryStore'
import { formatCreditCardNumber, validateCreditNumber } from 'Utils/FormatCreditCard';
import { Box, Grid, Typography } from '@mui/material'
import { useTranslate } from 'Stores/Dictionaries/useDictionaries'
import CustomInput from 'Components/Elements/UInput/CustomInput'
import CustomInputPassword from 'Components/Elements/UInput/CustomInputPassword';
import { OnSetScrollerType } from 'Constants/MobiScroll';
import styled from '@emotion/styled';
import { styles } from '../OrderSummary.style'
import { ReactComponent as SVGArrow } from '../../../../Assets/icons/arrow-left.svg'
import { ReactComponent as VISA_ICON } from '../../../../Assets/credit-cards/visa.svg'
import { ReactComponent as MC_ICON } from '../../../../Assets/credit-cards/mastercard.svg'
import { ReactComponent as AMEX_ICON } from '../../../../Assets/credit-cards/amex.svg'
import { ReactComponent as JCB_ICON } from '../../../../Assets/credit-cards/jcb.svg'

const CreditCardPayment = () => {
  const [, setPaymentType] = useRecoilState(paymentChannelState)
  const [paymentForm, setPaymentForm] = useRecoilState(paymentFormState)
  const [cardNumber, setCardNumber] = useState<string>('')
  const [translate] = useTranslate()

  useEffect(() => {
    setPaymentForm({
      ...paymentForm,
      name: '',
      card_number: '',
      exp_date: '',
      cvv: '',
    })
  }, [setPaymentType])

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === 'cvv') {
      const newValue = value.replace(/[^0-9.]/g, '');
      if (newValue.length > 4) {
        return
      }
      setPaymentForm({ ...paymentForm, [name]: newValue });
    } else {
      setPaymentForm({ ...paymentForm, [name]: value });
    }

  };

  const handlePaymentSelected = (value: string) => {
    setPaymentType({ channel: value })
  }

  const handleClearInput = (fieldName: string) => {
    setPaymentForm({ ...paymentForm, [fieldName]: '' });
  }

  const handleClearNumberInput = (fieldName: string) => {
    setCardNumber('')
    setPaymentForm({ ...paymentForm, [fieldName]: '' });
  }

  const setCardNumberPattern = (value: string) => {
    if (value && !validateCreditNumber(value)) return
    const { ccNumber, ccNumberFormat } = formatCreditCardNumber(value)
    setPaymentForm({ ...paymentForm, card_number: ccNumber })
    setCardNumber(ccNumberFormat)
  }

  const setCard = (key: string, value: string) => {
    switch (key) {
    case 'number':
      setCardNumberPattern(value)
      break
    default:
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw `CreditCard error. Key =  + ${key}`
    }
  }

  const dataYear = () => {
    const minYear = new Date().getFullYear()
    const maxYear = 10

    const listYear = []

    for (let i = 0; i <= maxYear; i++) {
      const year = minYear + i
      listYear.push({
        value: `${year}`,
        display: `${year}`
      })
    }
    return listYear
  }

  const wheels = [
    [
      {
        label: 'MM',
        circular: false,
        data: [
          { value: '01', display: '01' },
          { value: '02', display: '02' },
          { value: '03', display: '03' },
          { value: '04', display: '04' },
          { value: '05', display: '05' },
          { value: '06', display: '06' },
          { value: '07', display: '07' },
          { value: '08', display: '08' },
          { value: '09', display: '09' },
          { value: '10', display: '10' },
          { value: '11', display: '11' },
          { value: '12', display: '12' },
        ],
        cssClass: 'customMobi',
      },
      {
        label: 'YYYY',
        circular: false,
        data: dataYear(),
      },
    ],
  ];

  const setExpDate = (event: OnSetScrollerType) => {
    setPaymentForm({ ...paymentForm, exp_date: event.valueText });
  };

  const formatDate = (data: string[]) => `${data[0]}/${data[1]}`;

  const parseDate = (value: string) => {
    if (value) {
      const valueTemp = value.toString().split('/');
      return [valueTemp[0], valueTemp[1]];
    }
    return [0, 0];
  };

  return (
    <Box sx={styles.creditCardWrapper}>
      <Box sx={styles.creditCardTitle}>
        <Typography variant='caption4' fontWeight={600} color='#153862'>
          {translate('payment_options')}:
        </Typography>
        <Box
          onClick={() => handlePaymentSelected('')}
          sx={styles.bankWireIcon}>
          <SVGArrow />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        gap: '5px',
        marginBottom: '20px'
      }}>
        <VISA_ICON width={40} height={24} />
        <MC_ICON width={40} height={24} />
        <AMEX_ICON width={40} height={24} />
        <JCB_ICON width={40} height={24} />
      </Box>

      <Grid container spacing={'20px'}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            type='text'
            label={translate('urenew_name_on_card')}
            name='name'
            value={paymentForm.name}
            onChange={onChangeForm}
            clearinput={handleClearInput}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomInput
            type='tel'
            label={translate('urenew_card_number')}
            name='card_number'
            value={cardNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCard('number', e.target.value)}
            clearinput={handleClearNumberInput}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {paymentForm.exp_date === "" ? (
            <SelectNoValueStyle>
              <mobiscroll.Scroller
                wheels={wheels}
                cancelText={translate('urenew_cancel').toLocaleString()}
                setText={translate('ok').toLocaleString()}
                display="center"
                showLabel={true}
                placeholder={translate('exp_date').toLocaleString()}
                formatValue={(data: string[]) => formatDate(data)}
                onSet={(event: OnSetScrollerType) => setExpDate(event)}
              />
            </SelectNoValueStyle>
          ) : (
            <SelectStyle>
              <Box className="label">{translate('exp_date')}</Box>
              <mobiscroll.Scroller
                wheels={wheels}
                cancelText={translate('urenew_cancel').toLocaleString()}
                setText={translate('ok').toLocaleString()}
                display="center"
                showLabel={true}
                value={paymentForm.exp_date}
                parseValue={(value: string) => parseDate(value)}
                formatValue={(data: string[]) => formatDate(data)}
                onSet={(event: OnSetScrollerType) => setExpDate(event)}
              />
            </SelectStyle>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomInputPassword
            type='tel'
            label={translate('urenew_cvv').toLocaleString()}
            name='cvv'
            value={paymentForm.cvv}
            onChange={onChangeForm}
            clearinput={handleClearInput}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const SelectStyle = styled.div`
    width: 100%;
    position: relative;
    height: 55px;
    .label {
      height: 14.5px;
      position: absolute;
      left: 19px;
      top: 6px;
      right: 0;
      bottom: 0;
      font-family: 'Prompt';
      font-size: 13px;
      font-weight: 300;
      color: #8A9BB0;
      z-index: 1;
    }
    .mbsc-comp {
      position: absolute;
      height: 30px;
      font-family: 'Prompt';
      padding-top: 22px;
      padding-left: 18px;
      font-size: 16px;
      background: #ffffff;
      border: 1px solid #A0BBDC;
      border-radius: 8px;
      color: #153862;
      outline: none;
      right: 0;
      left: 0;
    }
    .customMobi {
        .mbsc-ios {
            font-family: 'Prompt, sans-serif' !important;
        }
    }
`;

const SelectNoValueStyle = styled.div`
    width: 100%;
    position: relative;
    height: 55px;
    .mbsc-comp {
      position: absolute;
      height: 51px;
      font-family: 'Prompt';
      padding-left: 18px;
      font-size: 16px;
      color: #8A9BB0 !important;
      font-weight: 300;
      background: #ffffff;
      border: 1px solid #A0BBDC;
      border-radius: 8px;
      right: 0;
      left: 0;
      outline: none
    }
    .mbsc-comp::placeholder {
      color: #8A9BB0;
    }
`;

export default CreditCardPayment